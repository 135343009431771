import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import wb from "./registerServiceWorker";
// import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment';
import vSelect from 'vue-select'
import VueSweetalert2 from 'vue-sweetalert2';
import VueGoodTablePlugin from 'vue-good-table';
import VueApexCharts from 'vue-apexcharts'
// import Dropzone from "dropzone";
import VueNumeric from 'vue-numeric';
import vue2Dropzone from 'vue2-dropzone'
import DatePicker from 'vue2-datepicker';

import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'

import 'vue-select/dist/vue-select.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-good-table/dist/vue-good-table.css'
// import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
// dashkit themes
import './assets/css/libs.bundle.css'
import 'vue2-datepicker/index.css';
import './assets/css/theme.bundle.css'
// import QrcodeVue from 'qrcode.vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import VeeValidate from 'vee-validate'
// import Calendar from 'v-calendar/lib/components/calendar.umd'
// import DatePicker from 'v-calendar/lib/components/date-picker.umd'\
import Vue2Editor from 'vue2-editor'
import VueQRCodeComponent from 'vue-qrcode-component'
import VueHtmlToPaper from 'vue-html-to-paper';

import i18n from './i18n/i18n'


const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}

window.queryString = require("query-string");

Vue.use(VueSweetalert2);
Vue.use(VueGoodTablePlugin);
Vue.use(VueApexCharts)
Vue.use(VeeValidate)
Vue.use(MonthPicker)
Vue.use(MonthPickerInput)
// Vue.use(DateRangePicker)
Vue.use(DatePicker)
Vue.use(Vue2Editor);
Vue.use(VueNumeric);
Vue.use(vue2Dropzone)
Vue.use(VueHtmlToPaper, options)
// Vue.component('v-calendar', Calendar)
// Vue.component('v-date-picker', DatePicker)
// Vue.component('v-range-datepicker', DateRangePicker)

Vue.component('v-select', vSelect)
Vue.component('v-checkbox', vSelect)
Vue.component('apexchart', VueApexCharts)
// Vue.component('qrcode-vue', QrcodeVue)
Vue.component('qr-code', VueQRCodeComponent)

Vue.prototype.$workbox = wb;
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: function (h) { return h(App) }
}).$mount('#app')