<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <!-- / .row -->

      <!-- Filter -->
      <!-- end of filter -->

      <!-- Table -->
      <div class="card mt-5">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">{{ $t("Disease") }}</h4>
          <!-- Button -->
          <b-button v-b-modal.modal-1 variant="primary" @click="isAdd = true">
            <span class="fe fe-file-plus"></span>
            {{ $t("Add") }}
          </b-button>
          <b-button v-b-toggle.collapse-1 variant="primary" style="margin-left: 7px">
            <span class="fe fe-sliders"></span>
          </b-button>
        </div>
        <b-collapse id="collapse-1">
          <form @submit.prevent="sorting()">
            <div class="row mt-4" style="margin-left: 15px; margin-right: 15px">
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">{{
                    $t("Block Name")
                  }}</label>
                  <input type="text" class="form-control" v-model="body.block_name" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">{{
                    $t("Chapter Name")
                  }}</label>
                  <input type="text" class="form-control" v-model="body.chapter_name" />
                </div>
              </div>
            </div>

            <div class="row" style="margin-left: 15px; margin-right: 15px">
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">{{
                    $t("Category Name")
                  }} </label>
                  <input type="text" class="form-control" v-model="body.category_name" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">{{
                    $t("Disease Name")
                  }}</label>
                  <input type="text" class="form-control" v-model="body.disease_name" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">Order</label>
                  <v-select v-model="body.order" :options="['ASC', 'DESC']" />
                </div>
              </div>
            </div>

            <div class="row mb-3" style="margin-right: 15px">
              <div class="col"></div>
              <div class="col-auto">
                <!-- Button -->
                <button type="submit" class="btn btn-primary lift">
                  {{ $t("Filter") }}
                </button>
              </div>
            </div>
            <!-- / .row -->
          </form>
        </b-collapse>
        <vue-good-table :line-numbers="true" :columns="columns" :rows="desease.data" :sort-options="{
            enabled: true
          }" :total-rows="desease.totalItems" :pagination-options="{
            enabled: true,
            mode: 'pages',
            position: 'bottom',
            perPage: 10,
            perPageDropdown: [10, 50, 100],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All'
          }" style-class="vgt-table border-0 table" @on-page-change="onPageChange" @on-per-page-change="onPageChange">
          <!-- Loading -->
          <div slot="emptystate" class="text-center font-size-14">
            No data available
          </div>
          <template slot="table-column" slot-scope="props">
            <!-- Table Action -->
            <span v-if="props.column.label == 'CHAPTER'">
              {{ $t("Chapter") }}
            </span>
            <span v-if="props.column.label == 'BLOCK'">
              {{ $t("Block") }}
            </span>
            <span v-if="props.column.label == 'CATEGORY'">
              {{ $t("Category") }}
            </span>
            <span v-if="props.column.label == 'DISEASE'">
              {{ $t("Disease") }}
            </span>
            <span v-if="props.column.label == 'ACTION'">
              {{ $t("Action") }}
            </span>
          </template>
          <!-- Custom Rows -->
          <template slot="table-row" slot-scope="props">
            <!-- Table Action -->
            <span v-if="props.column.field == 'action'">
              <b-button class="no-wrap btn-pill" variant="warning" size="sm" @click="updateData(props.row)">
                <span class="fe fe-edit"></span>
              </b-button>
              <b-button class="no-wrap ml-2" variant="danger" style="margin-left: 5px" size="sm"
                @click="deleteData(props.row.id)">
                <span class="fe fe-trash-2"></span>
              </b-button>
            </span>
          </template>
        </vue-good-table>
      </div>

      <!-- Modal -->
      <b-modal id="modal-1" centered hide-footer title="Disease Form">
        <form @submit.prevent="saveAdd()" v-if="isAdd === true">
          <div class="form-group">
            <label>{{ $t("Chapter") }} <span class="text-danger">*</span></label>
            <v-select v-model="bodyAdd.chapter_id" :options="chapter.data" label="chapter_name" :reduce="(e) => e.id"
              @input="chapterSelect()" v-validate="'required'" name="Chapter Name" />
            <span class="text-sm text-danger"
              v-show="errors.has('Chapter Name')">{{ errors.first("Chapter Name") }}</span>
          </div>
          <div class="form-group" v-if="selectBlock.length > 0">
            <label>{{ $t("Block") }} <span class="text-danger">*</span></label>
            <v-select v-model="bodyAdd.block_id" :options="selectBlock" label="block_name" :reduce="(e) => e.id"
              @input="blockSelect()" v-validate="'required'" name="Block Name" />
            <span class="text-sm text-danger" v-show="errors.has('Block Name')">{{ errors.first("Block Name") }}</span>
          </div>
          <div class="form-group" v-if="selectCategory.length > 0 && selectBlock.length > 0">
            <label>{{ $t("Category") }} <span class="text-danger">*</span></label>
            <v-select v-model="bodyAdd.category_id" :options="selectCategory" label="category_name"
              :reduce="(e) => e.id" @input="categorySelect()" v-validate="'required'" name="Category Name" />
            <span class="text-sm text-danger"
              v-show="errors.has('Category Name')">{{ errors.first("Category Name") }}</span>
          </div>
          <div class="form-group">
            <label>{{ $t("Disease Name") }} <span class="text-danger">*</span></label>
            <input type="text" v-model="bodyAdd.disease_name" class="form-control" placeholder="Disease Name"
              v-validate="'required'" name="Disiase Name" />
            <span class="text-sm text-danger"
              v-show="errors.has('Disiase Name')">{{ errors.first("Disiase Name") }}</span>
            <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="submit" v-if="isLoading == false">
              {{ $t("Save") }}
            </button>
            <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="button" disabled v-else>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>

        <form @submit.prevent="saveUpdate()" v-else>
          <div class="form-group">
            <label>{{ $t("Chapter") }} <span class="text-danger">*</span></label>
            <v-select v-model="bodyUpdate.chapter_id" :options="chapter.data" label="chapter_name" :reduce="(e) => e.id"
              @input="chapterUpdateSelect()" />
          </div>
          <div class="form-group" v-if="selectBlock.length > 0">
            <label>{{ $t("Block") }} <span class="text-danger">*</span></label>
            <v-select v-model="bodyUpdate.block_id" :options="selectBlock" label="block_name" :reduce="(e) => e.id" />
          </div>
          <div class="form-group" v-if="selectCategory.length > 0 && selectBlock.length > 0">
            <label>{{ $t("Category") }} <span class="text-danger">*</span></label>
            <v-select v-model="bodyUpdate.category_id" :options="selectCategory" label="category_name"
              :reduce="(e) => e.id" @input="blockUpdateSelect()" />
          </div>
          <div class="form-group">
            <label>{{ $t("Disease Name") }} <span class="text-danger">*</span></label>
            <input type="text" v-model="bodyUpdate.disease_name" class="form-control" placeholder="Disease Name" />
            <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="submit" v-if="isLoading == false">
              {{ $t("Save") }}
            </button>
            <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="button" disabled v-else>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>
<script>
  export default {
    name: "defaultCMS",
    computed: {
      desease() {
        return this.$store.getters["disease/deseaseData"] ?
          this.$store.getters["disease/deseaseData"] : [];
      },
      category() {
        return this.$store.getters["category/CategoryData"] ?
          this.$store.getters["category/CategoryData"] : [];
      },
      block() {
        return this.$store.getters["block/BlockData"] ?
          this.$store.getters["block/BlockData"] : [];
      },
      chapter() {
        return this.$store.getters["chapter/ChapterData"] ?
          this.$store.getters["chapter/ChapterData"] : [];
      }
    },
    data() {
      return {
        body: {
          page: 1,
          limit: 1000,
          order: "",
          category_name: "",
          block_name: "",
          chapter_name: "",
          disease_name: ""
        },
        bodys: {
          page: 1,
          limit: 1000,
          order: ""
        },
        bodyAdd: {
          category_id: "",
          block_id: "",
          chapter_id: "",
          disease_name: ""
        },
        bodyUpdate: {
          id: "",
          category_id: "",
          block_id: "",
          chapter_id: "",
          disease_name: ""
        },
        disease_name: "",
        disease_names: "",
        id: "",
        isLoading: false,
        isAdd: true,
        columns: [{
            label: "CHAPTER",
            field: "chapter_name",
            thClass: "table-header w-auto text-muted font-weight-bold",
            tdClass: "w-auto color-secondary align-middle"
          },
          {
            label: "BLOCK",
            field: "block_name",
            thClass: "table-header w-auto text-muted font-weight-bold",
            tdClass: "w-auto color-secondary align-middle"
          },
          {
            label: "CATEGORY",
            field: "category_name",
            thClass: "table-header w-auto text-muted font-weight-bold",
            tdClass: "w-auto color-secondary align-middle"
          },
          {
            label: "DISEASE",
            field: "disease_name",
            thClass: "table-header w-auto text-muted font-weight-bold",
            tdClass: "w-auto color-secondary align-middle"
          },
          {
            label: "ACTION",
            field: "action",
            thClass: "table-header no-wrap text-muted font-weight-bold",
            tdClass: "no-wrap color-secondary align-middle"
          }
        ],
        selectBlock: [],
        selectCategory: []
      };
    },
    mounted() {
      if (this.desease && this.desease.length < 1) {
        this.getDesease();
        this.getCategory();
        this.getBlock();
        this.getChapter();
      }
    },
    methods: {
      //action
      updateData(props) {
        this.$bvModal.show("modal-1");
        this.isAdd = false;
        this.bodyUpdate.id = props.id;
        this.bodyUpdate.disease_name = props.disease_name;
        this.bodyUpdate.category_id = props.category_id;
        this.bodyUpdate.block_id = props.block_id;
        this.bodyUpdate.chapter_id = props.chapter_id;

        this.chapterUpdateSelect();
        this.blockUpdateSelect();
      },

      saveAdd() {
        this.$validator.validateAll().then(async (res) => {
          if (!res) return false;
          try {
            this.isLoading = true;
            this.$store
              .dispatch("disease/addDesease", this.bodyAdd)
              .then((resp) => {
                this.$swal({
                  title: this.$t("Saved"),
                  text: this.$t("Your input has been saved"),
                  icon: "success",
                  confirmButtonText: this.$t("OK")
                }).then((resp) => {
                  this.isLoading = false,
                    // (this.bodyAdd.category_name = ""),
                    // (this.bodyAdd.block_id = ""),
                    this.bodyAdd.disease_name = ""
                  this.bodyAdd.chapter_id = ""
                  this.getDesease();
                  this.$bvModal.hide("modal-1");
                });
              }).catch((err) => {
                this.$swal.fire("Error!", `${err.response.data.error}`, "error");
                this.isLoading = false
              });
          } catch (resp) {
            this.$swal("Error", this.$t("Error while add data"), "error");
          }
        });
      },

      saveUpdate() {
        this.isLoading = true;
        this.$store
          .dispatch("disease/updateDisease", this.bodyUpdate)
          .then((resp) => {
            this.$swal({
              title: this.$t("Saved"),
              text: this.$t("Your input has been saved"),
              icon: "success",
              confirmButtonText: this.$t("OK")
            }).then((resp) => {
              this.isLoading = false;
              this.getDesease();
              this.$bvModal.hide("modal-1");
            });
          })
          .catch((resp) => {
            this.$swal("Error", this.$t("Error while add data"), "error");
          });
      },

      deleteData(id) {
        this.$swal({
          title: this.$t("Are you sure?"),
          text: this.$t("You won't be able to revert this!"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00D97E",
          cancelButtonColor: "#E63757",
          confirmButtonText: this.$t("Yes, delete it!"),
          cancelButtonText: this.$t("Cancel")
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch("disease/delDesease", id)
              .then((resp) => {
                this.getDesease();
                this.$swal(
                  "Deleted!",
                  this.$t("Your file has been deleted"),
                  "success"
                );
              })
              .catch((resp) => {
                this.$swal("Error", this.$t("Error while delete data"), "error");
              });
          }
        });
      },

      // api hit
      async getDesease() {
        this.$store.dispatch("disease/getDesease", this.body);
      },
      async getCategory() {
        this.$store.dispatch("category/getCategory", this.bodys);
      },
      async getBlock() {
        await this.$store.dispatch("block/getBlock", this.bodys);
      },
      async getChapter() {
        await this.$store.dispatch("chapter/getChapter", this.bodys);
      },

      // filter
      filterPage() {
        this.getDesease();
      },

      sorting() {
        this.body.page = 1;
        this.body.limit = 10;
        this.getDesease();
      },

      // Pagination
      onPageChange(payload) {
        this.body.limit = payload.currentPerPage;
        this.body.page = payload.currentPage;
        // if (
        //   parseInt(payload.currentPage) * parseInt(payload.currentPerPage) >
        //   1000
        // ) {
        this.getDesease();
        // }
      },

      //additional function
      chapterSelect() {
        let blockData = this.block.data;
        let chapterId = this.bodyAdd.chapter_id;
        this.selectBlock = blockData.filter((item) => {
          return item.chapter_id == chapterId;
        });
      },
      blockSelect() {
        let categoryData = this.category.data;
        let blockId = this.bodyAdd.block_id;
        this.selectCategory = categoryData.filter((item) => {
          return item.block_id == blockId;
        });
      },

      chapterUpdateSelect() {
        let blockData = this.block.data;
        let chapterId = this.bodyUpdate.chapter_id;
        this.selectBlock = blockData.filter((item) => {
          return item.chapter_id == chapterId;
        });
      },
      blockUpdateSelect() {
        let categoryData = this.category.data;
        let blockId = this.bodyUpdate.block_id;
        this.selectCategory = categoryData.filter((item) => {
          return item.block_id == blockId;
        });
      }
    }
  };
</script>