import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Profile from '../views/profile.vue'

// Role
import Role from '../views/role/role.vue'

//Company
import company from '../views/company/company.vue'
import companyNew from '../views/company/company_new.vue'

// Disease
import diseaseChapter from '../views/disease/chapter.vue'
import diseaseBlock from '../views/disease/block.vue'
import diseaseCategory from '../views/disease/category.vue'
import disease from '../views/disease/disease.vue'


// Inventory
import inventoryDrug from '../views/inventory/drug.vue'
import inventoryUnit from '../views/inventory/unit.vue'
import inventoryCategory from '../views/inventory/category.vue'
import inventoryDisposabelCategory from '../views/inventory/disposable_category.vue'
import disposableItem from '../views/inventory/disposable_item.vue'
import medicalItemCategory from '../views/inventory/tool_category.vue'
import medicalToolItem from '../views/inventory/medical_tools.vue'
import wastePartner from '../views/inventory/waste_partner.vue'

// Modul Medical
import facility from '../views/medical/facility.vue'
import treatment from '../views/medical/treatment.vue'
import mscTM from '../views/medical/msc.vue'
import trainingGroup from '../views/medical/staff/training/group.vue'
import trainingSubGroup from '../views/medical/staff/training/subGroup.vue'
import staffPosition from '../views/medical/staff/position.vue'

// MCU
import categoryMcu from '../views/mcu/category/category.vue'
import resultMcu from '../views/mcu/category/result.vue'
import categoryTest from '../views/mcu/test/category.vue'
import groupMcu from '../views/mcu/test/group.vue'
import itemMcu from '../views/mcu/test/item.vue'
import typeQuestion from '../views/mcu/question/questionType.vue'
import question from '../views/mcu/question/question.vue'
import ihAction from '../views/ih/action.vue'
import ihFactor from '../views/ih/factor.vue'
import ihMeasuringActivity from '../views/ih/measuring/activity.vue'
import ihMeasuringMethod from '../views/ih/measuring/method.vue'
import ihMeasuringUnit from '../views/ih/measuring/unit.vue'
import ihKit from '../views/ih/measuring/kit.vue'
import ihCampaign from '../views/ih/measuring/campaign.vue'

// Oh
import ohActivity from '../views/oh/activity.vue'
import ohIndicator from '../views/oh/indicator.vue'
import ohData from '../views/oh/data.vue'
import ohDocument from '../views/oh/document.vue'
import yearly from '../views/oh/yearly.vue'

// Healthcare Facility
import healthcare from '../views/healthcare_facility/healthcare.vue'

// Hazard 
import chemicalMaterialType from '../views/hazard/type.vue'
import chemicalMaterialName from '../views/hazard/name.vue'
import chemicalHazard from '../views/hazard/hazard.vue'

// Healthcare Fasility 
import healthcareFasility from '../views/healthcare/fasility.vue'

// Clinic 
import clinicPatient from '../views/clinic/patient/patient.vue'
import clinicInputPO from '../views/clinic/inventory/po.vue'
import clinicInputDO from '../views/clinic/inventory/do.vue'
import clinicListPO from '../views/clinic/inventory/poList.vue'
import clinicListDO from '../views/clinic/inventory/doList.vue'
import entryStock from '../views/clinic/inventory/entryStock.vue'
import inputStock from '../views/clinic/inventory/entry.vue'
import reportStocks from '../views/clinic/inventory/reportStock.vue'
import detailDrugs from '../views/clinic/inventory/stockDetail.vue'
import detailStock from '../views/clinic/inventory/stockList.vue'
import Mutation from '../views/clinic/inventory/mutation.vue'
import clinicMcu from '../views/clinic/mcu/clinic_mcu.vue'
import MedicalRecordMCU from '../views/clinic/medical_record_mcu/medical_record_mcu.vue'
import recordMcuRiwayat from '../views/clinic/medical_record_mcu/record_mcu_riwayat.vue'
import dataRecord from '../views/clinic/patient/data_record.vue'
import roleClinicManagenet from '../views/clinic/role_management/role_management.vue'
import history from '../views/clinic/patient/history/history.vue'
import recordDetail from '../views/clinic/patient/history/record-detail.vue'
import mcuDetail from '../views/clinic/patient/history/mcu_detail.vue'
import Opname from '../views/clinic/inventory/stock_opname.vue'
import OpnameDetail from '../views/clinic/inventory/opname_detail.vue'
import OpnameAdd from '../views/clinic/inventory/opname_add.vue'
import Bin from '../views/clinic/inventory/bin.vue'
import Disposal from '../views/clinic/inventory/disposal.vue'
import DrugTest from '../views/clinic/drug_test/drug_test.vue'
import DrugTestDetail from '../views/clinic/drug_test/detail.vue'
// Oh Management 
import detailManagement from '../views/oh_management/input/input.vue'
import leadingLaggingIndicator from '../views/oh_management/report/lagging_indicator.vue'
import summary from '../views/oh_management/report/summary.vue'
import tenagaKerjaSakit from '../views/oh_management/report/tenaga_kerja.vue'
import approval from '../views/oh_management/report/approval.vue'
import companyCorrection from '../views/oh_management/input/correction.vue'
import recapDaily from '../views/oh_management/report/recapDaily.vue'
// Role Management
import roleManagement from '../views/role_management/role_management.vue'
import noPartnership from '../views/clinic/nopartnership/nopartnership.vue'
// IH Management 
import ihMeasurement from '../views/ih_management/measurement/measurement.vue'
import ihChemical from '../views/ih_management/chemical/chemical.vue'
import ihMeasurementDetail from '../views/ih_management/measurement/measurement_detail.vue'
// Notification
import notificationSettings from '../views/notification_settings/notification.vue'
// overhandle
import overhandle from '@/views/overhandle/overhandle.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'home',
  component: HomeView
},
{
  path: '/profile',
  name: 'profile',
  component: Profile
},
{
  path: '/role',
  name: 'role',
  component: Role
},
{
  path: '/company-mapping',
  name: 'companyMapping',
  component: company
},
{
  path: '/company',
  name: 'companyNew',
  component: companyNew
},
{
  path: '/disease/chapter',
  name: 'diseaseChapter',
  component: diseaseChapter
},
{
  path: '/disease/block',
  name: 'diseaseBlock',
  component: diseaseBlock
},
{
  path: '/disease/category',
  name: 'diseaseCategory',
  component: diseaseCategory
},
{
  path: '/disease',
  name: 'disease',
  component: disease
},

// Inventory
{
  path: '/inventory/medicine',
  name: 'drug',
  component: inventoryDrug
},
{
  path: '/inventory/unit',
  name: 'unit',
  component: inventoryUnit
},
{
  path: '/inventory/category',
  name: 'inventoryCategory',
  component: inventoryCategory
},
{
  path: '/inventory/disposable-category',
  name: 'inventoryDisposableCategory',
  component: inventoryDisposabelCategory
},
{
  path: '/inventory/disposable-item',
  name: 'disposableItem',
  component: disposableItem
},
{
  path: '/inventory/medical-equipment-category',
  name: 'medicalItemCategory',
  component: medicalItemCategory
},
{
  path: '/inventory/medical-tool-item',
  name: 'medicalToolItem',
  component: medicalToolItem
},
{
  path: '/inventory/medical-waste/partner',
  name: 'Partner',
  component: wastePartner
},

// Medical
{
  path: '/medical/facility',
  name: 'medicalFacility',
  component: facility
},
{
  path: '/medical/treatment',
  name: 'medicalTreatment',
  component: treatment
},
{
  path: '/msc/tm',
  name: 'MSC&TM',
  component: mscTM
},
{
  path: '/medical/staff/training/group',
  name: 'trainingGroup',
  component: trainingGroup
},
{
  path: '/medical/staff/training/subgroup',
  name: 'trainingSubGroup',
  component: trainingSubGroup
},
{
  path: '/medical/staff/position',
  name: 'staffPosition',
  component: staffPosition
},
// MCU
{
  path: '/category/category',
  name: 'category',
  component: categoryMcu
},
{
  path: '/category/result',
  name: 'result',
  component: resultMcu
},
{
  path: '/test/category',
  name: 'categoryTest',
  component: categoryTest
},
{
  path: '/test/group',
  name: 'group',
  component: groupMcu
},
{
  path: '/test/item',
  name: 'item',
  component: itemMcu
},
{
  path: '/question/questionType',
  name: 'type',
  component: typeQuestion
},
{
  path: '/question/question',
  name: 'question',
  component: question
},

// IH
{
  path: '/ih/action',
  name: 'ihAction',
  component: ihAction
},
{
  path: '/ih/factor',
  name: 'ihFactor',
  component: ihFactor
},
{
  path: '/ih/measuring/activity',
  name: 'ihMeasuringActivity',
  component: ihMeasuringActivity
},
{
  path: '/ih/measuring/method',
  name: 'ihMeasuringMethod',
  component: ihMeasuringMethod
},
{
  path: '/ih/measuring/unit',
  name: 'ihMeasuringUnit',
  component: ihMeasuringUnit
},
{
  path: '/ih/measuring/detail/:id?',
  name: 'ihMeasurementDetail',
  component: ihMeasurementDetail
},
{
  path: '/ih/measuring/kit',
  name: 'ihKit',
  component: ihKit
},
{
  path: '/ih/measuring/campaign',
  name: 'ihCampaign',
  component: ihCampaign
},
{
  path: '/oh/activity',
  name: 'ohActivity',
  component: ohActivity
},
{
  path: '/oh/indicator',
  name: 'ohIndicator',
  component: ohIndicator
},
{
  path: '/oh/data',
  name: 'ohData',
  component: ohData
},
{
  path: '/oh/group-activity',
  name: 'ohDocument',
  component: ohDocument
},
{
  path: '/oh/yearly',
  name: 'yearly',
  component: yearly
},
// Healthcare Facility
{
  path: '/healthcare-facility',
  name: 'healthcareFacility',
  component: healthcare
},
// hazard
{
  path: '/chemical/material-type',
  name: 'chemical-material-type',
  component: chemicalMaterialType
},
{
  path: '/chemical/material-name',
  name: 'chemical-material-name',
  component: chemicalMaterialName
},
{
  path: '/chemical/hazard',
  name: 'chemical-hazard',
  component: chemicalHazard
},
{
  path: '/healthcare/fasility',
  name: 'healthcare-fasility',
  component: healthcareFasility
},

// Clinic 
{
  path: '/clinic-patient',
  name: 'clinic-patient',
  component: clinicPatient
},

{
  path: '/clinic-management/role-management-clinic',
  name: 'role-management-clinic',
  component: roleClinicManagenet
},
{
  path: '/clinic-management/input-po/:id?',
  name: 'input-po',
  component: clinicInputPO
},
{
  path: '/clinic-management/input-do/:id?',
  name: 'input-do',
  component: clinicInputDO
},

{
  path: '/clinic-management/inventory/list-po',
  name: 'list-po',
  component: clinicListPO
},
{
  path: '/clinic-management/inventory/list-do',
  name: 'list-do',
  component: clinicListDO
},
{
  path: '/clinic-management/inventory/entry-stock',
  name: 'entry-stock',
  component: entryStock
},
  {
    path: '/clinic-management/input-stock/:id?',
    name: 'input-stock',
    component: inputStock
  },
{
  path: '/clinic-management/inventory/drugs-&-medical',
  name: 'drugs-medical',
  component: reportStocks
},
{
  path: '/clinic-management/inventory/drugs-&-medical/drugs-detail/:id?',
  name: 'drugs-detail',
  component: detailDrugs
},
{
  path: '/clinic-management/inventory/drugs-&-medical/stock-detail/:id?',
  name: 'stock-detail',
  component: detailStock
},
{
  path: '/clinic-management/inventory/drugs-&-medical/mutation/:id?',
  name: 'mutation',
  component: Mutation
},
// {
//   path: '/clinic-management/inventory/stock-opname',
//   name: 'Opname',
//   component: Opname
// },
{
  path: '/clinic-management/inventory/stock-opname',
  name: 'Opname',
  component: Opname
},
{
  path: '/clinic-management/inventory/bin',
  name: 'Bin',
  component: Bin
},
{
  path: '/clinic-management/inventory/disposal',
  name: 'Disposal',
  component: Disposal
},

{
  path: '/clinic-management/drug_test',
  name: 'Drug Test',
  component: DrugTest
},
{
  path: '/clinic-management/drug_test/detail',
  name: 'Drug Test Detail',
  component: DrugTestDetail
},
{
  path: '/clinic-management/inventory/stock-opname/detail/:id?',
  name: 'Opname Detail',
  component: OpnameDetail
},
{
  path: '/clinic-management/inventory/stock-opname/register',
  name: 'Opname Add',
  component: OpnameAdd
},
{
  path: '/medical-record-mcu',
  name: 'medical-record-mcu',
  component: MedicalRecordMCU
},
{
  path: '/medical-record-mcu/record-mcu-riwayat',
  name: 'record-mcu-riwayat',
  component: recordMcuRiwayat
},
{
  path: '/clinic-mcu',
  name: 'clinic-mcu',
  component: clinicMcu
},
{
  path: '/no-partnership-clinic',
  name: 'no-partnership-clinic',
  component: noPartnership
},
{
  path: '/clinic-mcu/history/mcu-detail',
  name: 'mcuDetail',
  component: mcuDetail
},
{
  path: '/clinic-mcu/medical-record',
  name: 'medical-record',
  component: dataRecord
},
{
  path: '/clinic-mcu/history',
  name: 'historyMCU',
  component: history
},
{
  path: '/clinic-mcu/history/record-detail',
  name: 'recordDetail',
  component: recordDetail
},
{
  path: '/clinic-mcu/history/record-mcu',
  name: 'recordMCU',
  component: clinicMcu
},

// Oh Management 
{
  path: '/oh-management/input',
  name: 'input-management',
  component: detailManagement
},
{
  path: '/oh-management/correction',
  name: 'oh-correction',
  component: companyCorrection
},
{
  path: '/oh-management/recap-daily',
  name: 'oh-recap-daily',
  component: recapDaily
},
{
  path: '/oh-management/monthly/leading-lagging-indicator',
  name: 'leading-lagging-indicator',
  component: leadingLaggingIndicator
},
{
  path: '/oh-management/monthly/summary',
  name: 'summary',
  component: summary
},
{
  path: '/oh-management/monthly/tenaga-kerja-sakit',
  name: 'tenaga-kerja-sakit',
  component: tenagaKerjaSakit
},
{
  path: '/oh-management/approval',
  name: 'approval',
  component: approval
},

// Role Management
{
  path: '/role-management',
  name: 'role-management',
  component: roleManagement
},

// IH Management
{
  path: '/ih-measurement',
  name: 'ih-measurement',
  component: ihMeasurement
},
{
  path: '/ih-chemical',
  name: 'ih-chemical',
  component: ihChemical
},
// Notification
{
  path:'/notification-settings',
  name:'notification-settings',
  component:notificationSettings
},
// overhandle
{
  path:'/overhandle',
  name:'overhandle',
  component:overhandle
}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router