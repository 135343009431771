<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <!-- / .row -->

      <!-- Filter -->
      <!-- end of filter -->

      <!-- Table -->
      <div class="card mt-5">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">{{ $t("Medicine") }}</h4>
          <!-- Button -->
          <b-button
            v-b-modal.modal-1
            variant="primary"
            @click="isAdd = true"
            v-if="comp === '1'">
            <span class="fe fe-file-plus"></span>
            {{ $t("Add") }}
          </b-button>
          <b-button
            v-b-toggle.collapse-1
            variant="primary"
            style="margin-left: 7px">
            <span class="fe fe-sliders"></span>
          </b-button>
        </div>
        <b-collapse id="collapse-1">
          <form @submit.prevent="sorting()">
            <div class="row mt-4" style="margin-left: 15px; margin-right: 15px">
              <div class="col">
                <div class="form-group">
                  <label>{{ $t("Medicine Category") }}</label>
                  <v-select
                    v-model="body.item_category_id"
                    :options="category.data"
                    label="item_category_name"
                    :reduce="(e) => e.id"
                    multiple />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>{{ $t("Generic Name") }}</label>
                  <input
                    type="text"
                    v-model="body.item_generic_name"
                    class="form-control" />
                </div>
              </div>
            </div>

            <div class="row" style="margin-left: 15px; margin-right: 15px">
              <div class="col">
                <div class="form-group">
                  <label>{{ $t("Brand") }}</label>
                  <input
                    type="text"
                    v-model="body.item_product_name"
                    class="form-control" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>{{ $t("Manufacture") }}</label>
                  <input
                    type="text"
                    v-model="body.item_manufacturer"
                    class="form-control" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label"
                    >Order</label
                  >
                  <v-select v-model="body.order" :options="['ASC', 'DESC']" />
                  <!-- <v-select v-model="body.order" :options="['ASC', 'DESC']" @input="sorting" /> -->
                </div>
              </div>
            </div>
            <div class="row mb-3" style="margin-right: 15px">
              <div class="col"></div>
              <div class="col-auto">
                <!-- Button -->
                <button type="submit" class="btn btn-danger lift">
                  {{ $t("Filter") }}
                </button>
              </div>
            </div>
            <!-- / .row -->
          </form>
        </b-collapse>
        <vue-good-table
          :line-numbers="true"
          :columns="columns"
          :rows="drug.data"
          :sort-options="{
            enabled: true
          }"
          :total-rows="drug.totalItems"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            position: 'bottom',
            perPage: 10,
            perPageDropdown: [10, 50, 100],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All'
          }"
          style-class="vgt-table border-0 table"
          @on-page-change="onPageChange"
          @on-per-page-change="onPageChange">
          <!-- Loading -->
          <div slot="emptystate" class="text-center font-size-14">
            No data available
          </div>
          <template slot="table-column" slot-scope="props">
            <!-- Table Action -->
            <span v-if="props.column.label == 'CATEGORY'">
              {{ $t("Category") }}
            </span>
            <span v-if="props.column.label == 'GENERIC'">
              {{ $t("Generic") }}
            </span>
            <span v-if="props.column.label == 'BRAND'">
              {{ $t("Brand") }}
            </span>
            <span v-if="props.column.label == 'UOM'">
              {{ $t("UOM") }}
            </span>
            <span v-if="props.column.label == 'PATENT'">
              {{ $t("Manufacture") }}
            </span>
            <span v-if="props.column.label == 'ACTION'">
              {{ $t("Action") }}
            </span>
          </template>
          <!-- Custom Rows -->
          <template slot="table-row" slot-scope="props">
            <!-- Table Action -->
            <span v-if="props.column.field == 'action'">
              <b-button
                class="no-wrap btn-pill"
                variant="warning"
                size="sm"
                @click="updateData(props.row)"
                v-if="comp === '1'">
                <span class="fe fe-edit"></span>
              </b-button>
              <b-button
                class="no-wrap btn-pill"
                variant="info"
                size="sm"
                @click="details(props.row)"
                v-if="comp != '1'"
                v-b-modal.modal-lg>
                <span class="fe fe-list"></span>
              </b-button>
              <b-button
                class="no-wrap btn-pill ml-2"
                variant="warning"
                size="sm"
                @click="updateClinic(props.row)"
                v-if="comp != '1'">
                <span class="fe fe-edit"></span>
              </b-button>
              <b-button
                class="no-wrap ml-2"
                variant="danger"
                style="margin-left: 5px"
                size="sm"
                @click="deleteData(props.row.id)"
                v-if="comp === '1'">
                <span class="fe fe-trash-2"></span>
              </b-button>
            </span>
            <span v-else-if="props.column.field === 'uom'">
              <b-button
                class="no-wrap ml-2"
                variant="info"
                style="margin-left: 5px"
                size="sm"
                @click="uomDetail(props.row.uom)">
                UOM
              </b-button>
            </span>
          </template>
        </vue-good-table>
      </div>

      <!-- Modal -->
      <b-modal id="modal-1" hide-footer :title="$t('Medicine Form')">
        <form @submit.prevent="saveAdd()" v-if="isAdd === true">
          <div class="form-group">
            <label
              >{{ $t("Medicine Category")
              }}<span class="text-danger">*</span></label
            >
            <v-select
              v-model="bodyAdd.item_category_id"
              :options="category.data"
              label="item_category_name"
              :reduce="(e) => e.id"
              multiple
              name="Medicine Category"
              v-validate="'required'" />
            <span
              class="text-sm text-danger"
              v-show="errors.has('Medicine Category')"
              >{{ errors.first("Medicine Category") }}</span
            >
          </div>
          <div class="form-group">
            <label>Medicine Type<span class="text-danger">*</span></label>
            <v-select
              v-model="bodyAdd.item_product_type"
              :options="['GENERIC', 'PATENT']"
              name="Medicine Type"
              v-validate="'required'" />
            <span
              class="text-sm text-danger"
              v-show="errors.has('Medicine Type')"
              >{{ errors.first("Medicine Type") }}</span
            >
          </div>
          <div class="form-group">
            <label
              >{{ $t("Generic Name") }}<span class="text-danger">*</span></label
            >
            <input
              type="text"
              v-model="bodyAdd.item_generic_name"
              class="form-control"
              placeholder="Generic Name"
              v-validate="'required'"
              name="Generic Name" />
            <span
              class="text-sm text-danger"
              v-show="errors.has('Generic Name')"
              >{{ errors.first("Generic Name") }}</span
            >
          </div>
          <div class="form-group" v-if="bodyAdd.item_product_type === 'PATENT'">
            <label>{{ $t("Brand") }}<span class="text-danger">*</span></label>
            <input
              type="text"
              v-model="bodyAdd.item_product_name"
              class="form-control"
              placeholder="Brand"
              v-validate="'required'"
              name="Brand" />
            <span class="text-sm text-danger" v-show="errors.has('Brand')">{{
              errors.first("Brand")
            }}</span>
          </div>
          <!-- <div class="form-group">
            <label>{{ $t("Dosage") }}</label>
            <input type="text" v-model="bodyAdd.drugs_dose" class="form-control" placeholder="Dosage"
              v-validate="'required'" name="Dosage" />
            <span class="text-sm text-danger" v-show="errors.has('Dosage')">{{
              errors.first("Dosage")
            }}</span>
          </div> -->
          <div class="form-group" v-if="bodyAdd.item_product_type === 'PATENT'">
            <label
              >{{ $t("Manufacture") }}<span class="text-danger">*</span></label
            >
            <input
              type="text"
              v-model="bodyAdd.item_manufacturer"
              class="form-control"
              placeholder="Manufacture"
              v-validate="'required'"
              name="Manufacture" />
            <span
              class="text-sm text-danger"
              v-show="errors.has('Manufacture')"
              >{{ errors.first("Manufacture") }}</span
            >
          </div>
          <div class="card">
            <div class="card-header">
              <!-- Title -->
              <h4 class="card-header-title">Unit Medicine</h4>

              <!-- Link -->
              <a href="#!" class="small" @click="createNew()">Add Unit</a>
            </div>
            <div class="card-body">
              <!-- List group -->
              <div class="list-group list-group-flush my-n3">
                <div
                  class="list-group-item"
                  v-for="(unit, index) in bodyAdd.uom"
                  :key="index">
                  <div class="row align-items-center">
                    <div class="col-auto">
                      <!-- Avatar -->
                      <div class="form-group">
                        <label>{{ $t("Qty") }}</label>
                        <input
                          type="number"
                          v-model="unit.qty"
                          :disabled="index > 0 ? false : true"
                          class="form-control"
                          min="1" />
                      </div>
                    </div>
                    <div class="col ms-n2">
                      <div class="form-group">
                        <label
                          >{{ $t("Unit")
                          }}<span class="text-danger">*</span></label
                        >
                        <v-select
                          v-model="unit.uom_id"
                          :options="unitData.data"
                          label="unit_name"
                          :reduce="(e) => e.id"
                          v-validate="'required'"
                          :name="`Unit-${index}`" />
                        <span
                          class="text-sm text-danger"
                          v-show="errors.has(`Unit-${index}`)"
                          >{{ "Unit is Required" }}</span
                        >
                      </div>
                    </div>
                    <div class="col-auto" v-if="index > 0">
                      <!-- <button class="btn btn-primary" > -->
                      <span
                        class="fe fe-x"
                        style="cursor: pointer"
                        @click="removeUnit(index)"></span>
                      <!-- </button> -->
                    </div>
                  </div>
                  <!-- / .row -->
                </div>
              </div>
            </div>
            <!-- / .card-body -->
          </div>
          <div class="form-group">
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="submit"
              v-if="isLoading == false">
              {{ $t("Save") }}
            </button>
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="button"
              disabled
              v-else>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>

        <form @submit.prevent="saveUpdate()" v-else>
          <div class="form-group">
            <label
              >{{ $t("Medicine Category")
              }}<span class="text-danger">*</span></label
            >
            <v-select
              v-model="bodyUpdate.item_category_id"
              :options="category.data"
              label="item_category_name"
              :reduce="(e) => e.id"
              multiple
              name="Medicine Category"
              v-validate="'required'" />
            <span
              class="text-sm text-danger"
              v-show="errors.has('Medicine Category')"
              >{{ errors.first("Medicine Category") }}</span
            >
          </div>
          <div class="form-group">
            <label>Medicine Type<span class="text-danger">*</span></label>
            <v-select
              v-model="bodyUpdate.item_product_type"
              :options="['GENERIC', 'PATENT']" />
          </div>
          <div class="form-group">
            <label
              >{{ $t("Generic Name") }}<span class="text-danger">*</span></label
            >
            <input
              type="text"
              v-model="bodyUpdate.item_generic_name"
              class="form-control"
              placeholder="Generic Name"
              v-validate="'required'"
              name="Generic Name" />
            <span
              class="text-sm text-danger"
              v-show="errors.has('Generic Name')"
              >{{ errors.first("Generic Name") }}</span
            >
          </div>
          <div
            class="form-group"
            v-if="bodyUpdate.item_product_type === 'PATENT'">
            <label>{{ $t("Brand") }}<span class="text-danger">*</span></label>
            <input
              type="text"
              v-model="bodyUpdate.item_product_name"
              class="form-control"
              placeholder="Brand"
              v-validate="'required'"
              name="Brand" />
            <span class="text-sm text-danger" v-show="errors.has('Brand')">{{
              errors.first("Brand")
            }}</span>
          </div>
          <!-- <div class="form-group">
            <label>{{ $t("Dosage") }}</label>
            <input type="text" v-model="bodyAdd.drugs_dose" class="form-control" placeholder="Dosage"
              v-validate="'required'" name="Dosage" />
            <span class="text-sm text-danger" v-show="errors.has('Dosage')">{{
              errors.first("Dosage")
            }}</span>
          </div> -->
          <div
            class="form-group"
            v-if="bodyUpdate.item_product_type === 'PATENT'">
            <label
              >{{ $t("Manufacture") }}<span class="text-danger">*</span></label
            >
            <input
              type="text"
              v-model="bodyUpdate.item_manufacturer"
              class="form-control"
              placeholder="Manufacture"
              v-validate="'required'"
              name="Manufacture" />
            <span
              class="text-sm text-danger"
              v-show="errors.has('Manufacture')"
              >{{ errors.first("Manufacture") }}</span
            >
          </div>
          <div class="card">
            <div class="card-header">
              <!-- Title -->
              <h4 class="card-header-title">Unit Medicine</h4>

              <!-- Link -->
              <a href="#!" class="small" @click="createNewUpdate()">Add Unit</a>
            </div>
            <div class="card-body">
              <!-- List group -->
              <div class="list-group list-group-flush my-n3">
                <div
                  class="list-group-item"
                  v-for="(unit, index) in bodyUpdate.uom"
                  :key="index">
                  <div class="row align-items-center">
                    <div class="col-auto">
                      <!-- Avatar -->
                      <div class="form-group">
                        <label>{{ $t("Qty") }}</label>
                        <input
                          type="number"
                          v-model="unit.qty"
                          :disabled="index > 0 ? false : true"
                          class="form-control"
                          min="1" />
                      </div>
                    </div>
                    <div class="col ms-n2">
                      <div class="form-group">
                        <label
                          >{{ $t("Unit")
                          }}<span class="text-danger">*</span></label
                        >
                        <v-select
                          v-model="unit.uom_id"
                          :options="unitData.data"
                          label="unit_name"
                          :reduce="(e) => e.id"
                          v-validate="'required'"
                          :name="`Unit-${index}`" />
                        <span
                          class="text-sm text-danger"
                          v-show="errors.has(`Unit-${index}`)"
                          >{{ "Unit is Required" }}</span
                        >
                      </div>
                    </div>
                    <div class="col-auto" v-if="index > 0">
                      <!-- <button class="btn btn-primary" > -->
                      <span
                        class="fe fe-x"
                        style="cursor: pointer"
                        @click="removeUnitUpdate(index)"></span>
                      <!-- </button> -->
                    </div>
                  </div>
                  <!-- / .row -->
                </div>
              </div>
            </div>
            <!-- / .card-body -->
          </div>
          <div class="form-group">
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="submit"
              v-if="isLoading == false">
              {{ $t("Save") }}
            </button>
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="button"
              disabled
              v-else>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>
      </b-modal>
      <b-modal
        id="modal-lg"
        size="lg"
        hide-footer
        :title="`${detailDrug.item_generic_name} Detail`"
        centered>
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <table cellpadding="12" id="rawTable">
              <tr>
                <td>Category</td>
                <td>:</td>
                <td>{{ detailDrug.item_category_name }}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>:</td>
                <td>{{ detailDrug.item_generic_name }}</td>
              </tr>
              <tr>
                <td>Brand</td>
                <td>:</td>
                <td>{{ detailDrug.item_product_name }}</td>
              </tr>
              <tr>
                <td>Manufacture</td>
                <td>:</td>
                <td>{{ detailDrug.item_manufacturer }}</td>
              </tr>
              <tr>
                <td>Unit Of Metric</td>
                <td>:</td>
                <td>{{ detailDrug.uom_label }}</td>
              </tr>
            </table>
          </div>
          <!-- <div class="col-lg-8 col-md-12">
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th scope="col">NO</th>
                <th scope="col">QTY</th>
                <th scope="col">Unit</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in detailDrug.uom" :key="index">
                <th>{{ index + 1 }}</th>
                <td>{{ item.qty }}</td>
                <td>{{ item.item_label }}</td>
              </tr>
            </tbody>
          </table>
        </div> -->
        </div>
      </b-modal>
      <b-modal id="modal-clinic" hide-footer :title="$t('Drug Form')">
        <form @submit.prevent="saves()">
          <div class="form-group">
            <label>{{ $t("SAP Material Number") }}</label>
            <span style="color: red">*</span>
            <input
              type="text"
              v-model="bodyClinic.sap_material_number"
              class="form-control"
              placeholder="SAP Number"
              v-validate="'required'"
              name="SAP Number" />
            <span
              class="text-sm text-danger"
              v-show="errors.has('SAP Number')"
              >{{ errors.first("SAP Number") }}</span
            >
          </div>
          <div class="form-group">
            <label>{{ $t("Min Stock Type") }}</label>
            <span style="color: red">*</span>
            <v-select
              v-model="bodyClinic.min_stock_type"
              :options="['QUANTITY', 'PERCENTAGE']"
              v-validate="'required'"
              name="Min Stock Type" />
            <span
              class="text-sm text-danger"
              v-show="errors.has('Min Stock Type')"
              >{{ errors.first("Min Stock Type") }}</span
            >
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label>{{ $t("Min Stock") }}</label>
                <span style="color: red">*</span>
                <input
                  type="number"
                  v-model="bodyClinic.item_min_stock"
                  class="form-control"
                  placeholder="Min Stock"
                  v-validate="'required'"
                  name="Min Stock"
                  min="0" />
                <span
                  class="text-sm text-danger"
                  v-show="errors.has('Min Stock')"
                  >{{ errors.first("Min Stock") }}</span
                >
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label>{{ $t("Min Stock Unit") }}</label>
                <span style="color: red">*</span>
                <v-select
                  v-model="bodyClinic.item_min_stock_uom"
                  :options="dataClinic.uom"
                  :reduce="(e) => e.uom_id"
                  label="unit_name"
                  v-validate="'required'"
                  name="Min Stock Unit" />
                <span
                  class="text-sm text-danger"
                  v-show="errors.has('Min Stock Unit')"
                  >{{ errors.first("Min Stock Unit") }}</span
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label>{{ $t("Max Stock") }}</label>
                <input
                  type="number"
                  v-model="bodyClinic.item_max_stock"
                  class="form-control"
                  placeholder="Max Stock"
                  min="0" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label>{{ $t("Max Stock Unit") }}</label>
                <v-select
                  v-model="bodyClinic.item_max_stock_uom"
                  :options="dataClinic.uom"
                  :reduce="(e) => e.uom_id"
                  label="unit_name" />
              </div>
            </div>
          </div>

          <div class="form-group">
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="submit"
              v-if="isLoading == false">
              {{ $t("Save") }}
            </button>
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="button"
              disabled
              v-else>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>
      </b-modal>
      <!-- <b-modal id="modal-2" hide-footer centered :title="$t('Form Input')">
                <div class="form-group">
                    <label>Template</label>
                    <button class="form-control btn btn-primary" @click="downloadTemplate">
                        {{ $t("Download Template") }}
                    </button>
                </div>
                <form @submit.prevent="saveUpload()">
                    <div class="form-group">
                        <label>{{ $t("Company") }}<span class="text-danger">*</span></label>
                        <v-select :options="list.rows" v-model="bodyImport.company_id" label="company_name"
                            :reduce="(e) => e.id" v-validate="'required'" name="Company" />
                        <span class="text-sm text-danger"
                            v-show="errors.has('Company')">{{ errors.first("Company") }}</span>
                    </div>
                    <div class="form-group">
                        <label>{{ $t("Upload File") }}</label>
                        <input type="file" class="form-control" v-validate="'required'" ref="fileUpload" name="File"
                            @change="fileUploader1" />
                        <span class="text-sm text-danger" v-show="errors.has('File')">{{ errors.first("File") }}</span>
                        <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="submit"
                            v-if="isLoading == false">
                            {{ $t("Save") }}
                        </button>
                        <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="button"
                            disabled v-else>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>
                    </div>
                </form>
            </b-modal> -->
    </div>
  </div>
</template>
<script>
export default {
  // name: "drug-comp",
  computed: {
    drug() {
      return this.$store.getters["drug/DrugData"]
        ? this.$store.getters["drug/DrugData"]
        : [];
    },
    category() {
      return this.$store.getters["categoryDrug/CategoryData"]
        ? this.$store.getters["categoryDrug/CategoryData"]
        : [];
    },
    unitData() {
      return this.$store.getters["unit/UnitData"]
        ? this.$store.getters["unit/UnitData"]
        : [];
    }
  },
  data() {
    return {
      body: {
        page: 1,
        limit: 10,
        order: "",
        item_type: "MEDICINE",
        item_generic_name: "",
        item_category_id: [],
        item_manufacturer: "",
        item_product_name: ""
        // drugs_dose: ''
      },
      bodyAdd: {
        item_generic_name: "",
        item_category_id: [],
        item_manufacturer: "",
        item_product_name: "",
        item_product_type: "",
        item_type: "MEDICINE",
        uom: [
          {
            qty: 1,
            uom_id: ""
          }
        ]
      },
      bodyUpdate: {
        id: "",
        item_generic_name: "",
        item_category_id: [],
        item_manufacturer: "",
        item_product_name: "",
        item_product_type: "",
        item_type: "MEDICINE",
        uom: [
          {
            qty: "",
            uom_id: ""
          }
        ]
      },
      bodyClinic: {
        item_id: "",
        sap_material_number: "",
        min_stock_type: "",
        item_min_stock: "",
        item_min_stock_uom: "",
        item_max_stock: "",
        item_max_stock_uom: ""
      },
      uomDat: [],
      comp: "",
      detailDrug: {},
      dataClinic: {},
      isAdd: true,
      isLoading: false,
      columns: [
        {
          label: "CATEGORY",
          field: "item_category_name",
          thClass: "table-header text-muted w-auto font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "GENERIC",
          field: "item_generic_name",
          thClass: "table-header text-muted w-auto font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "BRAND",
          field: "item_product_name",
          thClass: "table-header text-muted w-auto font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        // {
        //   label: "UOM",
        //   field: "uom",
        //   thClass: "table-header text-muted w-auto font-weight-bold",
        //   tdClass: "color-secondary align-middle",
        // },
        {
          label: "PATENT",
          field: "item_manufacturer",
          thClass: "table-header text-muted w-auto font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "UOM",
          field: "uom_label",
          thClass: "table-header text-muted w-auto font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "ACTION",
          field: "action",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        }
      ]
    };
  },
  mounted() {
    if (this.drug && this.drug.length < 1) {
      this.getDrug();
      this.getCategory();
    }
    this.getUnit();
    this.comp = localStorage.getItem("company");
  },
  methods: {
    //action
    updateData(props) {
      this.$bvModal.show("modal-1");
      this.isAdd = false;
      this.bodyUpdate.id = props.id;
      this.bodyUpdate.item_generic_name = props.item_generic_name;
      this.bodyUpdate.item_category_id = props.item_category_id
        .split(",")
        .map((i) => Number(i));
      this.bodyUpdate.item_manufacturer = props.item_manufacturer;
      this.bodyUpdate.item_product_name = props.item_product_name;
      this.bodyUpdate.item_product_type = props.item_product_type;
      this.bodyUpdate.uom = props.uom;

      // this.bodyUpdate.drugs_dose = props.drugs_dose;
    },

    details(dat) {
      this.detailDrug = dat;
    },

    updateClinic(dat) {
      this.$bvModal.show("modal-clinic");
      this.dataClinic = dat;
      let body = {
        id: dat.id
      };
      this.$store.dispatch("drug/getDrugClinic", body).then(() => {
        let data = this.$store.getters["drug/DrugDataClinic"];

        this.bodyClinic.item_id = dat.id;
        this.bodyClinic.sap_material_number = data.sap_material_number;
        this.bodyClinic.min_stock_type = data.min_stock_type;
        this.bodyClinic.item_min_stock = data.item_min_stock;
        this.bodyClinic.item_min_stock_uom = data.item_min_stock_uom;
        this.bodyClinic.item_max_stock = data.item_max_stock;
        this.bodyClinic.item_max_stock_uom = item_max_stock_uom;
      });
    },

    uomDetail(data) {
      this.uomDat = data;
      this.$bvModal.show("detail-uom");
    },

    saves() {
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        try {
          this.isLoading = true;
          this.$store
            .dispatch("drug/editDrugClinic", this.bodyClinic)
            .then((resp) => {
              this.$swal({
                title: this.$t("Saved"),
                text: this.$t("Your input has been saved"),
                icon: "success",
                confirmButtonText: this.$t("OK")
              }).then((resp) => {
                this.isLoading = false;
                this.$bvModal.hide("modal-clinic");
              });
            });
        } catch (resp) {
          this.$swal("Error", this.$t("Error while edit data"), "error");
        }
      });
    },

    saveAdd() {
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        try {
          this.isLoading = true;
          this.$store.dispatch("drug/addDrug", this.bodyAdd).then((resp) => {
            this.$swal({
              title: this.$t("Saved"),
              text: this.$t("Your input has been saved"),
              icon: "success",
              confirmButtonText: this.$t("OK")
            }).then((resp) => {
              this.isLoading = false;
              (this.bodyAdd = {
                item_generic_name: "",
                item_category_id: [],
                item_manufacturer: "",
                item_product_name: "",
                item_product_type: "",
                item_type: "MEDICINE",
                uom: [
                  {
                    qty: 1,
                    uom_id: ""
                  }
                ]
              }),
                this.getDrug();
              this.$bvModal.hide("modal-1");
            });
          });
        } catch (resp) {
          this.$swal("Error", this.$t("Error while add data"), "error");
        }
      });
    },

    saveUpdate() {
      this.isLoading = true;
      this.$store
        .dispatch("drug/updateDrug", this.bodyUpdate)
        .then((resp) => {
          this.$swal({
            title: this.$t("Saved"),
            text: this.$t("Your input has been saved"),
            icon: "success",
            confirmButtonText: this.$t("OK")
          }).then((resp) => {
            this.isLoading = false;
            this.getDrug();
            this.$bvModal.hide("modal-1");
          });
        })
        .catch((resp) => {
          this.$swal("Error", this.$t("Error while add data"), "error");
        });
    },

    deleteData(id) {
      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00D97E",
        cancelButtonColor: "#E63757",
        confirmButtonText: this.$t("Yes, delete it!"),
        cancelButtonText: this.$t("Cancel")
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("drug/delDrug", id)
            .then((resp) => {
              this.getDrug();
              this.$swal(
                "Deleted!",
                this.$t("Your file has been deleted"),
                "success"
              );
            })
            .catch((resp) => {
              this.$swal("Error", this.$t("Error while delete data"), "error");
            });
        }
      });
    },

    createNew() {
      const blank_unit = {
        qty: "1",
        uom_id: ""
      };
      this.bodyAdd.uom.push(blank_unit);
    },
    removeUnit(index) {
      this.bodyAdd.uom.splice(index, 1);
    },

    createNewUpdate() {
      const blank_unit = {
        qty: "1",
        uom_id: ""
      };
      this.bodyUpdate.uom.push(blank_unit);
    },
    removeUnitUpdate(index) {
      this.bodyUpdate.uom.splice(index, 1);
    },

    // api hit
    async getDrug() {
      this.$store.dispatch("drug/getDrug", this.body);
    },
    async getCategory() {
      let body = {
        limit: 1000,
        page: 1
      };
      this.$store.dispatch("categoryDrug/getCategory", body);
    },
    async getUnit() {
      let body = {
        limit: 1000,
        page: 1
      };
      this.$store.dispatch("unit/getUnit", body);
    },

    // filter
    filterPage() {
      this.getDrug();
    },

    sorting() {
      // this.body.item_category_id = this.body.item_category_id.split(',').map(i => Number(i))
      this.getDrug();
    },

    // Pagination
    onPageChange(payload) {
      this.body.limit = payload.currentPerPage;
      this.body.page = payload.currentPage;
      // if (
      //   parseInt(payload.currentPage) * parseInt(payload.currentPerPage) >
      //   1000
      // ) {
      this.getDrug();
      // }
    }
  }
};
</script>
