<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <!-- / .row -->

      <!-- Filter -->
      <!-- end of filter -->

      <!-- Table -->
      <div class="card mt-5">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">Question</h4>
          <!-- Button -->
          <b-button v-b-modal.modal-1 variant="primary" @click="isAdd = true">
            <span class="fe fe-file-plus"></span>
            {{ $t("Add") }}
          </b-button>
          <b-button v-b-toggle.collapse-1 variant="primary" style="margin-left: 7px">
            <span class="fe fe-sliders"></span>
          </b-button>
          <!-- <b-button v-b-toggle.collapse-1 variant="primary" style="margin-left : 7px">
            <span class="fe fe-sliders"></span>
          </b-button> -->
        </div>
        <b-collapse id="collapse-1">
          <div class="mt-3 mb-5" style="margin-left: 10px">
            <p class="card-text">{{ $t("Filter") }}</p>
            <v-select v-model="body.order" :options="['ASC', 'DESC']" @input="sorting" />
          </div>
        </b-collapse>
        <vue-good-table :line-numbers="true" :columns="columns" :rows="question.data" :sort-options="{
            enabled: true
          }" :total-rows="question.totalItems" :pagination-options="{
            enabled: true,
            mode: 'pages',
            position: 'bottom',
            perPage: 10,
            perPageDropdown: [10, 50, 100],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All'
          }" style-class="vgt-table border-0 table" @on-page-change="onPageChange" @on-per-page-change="onPageChange">
          <!-- Loading -->
          <div slot="emptystate" class="text-center font-size-14">
            No data available
          </div>
          <!-- Custom Rows -->
          <template slot="table-column" slot-scope="props">
            <!-- Table Action -->
            <span v-if="props.column.label == 'ACTION'">
              {{ $t("Action") }}
            </span>
          </template>
          <template slot="table-row" slot-scope="props">
            <!-- Table Action -->
            <span v-if="props.column.field == 'action'">
              <b-button class="no-wrap btn-pill" variant="warning" size="sm" @click="updateData(props.row)">
                <span class="fe fe-edit"></span>
              </b-button>
              <b-button class="no-wrap ml-2" variant="danger" style="margin-left: 5px" size="sm"
                @click="deleteData(props.row.id)">
                <span class="fe fe-trash-2"></span>
              </b-button>
            </span>
          </template>
        </vue-good-table>
      </div>

      <!-- Modal -->
      <b-modal id="modal-1" hide-footer title="Question Form">
        <form @submit.prevent="saveAdd()" v-if="isAdd === true">
          <div class="form-category mb-3">
            <label>Question Type</label>
            <v-select v-model="question_type" :options="types.data" :reduce="(e) => e.id" label="type_name"
              v-validate="'required'" name="Question Type"></v-select>
            <span class="text-sm text-danger"
              v-show="errors.has('Question Type')">{{ errors.first("Question Type") }}</span>
          </div>
          <div class="form-category">
            <label>{{ $t("Question Name") }}</label>
            <input type="text" v-model="question_name" class="form-control" :placeholder="$t('Question Name')"
              v-validate="'required'" name="Question Name" />
            <span class="text-sm text-danger"
              v-show="errors.has('Question Name')">{{ errors.first("Question Name") }}</span>
            <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="submit"
              v-if="isLoading == false">
              {{ $t("Save") }}
            </button>
            <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="button" disabled v-else>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>

        <form @submit.prevent="saveUpdate()" v-else>
          <div class="form-category mb-3">
            <label>Question Type</label>
            <v-select v-model="question_types" :options="types.data" :reduce="(e) => e.id" label="type_name"
              v-validate="'required'" name="Question Type"></v-select>
            <span class="text-sm text-danger"
              v-show="errors.has('Question Type')">{{ errors.first("Question Type") }}</span>
          </div>
          <div class="form-category">
            <label>{{ $t("Question Name") }}</label>
            <input type="text" v-model="question_names" class="form-control" :placeholder="$t('Question Name')"  v-validate="'required'" name="Question Name"/>
            <span class="text-sm text-danger"
            v-show="errors.has('Question Name')">{{ errors.first("Question Name") }}</span>
            <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="submit"
              v-if="isLoading == false">
              {{ $t("Save") }}
            </button>
            <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="button" disabled v-else>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>
<script>
  import {
    reduce
  } from 'vue-cli-plugin-bootstrap-vue/sassAbstractsImports';

  export default {
    name: "question",
    computed: {
      question() {
        return this.$store.getters["question/QuestionData"] ?
          this.$store.getters["question/QuestionData"] : [];
      },
      types() {
        return this.$store.getters["typeQuestion/QuestionDataType"] ?
          this.$store.getters["typeQuestion/QuestionDataType"] : [];
      }
    },
    data() {
      return {
        body: {
          page: 1,
          limit: 10,
          order: ""
        },
        question_name: "",
        question_names: "",
        question_type: "",
        question_types: "",
        id: "",
        isAdd: true,
        isLoading: false,
        columns: [{
            label: "Question Type",
            field: "question_type_name",
            thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
            tdClass: "no-wrap color-secondary align-middle"
          },
          {
            label: "Question",
            field: "question",
            thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
            tdClass: "no-wrap color-secondary align-middle"
          },
          {
            label: "ACTION",
            field: "action",
            thClass: "table-header no-wrap text-muted font-weight-bold",
            tdClass: "no-wrap color-secondary align-middle"
          }
        ]
      };
    },
    mounted() {
      // if (this.category && this.category.length < 1) {
      this.getQuestion();
      this.getType();
      // }
    },
    methods: {
      //action
      updateData(props) {
        this.$bvModal.show("modal-1");
        this.isAdd = false;
        this.id = props.id;
        this.question_names = props.question;
        this.question_types = props.question_type_id;
      },
      // customStore() {
      //     this.$store.dispatch("categoryMcuTest/getCategory", this.category);
      // },
      saveAdd() {
        this.$validator.validateAll().then(async (res) => {
          if (!res) return false;
          try {
            this.isLoading = true;
            this.$store
              .dispatch("question/addQuestion", {
                question: this.question_name,
                question_type_id: this.question_type
              })
              .then((resp) => {
                this.$swal({
                  title: this.$t("Saved"),
                  text: this.$t("Your input has been saved"),
                  icon: "success",
                  confirmButtonText: this.$t("OK")
                }).then((resp) => {
                  this.isLoading = false;
                  this.question_name = "";
                  this.question_type = ""
                  this.getQuestion();
                  this.$bvModal.hide("modal-1");
                });
              }).catch((err) => {
                this.$swal.fire("Error!", `${err.response.data.error}`, "error");
                this.isLoading = false
              });
          } catch (resp) {
            this.$swal("Error", this.$t("Error while add data"), "error");
          }
        });
      },

      saveUpdate() {
        let body = {
          id: this.id,
          question: this.question_names,
          question_type_id: this.question_types
        };
        this.isLoading = true;
        this.$store
          .dispatch("question/updateQuestion", body)
          .then((resp) => {
            this.$swal({
              title: this.$t("Saved"),
              text: this.$t("Your input has been saved"),
              icon: "success",
              confirmButtonText: this.$t("OK")
            }).then((resp) => {
              this.isLoading = false;
              this.getQuestion();
              this.$bvModal.hide("modal-1");
            });
          })
          .catch((resp) => {
            this.isLoading = false
            this.$swal("Error", this.$t("Error while edit data"), "error");
          });
      },

      deleteData(id) {
        this.$swal({
          title: this.$t("Are you sure?"),
          text: this.$t("You won't be able to revert this!"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00D97E",
          cancelButtonColor: "#E63757",
          confirmButtonText: this.$t("Yes, delete it!"),
          cancelButtonText: this.$t("Cancel")
        }).then((question) => {
          if (question.isConfirmed) {
            this.$store
              .dispatch("question/delQuestion", id)
              .then((resp) => {
                this.getQuestion();
                this.$swal(
                  "Deleted!",
                  this.$t("Your file has been deleted"),
                  "success"
                );
              })
              .catch((resp) => {
                this.$swal("Error", this.$t("Error while delete data"), "error");
              });
          }
        });
      },

      // api hit
      getQuestion() {
        this.$store.dispatch("question/getQuestion", this.body);
      },
      getType() {
        this.$store.dispatch("typeQuestion/getQuestionType", this.body);
      },

      // filter
      filterPage() {
        this.getQuestion();
      },

      sorting() {
        this.getQuestion();
      },

      // Pagination
      onPageChange(payload) {
        this.body.limit = payload.currentPerPage;
        this.body.page = payload.currentPage;
        // if (
        //   parseInt(payload.currentPage) * parseInt(payload.currentPerPage) >
        //   1000
        // ) {
        this.getQuestion();
        // }
      }
    }
  };
</script>