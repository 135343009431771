<template>
  <!-- HEADER -->
  <div class="header">
    <!-- Body -->
    <div>
      <b-tabs content-class="mt-3" fill>
        <b-tab title="Plan">
          <div class="row">
            <div class="col">
              <div class="card-body">
                <!-- <div class="d-flex mb-3">
                  <label class="align-self-center mb-0 mr-3">Year</label>
                  <datepicker input-class="form-control w-auto" format="yyyy" minimum-view="year"
                    v-model="bodyPlan.year" @input="filterYear()" />
                  <label class="align-self-center mb-0 mr-3 ml-3">Group Acitivity</label>
                  <v-select class="w-25" v-model="bodyPlan.oh_document_id" :options="document.data"
                    label="document_type_name" :reduce="(e) => e.id" @input="getPlan()" />
                  <button class="btn btn-primary float-right ml-auto" v-b-modal.modal-new-plan
                    @click="isAddDoc = true">New Plan</button>
                </div> -->
                <div class="row">
                  <div class="col-lg-3">
                    <div class="form-group ml-3">
                      <label class="align-self-center mb-0 mr-3">Year</label>
                      <div class="d-flex">
                        <datepicker
                          input-class="form-control w-100"
                          format="yyyy"
                          minimum-view="year"
                          v-model="bodyPlan.year"
                          @input="filterYear()" />
                        <b-button
                          size="sm"
                          @click="clearYear2()"
                          variant="link"
                          class="ml-3"
                          v-if="bodyPlan.year">
                          <span class="fe fe-x"></span>
                        </b-button>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label class="align-self-center mb-0 mr-3"
                        >Group Activity</label
                      >
                      <v-select
                        class=""
                        v-model="bodyPlan.oh_document_id"
                        :options="document.data"
                        label="document_type_name"
                        :reduce="(e) => e.id"
                        @input="getPlan()" />
                    </div>
                  </div>
                  <div class="col-lg-4" v-if="filterCompany == 1">
                    <div class="form-group">
                      <label class="align-self-center mb-0 mr-3">{{
                        $t("Company")
                      }}</label>
                      <v-select
                        class=""
                        :options="selectCompanyOption()"
                        v-model="bodyPlan.company_id"
                        label="company_name"
                        :reduce="(e) => e.id"
                        @input="changeCompany2()" />
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class="d-flex justify-content-end mr-3">
                      <button
                        class="btn btn-primary mr-2"
                        v-b-modal.modal-new-plan
                        @click="isAddDoc = true">
                        <span class="fe fe-plus"></span>
                      </button>
                    </div>
                  </div>
                </div>
                <vue-good-table
                  mode="remote"
                  :line-numbers="true"
                  :columns="columnPlan"
                  :rows="plan.rows"
                  :total-rows="plan.totalRecords"
                  :sort-options="{
                    enabled: true
                  }"
                  :pagination-options="{
                    enabled: true,
                    mode: 'pages',
                    position: 'bottom',
                    perPage: bodyPlan.perPage,
                    perPageDropdown: [5, 10, 50, 100],
                    dropdownAllowAll: false,
                    setCurrentPage: 1,
                    nextLabel: 'next',
                    prevLabel: 'prev',
                    rowsPerPageLabel: 'Rows per page',
                    ofLabel: 'of',
                    pageLabel: 'page',
                    allLabel: 'All'
                  }"
                  style-class="vgt-table table table-bordered"
                  @on-sort-change="onSortChange"
                  @on-page-change="onPageChanges"
                  @on-per-page-change="onPageChanges"
                  :isLoading.sync="isSync">
                  <!-- Loading -->
                  <!-- @on-page-change="onPageChange"
                @on-per-page-change="onPageChange" -->
                  <div slot="emptystate" class="text-center font-size-14">
                    No data available
                  </div>
                  <template slot="table-row" slot-scope="props">
                    <!-- Table Action -->
                    <span v-if="props.column.field == 'action'">
                      <b-button
                        class="no-wrap ml-2"
                        variant="warning"
                        title="Edit"
                        style="margin-left: 5px"
                        size="sm"
                        @click="updatePlan(props.row)">
                        <span class="fe fe-edit"></span>
                      </b-button>
                      <b-button
                        class="no-wrap ml-2"
                        variant="danger"
                        title="Cancel"
                        style="margin-left: 5px"
                        size="sm"
                        @click="deletePlan(props.row.id)">
                        <span class="fe fe-archive"></span>
                      </b-button>
                    </span>
                    <!-- <span v-else-if="props.column.field == 'price'">
                    Rp. {{ convertNumber(props.row.price) }}
                  </span> -->
                  </template>
                </vue-good-table>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Actual">
          <div :class="`cart ${load === true ? '' : 'd-none'}`">
            <div class="card-body text-center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
          <div :class="`cart ${load === true ? 'd-none' : ''}`">
            <div class="row mt-3">
              <div class="col-lg-3">
                <div class="form-group ml-3">
                  <label class="align-self-center mb-0 mr-3">Year</label>
                  <div class="d-flex">
                    <datepicker
                      input-class="form-control w-100"
                      format="yyyy"
                      minimum-view="year"
                      v-model="body.year"
                      @input="filterYear2()" />
                    <b-button
                      size="sm"
                      @click="clearYear()"
                      variant="link"
                      class="ml-3"
                      v-if="body.year">
                      <span class="fe fe-x"></span>
                    </b-button>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label class="align-self-center mb-0 mr-3"
                    >Group Activity</label
                  >
                  <v-select
                    class=""
                    v-model="body.oh_document_id"
                    :options="document.data"
                    label="document_type_name"
                    :reduce="(e) => e.id"
                    @input="getActual()" />
                </div>
              </div>
              <div class="col-lg-4" v-if="filterCompany == 1">
                <div class="form-group">
                  <label class="align-self-center mb-0 mr-3">{{
                    $t("Company")
                  }}</label>
                  <v-select
                    class=""
                    :options="selectCompanyOption()"
                    v-model="body.company_id"
                    label="company_name"
                    :reduce="(e) => e.id"
                    @input="changeCompany()" />
                </div>
              </div>
              <div class="col-lg-2">
                <div class="d-flex justify-content-end mr-3">
                  <button
                    class="btn btn-primary mr-2"
                    v-b-modal.modal-1
                    @click="isAddDoc = true">
                    <span class="fe fe-plus"></span>
                  </button>
                  <button class="btn btn-primary" @click="downloadActual()">
                    <span class="fe fe-download"></span>
                  </button>
                </div>
              </div>
            </div>
            <div
              class="row mt-4 d-none"
              style="margin-left: 15px; margin-right: 15px">
              <!-- <div class="col-10" v-if="filterCompany == 1">
                <div class="form-group">
                  <label>{{ $t("Company") }}</label>
                  <v-select :options="selectCompanyOption()" v-model="body.company_id" label="company_name"
                    :reduce="(e) => e.id" @input="changeCompany()" />
                </div>
              </div> -->
              <div class="col">
                <div class="form-group">
                  <!-- <label for="exampleInputEmail1" class="form-label">Order</label> -->
                  <b-button
                    v-b-modal.modal-1
                    variant="primary"
                    @click="isAddDoc = true"
                    class="float-end">
                    <span class="fe fe-plus"></span>
                    {{ $t("Add") }}
                  </b-button>
                  <!-- <v-select v-model="body.order" :options="['ASC', 'DESC']" @input="sorting" /> -->
                </div>
              </div>
            </div>
            <hr />
            <b-collapse id="collapse-1">
              <form @submit.prevent="sorting()">
                <div
                  class="row mt-4"
                  style="margin-left: 15px; margin-right: 15px">
                  <div class="col">
                    <div class="form-group">
                      <label for="exampleInputEmail1" class="form-label">{{
                        $t("Company")
                      }}</label>
                      <v-select
                        :options="[
                          { label: 'UGEMS', id: 'UGEMS' },
                          { label: 'Februari', id: 'Februari' },
                          { label: 'Maret', id: 'Maret' }
                        ]" />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label for="exampleInputEmail1" class="form-label">{{
                        $t("Monthly")
                      }}</label>
                      <v-select
                        :options="[
                          { label: 'Januari', id: 'Januari' },
                          { label: 'Februari', id: 'Februari' },
                          { label: 'Maret', id: 'Maret' }
                        ]" />
                    </div>
                  </div>
                </div>
                <div class="row mb-3" style="margin-right: 15px">
                  <div class="col"></div>
                  <div class="col-auto">
                    <!-- Button -->
                    <button type="submit" class="btn btn-danger lift">
                      {{ $t("Filter") }}
                    </button>
                  </div>
                </div>
                <!-- / .row -->
              </form>
            </b-collapse>
            <!-- {{ actualDoc }} -->
            <vue-good-table
              mode="remote"
              :line-numbers="true"
              :columns="columnActual"
              :rows="actualDoc.rows"
              :total-rows="actualDoc.totalRecords"
              :sort-options="{
                enabled: true
              }"
              :pagination-options="{
                enabled: true,
                mode: 'pages',
                position: 'bottom',
                perPage: body.perPage,
                perPageDropdown: [5, 10, 50, 100],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page',
                allLabel: 'All'
              }"
              style-class="vgt-table table table-bordered"
              @on-sort-change="onSortChanges"
              @on-page-change="onPageChange"
              @on-per-page-change="onPageChange"
              :isLoading.sync="isSync">
              <!-- Loading -->
              <!-- @on-page-change="onPageChange"
                @on-per-page-change="onPageChange" -->
              <div slot="emptystate" class="text-center font-size-14">
                No data available
              </div>
              <template slot="table-row" slot-scope="props">
                <!-- Image -->
                <span v-if="props.column.field == 'image_path'">
                  <img
                    v-for="(i, index) in props.row.image_path"
                    :key="index"
                    style="max-height: 100px"
                    :src="url + i"
                    alt=""
                    v-b-modal.modal-2
                    @click="fotoPreview(props.row)" />
                  <!-- {{ i }} -->
                </span>
                <!-- Table Action -->
                <span v-else-if="props.column.field == 'action'">
                  <b-button
                    class="no-wrap btn-pill"
                    variant="warning"
                    size="sm"
                    @click="updateData(props.row)">
                    <span class="fe fe-edit"></span>
                  </b-button>
                  <b-button
                    class="no-wrap ml-2"
                    variant="danger"
                    style="margin-left: 5px"
                    size="sm"
                    @click="deleteData(props.row.id)">
                    <span class="fe fe-trash-2"></span>
                  </b-button>
                </span>
              </template>
            </vue-good-table>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <!-- Modal -->
    <b-modal id="modal-1" hide-footer :title="$t('Form Documentation')">
      <form @submit.prevent="saveAdd()" v-if="isAddDoc === true">
        <div class="form-group">
          <label
            >{{ $t("Documentation Name") }} <span class="text-danger">*</span>
          </label>
          <input
            type="text"
            v-model="payload.document_name"
            class="form-control"
            v-validate="'required'"
            name="Documentation Name" />
          <span
            class="text-sm text-danger"
            v-show="errors.has('Documentation Name')"
            >{{ errors.first("Documentation Name") }}</span
          >
        </div>
        <div class="form-group">
          <label>{{ $t("Date") }}<span class="text-danger">*</span></label>
          <input
            type="date"
            v-model="payload.date_input"
            class="form-control"
            placeholder="Pilih Tanggal"
            v-validate="'required'"
            name="Date"
            @input="dateInputActual('add')" />
          <span class="text-sm text-danger" v-show="errors.has('Date')">{{
            errors.first("Date")
          }}</span>
        </div>

        <div class="form-group">
          <label
            >{{ $t("Group Activity") }}<span class="text-danger">*</span></label
          >
          <v-select
            v-model="payload.document_type"
            :options="document.data"
            label="document_type_name"
            :reduce="(e) => e.id"
            v-validate="'required'"
            name="Group Activity"
            :clearable="false"
            @input="getPlanList('add')" />
          <span
            class="text-sm text-danger"
            v-show="errors.has('Group Activity')"
            >{{ errors.first("Group Activity") }}</span
          >
        </div>
        <div class="form-group" v-if="filterCompany == 1">
          <label class="align-self-center mb-0 mr-3">{{ $t("Company") }}</label>
          <v-select
            class=""
            :options="selectCompanyOption()"
            v-model="payload.company_id"
            label="company_name"
            :reduce="(e) => e.id"
            @input="getPlanList('add')" v-validate="'required'"
            name="Company" />
          <span class="text-sm text-danger" v-show="errors.has('Company')">{{
            errors.first("Company")
          }}</span>
        </div>
        <div class="form-group">
          <label>{{ $t("Plan List") }}<span class="text-danger">*</span></label>
          <v-select
            :disabled="
              payload.document_type && syncPlan == false ? false : true
            "
            v-model="payload.plan_id"
            :options="planList.rows"
            :loading="syncPlan"
            label="activity_name"
            :clearable="false"
            :reduce="(e) => e.id"
            v-validate="'required'"
            name="Plan List" />
          <span class="text-sm text-danger" v-show="errors.has('Plan List')">{{
            errors.first("Plan List")
          }}</span>
        </div>
        

        <div class="form-group">
          <label>{{ $t("Year") }} <span class="text-danger">*</span></label>
          <datepicker
            v-model="payload.year"
            input-class="form-control"
            format="yyyy"
            minimum-view="year"
            v-validate="'required'"
            name="Year" />
          <span class="text-sm text-danger" v-show="errors.has('Year')">{{
            errors.first("Year")
          }}</span>
        </div>
        <div class="form-group">
          <label>{{ $t("PIC") }} <span class="text-danger">*</span> </label>
          <input
            type="text"
            v-model="payload.pic"
            class="form-control"
            v-validate="'required'"
            name="PIC" />
          <span class="text-sm text-danger" v-show="errors.has('PIC')">{{
            errors.first("PIC")
          }}</span>
        </div>
        <div class="form-group">
          <label>{{ $t("Images") }}<span class="text-danger">*</span></label>
          <input
            type="file"
            multiple
            class="form-control"
            accept="image/*"
            v-validate="'required'"
            name="Image"
            ref="fileUpload"
            @change="fileUploader" />
          <span class="text-sm text-danger" v-show="errors.has('Image')">{{
            errors.first("Image")
          }}</span>
        </div>
        <!-- <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions"></vue-dropzone> -->

        <button
          style="margin-left: auto; float: right"
          class="mt-4 btn btn-primary"
          type="submit"
          :disabled="isLoading">
          <b-spinner small v-if="isLoading == true"></b-spinner>
          {{ $t("Save") }}
        </button>
      </form>

      <form @submit.prevent="saveUpdate()" v-else>
        <div class="form-group">
          <label>{{ $t("Documentation Name") }}</label>
          <input
            type="text"
            v-model="payloads.document_name"
            class="form-control"
            placeholder="Documentation Name"
            v-validate="'required'"
            name="Documentation Name" />
          <span
            class="text-sm text-danger"
            v-show="errors.has('Documentation Name')"
            >{{ errors.first("Documentation Name") }}</span
          >
        </div>
        <div class="form-group">
          <label>{{ $t("Date") }}</label>
          <input
            type="date"
            v-model="payloads.date_input"
            class="form-control"
            placeholder="Pilih Tanggal"
            v-validate="'required'"
            name="Date"
            @input="dateInputActual('update')" />
          <span class="text-sm text-danger" v-show="errors.has('Date')">{{
            errors.first("Date")
          }}</span>
        </div>
        <div class="form-group">
          <label
            >{{ $t("Group Activity") }}<span class="text-danger">*</span></label
          >
          <v-select
            v-model="payloads.document_type"
            :options="document.data"
            label="document_type_name"
            :reduce="(e) => e.id"
            v-validate="'required'"
            name="Group Activity"
            @input="getPlanList('update')"
            :clearable="false" />
          <span
            class="text-sm text-danger"
            v-show="errors.has('Group Activity')"
            >{{ errors.first("Group Activity") }}</span
          >
        </div>

        <div class="form-group" v-if="filterCompany == 1">
          <label class="align-self-center mb-0 mr-3">{{ $t("Company") }}</label>
          <v-select
            class=""
            :options="selectCompanyOption()"
            v-model="payloads.company_id"
            label="company_name"
            :reduce="(e) => e.id"
            @input="getPlanList('update')" />
        </div>
        <div class="form-group">
          <label>{{ $t("Plan List") }}<span class="text-danger">*</span></label>
          <v-select
            :disabled="
              payloads.document_type && syncPlan == false ? false : true
            "
            v-model="payloads.plan_id"
            :options="planList.rows"
            :loading="syncPlan"
            :clearable="false"
            label="activity_name"
            :reduce="(e) => e.id"
            v-validate="'required'"
            name="Plan List" />
          <span class="text-sm text-danger" v-show="errors.has('Plan List')">{{
            errors.first("Plan List")
          }}</span>
        </div>

        <div class="form-group">
          <label>{{ $t("Year") }} <span class="text-danger">*</span></label>
          <datepicker
            v-model="payloads.year"
            input-class="form-control"
            format="yyyy"
            minimum-view="year"
            v-validate="'required'"
            name="Year" />
          <span class="text-sm text-danger" v-show="errors.has('Year')">{{
            errors.first("Year")
          }}</span>
        </div>
        <div class="form-group">
          <label>{{ $t("PIC") }} <span class="text-danger">*</span> </label>
          <input
            type="text"
            v-model="payloads.pic"
            class="form-control"
            v-validate="'required'"
            name="PIC" />
          <span class="text-sm text-danger" v-show="errors.has('PIC')">{{
            errors.first("PIC")
          }}</span>
        </div>
        <div class="form-group">
          <label>{{ $t("Image") }}</label>
          <input
            type="file"
            multiple
            class="form-control"
            v-validate="'required'"
            accept="image/*"
            ref="fileUpload"
            @change="fileUploader2" />
          <span class="text-sm text-danger" v-show="errors.has('file')">{{
            errors.first("file")
          }}</span>
        </div>
        <div class="form-group">
          <button
            style="margin-left: auto; float: right"
            class="mt-4 btn btn-primary"
            type="submit"
            :disabled="isLoading">
            <b-spinner small v-if="isLoading == true"></b-spinner>
            {{ $t("Save") }}
          </button>
        </div>
      </form>
    </b-modal>
    <b-modal id="modal-new-plan" hide-footer :title="$t('Form Plan')">
      <form @submit.prevent="savePlan()" v-if="isAddDoc === true">
        <div class="form-group">
          <label>{{ $t("Year") }} <span class="text-danger">*</span></label>
          <datepicker
            v-model="planPayload.year"
            input-class="form-control"
            format="yyyy"
            minimum-view="year"
            v-validate="'required'"
            name="Year" />
          <span class="text-sm text-danger" v-show="errors.has('Year')">{{
            errors.first("Year")
          }}</span>
        </div>
        <div class="form-group">
          <label
            >{{ $t("Group Activity") }}<span class="text-danger">*</span></label
          >
          <v-select
            v-model="planPayload.oh_document_id"
            :options="document.data"
            label="document_type_name"
            :reduce="(e) => e.id"
            v-validate="'required'"
            name="Group Activity" />
          <span
            class="text-sm text-danger"
            v-show="errors.has('Group Activity')"
            >{{ errors.first("Group Activity") }}</span
          >
        </div>
        <div class="form-group" v-if="filterCompany == 1">
          <label class="align-self-center mb-0 mr-3"
            >{{ $t("Company") }}<span class="text-danger">*</span></label
          >
          <v-select
            class=""
            :options="selectCompanyOption()"
            v-model="planPayload.company_id"
            label="company_name"
            :reduce="(e) => e.id" v-validate="'required'"
            name="Company" />
          <span class="text-sm text-danger" v-show="errors.has('Company')">{{
            errors.first("Company")
          }}</span>
        </div>
        <div class="form-group">
          <label>{{ $t("Activity") }}<span class="text-danger">*</span></label>
          <input
            type="text"
            v-model="planPayload.activity_name"
            class="form-control"
            v-validate="'required'"
            name="Activity" />
          <span class="text-sm text-danger" v-show="errors.has('Activity')">{{
            errors.first("Activity")
          }}</span>
        </div>
        <div class="form-group">
          <label>{{ $t("Target") }}<span class="text-danger">*</span></label>
          <input
            type="number"
            v-model="planPayload.target"
            class="form-control"
            v-validate="'required'"
            name="Target" />
          <span class="text-sm text-danger" v-show="errors.has('Target')">{{
            errors.first("Target")
          }}</span>
        </div>
        <div class="form-group">
          <label
            >{{ $t("Yearly Target") }}<span class="text-danger">*</span></label
          >
          <input
            type="text"
            v-model="planPayload.yearly_target"
            class="form-control"
            v-validate="'required'"
            name="Yearly Target" />
          <span
            class="text-sm text-danger"
            v-show="errors.has('Yearly Target')"
            >{{ errors.first("Yearly Target") }}</span
          >
        </div>
        <div class="form-group">
          <label>{{ $t("Remark") }}</label>
          <textarea
            class="form-control"
            v-model="planPayload.remark"
            rows="3" />
        </div>
        <button
          style="margin-left: auto; float: right"
          class="mt-4 btn btn-primary"
          type="submit"
          :disabled="isLoading">
          <b-spinner small v-if="isLoading == true"></b-spinner>
          {{ $t("Save") }}
        </button>
      </form>

      <form @submit.prevent="saveUpdatePlan()" v-else>
        <div class="form-group">
          <label>{{ $t("Year") }} <span class="text-danger">*</span></label>
          <datepicker
            v-model="planUpdate.year"
            input-class="form-control"
            format="yyyy"
            minimum-view="year"
            v-validate="'required'"
            name="Year" />
          <span class="text-sm text-danger" v-show="errors.has('Year')">{{
            errors.first("Year")
          }}</span>
        </div>
        <div class="form-group">
          <label
            >{{ $t("Group Activity") }}<span class="text-danger">*</span></label
          >
          <v-select
            v-model="planUpdate.oh_document_id"
            :options="document.data"
            label="document_type_name"
            :reduce="(e) => e.id"
            v-validate="'required'"
            name="Group Activity" />
          <span
            class="text-sm text-danger"
            v-show="errors.has('Group Activity')"
            >{{ errors.first("Group Activity") }}</span
          >
        </div>
        <div class="form-group">
          <label>{{ $t("Activity") }}<span class="text-danger">*</span></label>
          <input
            type="text"
            v-model="planUpdate.activity_name"
            class="form-control"
            v-validate="'required'"
            name="Activity" />
          <span class="text-sm text-danger" v-show="errors.has('Activity')">{{
            errors.first("Activity")
          }}</span>
        </div>
        <div class="form-group">
          <label>{{ $t("Target") }}<span class="text-danger">*</span></label>
          <input
            type="number"
            v-model="planUpdate.target"
            class="form-control"
            v-validate="'required'"
            name="Target" />
          <span class="text-sm text-danger" v-show="errors.has('Target')">{{
            errors.first("Target")
          }}</span>
        </div>
        <div class="form-group">
          <label
            >{{ $t("Yearly Target") }}<span class="text-danger">*</span></label
          >
          <input
            type="text"
            v-model="planUpdate.yearly_target"
            class="form-control"
            v-validate="'required'"
            name="Yearly Target" />
          <span
            class="text-sm text-danger"
            v-show="errors.has('Yearly Target')"
            >{{ errors.first("Yearly Target") }}</span
          >
        </div>
        <div class="form-group">
          <label>{{ $t("Remark") }}</label>
          <textarea class="form-control" v-model="planUpdate.remark" rows="3" />
        </div>
        <button
          style="margin-left: auto; float: right"
          class="mt-4 btn btn-primary"
          type="submit"
          :disabled="isLoading">
          <b-spinner small v-if="isLoading == true"></b-spinner>
          {{ $t("Save") }}
        </button>
      </form>
    </b-modal>
    <!-- Modal Foto  -->
    <b-modal
      id="modal-2"
      size="xl"
      hide-footer
      :title="$t('Foto Documentation')">
      <b-carousel
        id="carousel1"
        style="text-shadow: 1px 1px 2px #333"
        controls
        indicators
        background="white"
        :interval="4000"
        img-width="1024"
        img-height="300"
        v-model="slide"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
        class="mycarousel">
        <b-carousel-slide
          class="item row"
          v-for="(i, index) in payloads.files"
          :key="index">
          <img
            slot="img"
            class="d-block img-fluid w-100"
            :src="url + i"
            alt="factory picture" />
        </b-carousel-slide>
      </b-carousel>
    </b-modal>
  </div>
</template>
<script>
import moment from "moment";
import axiosIns from "@/api/axios";
import Datepicker from "vuejs-datepicker";
import queryString from "query-string";
export default {
  name: "yearly",
  computed: {
    actualDoc() {
      return this.$store.getters["documentation/actualData"]
        ? this.$store.getters["documentation/actualData"]
        : [];
    },
    document() {
      return this.$store.getters["document/DocumentData"]
        ? this.$store.getters["document/DocumentData"]
        : [];
    },
    company() {
      return this.$store.getters["detailManagement/dataCompany"]
        ? this.$store.getters["detailManagement/dataCompany"]
        : [];
    },
    companySelect() {
      return this.$store.getters["detailManagement/companySelectData"]
        ? this.$store.getters["detailManagement/companySelectData"]
        : "1";
    },
    plan() {
      return this.$store.getters["documentation/planData"]
        ? this.$store.getters["documentation/planData"]
        : [];
    },
    planList() {
      return this.$store.getters["documentation/planListData"]
        ? this.$store.getters["documentation/planListData"]
        : [];
    }
  },
  components: {
    Datepicker
  },
  data() {
    return {
      body: {
        page: 1,
        perPage: 10,
        year: "",
        oh_document_id: "",
        company_id: localStorage.getItem("company"),
        type_input: "monthly",
        oh_type: "document"
      },
      bodyPlan: {
        page: 1,
        perPage: 10,
        company_id: localStorage.getItem("company"),
        year: moment().format("YYYY"),
        oh_document_id: "",
        sort: ""
      },
      bodys: {
        limit: 10,
        page: 1
      },
      payload: {
        plan_id: "",
        year: "",
        pic: "",
        company_id: "",
        document_name: "",
        document_type: "",
        date_input: "",
        files: []
      },
      payloads: {
        id: "",
        plan_id: "",
        year: "",
        pic: "",
        company_id: "",
        document_name: "",
        document_type: "",
        date_input: "",
        files: []
      },
      planPayload: {
        year: moment().format("YYYY"),
        oh_document_id: "",
        activity_name: "",
        target: "",
        yearly_target: "",
        remark: "",
        company_id: ""
      },
      planUpdate: {
        id: "",
        year: moment().format("YYYY"),
        oh_document_id: "",
        activity_name: "",
        target: "",
        yearly_target: "",
        remark: ""
      },
      url: "",
      isAddDoc: false,
      load: true,
      filterCompany: "",
      slide: 0,
      sliding: null,
      isLoading: false,
      isSync: false,
      listYear: [],
      syncPlan: false,
      rows: [
        {
          documentation_name: "Observasi Pengecekan kelelahan",
          jenis: "Fatigue cek",
          documentation:
            "https://ugems.id/documents/d/guest/horizontal-warna-1-png",
          role: true
        },
        {
          documentation_name: "Check Fatiq",
          jenis: "Fatigue cek",
          documentation:
            "https://ugems.id/documents/d/guest/horizontal-warna-1-png",
          role: false
        }
      ],
      columnActual: [
        {
          label: "Documentation Name",
          field: "value",
          thClass: "table-header no-wrap w-AUTO text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Group Activity",
          field: "oh_label",
          thClass: "table-header no-wrap w-AUTO text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Year",
          field: "year",
          thClass:
            "table-header text-center no-wrap w-AUTO text-muted font-weight-bold",
          tdClass: "no-wrap text-center color-secondary align-middle"
        },
        {
          label: "Target",
          field: "target",
          thClass: "table-header no-wrap w-AUTO text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "DOCUMENTATION",
          field: "image_path",
          sortable: false,
          thClass:
            "table-header text-center no-wrap w-AUTO text-muted font-weight-bold",
          tdClass: "no-wrap text-center color-secondary align-middle"
        },
        {
          label: "ACTION",
          field: "action",
          sortable: false,
          thClass:
            "table-header text-center no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap text-center color-secondary align-middle"
        }
      ],
      columnPlan: [
        {
          label: "Company",
          field: "company_name",
          thClass: "table-header no-wrap w-AUTO text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Year",
          field: "year",
          thClass: "table-header no-wrap w-AUTO text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Group Activity",
          field: "document_type_name",
          thClass: "table-header no-wrap w-AUTO text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Activity",
          field: "activity_name",
          thClass: "table-header no-wrap w-AUTO text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Yearly Target",
          field: "yearly_target",
          thClass: "table-header no-wrap w-AUTO text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Plan",
          field: "target",
          thClass: "table-header no-wrap w-AUTO text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Remark",
          field: "remark",
          thClass: "table-header no-wrap w-AUTO text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "ACTION",
          field: "action",
          sortable: false,
          thClass:
            "table-header text-center no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap text-center color-secondary align-middle"
        }
      ],
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: {
          "My-Awesome-Header": "header value"
        }
      }
    };
  },
  mounted() {
    const company = localStorage.getItem("company");
    const roles = localStorage.getItem("roles_id");
    this.filterCompany = parseInt(roles) == 1 ? 1 : 0;
    // if (this.filterCompany === 1) {
    //   this.body.company_id = this.companySelect
    // }

    let year = moment().format("YYYY");
    for (let i = parseInt(year) - 2; i < parseInt(year) + 3; i++) {
      this.listYear.push(i);
    }
    this.getPlan();
    this.getActual();
    this.getDocument();
    this.url = axiosIns.defaults.baseURL;

    // if (this.actualDoc && this.actualDoc.length < 1) {
    //   this.getActual();
    // }
    // if (this.activity && this.activity.length < 1) {
    //   this.getActivity();
    // }
  },
  methods: {
    selectCompanyOption() {
      const company = this.company;
      const term = [];
      term.push({
        id: 0,
        company_name: "BIB + Mitra"
      });

      company.map((i) => {
        term.push({
          id: i.id,
          company_name: i.company_name
        });
      });

      return term;
    },
    clearYear() {
      this.body.year = "";
      this.getActual();
    },
    clearYear2() {
      this.bodyPlan.year = "";
      this.getPlan();
    },
    async fileUploader(event) {
      this.payload.files = event.target.files;
    },
    async fileUploader2(event) {
      this.payloads.files = event.target.files;
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    filterYear() {
      this.bodyPlan.year = moment(this.bodyPlan.year).format("YYYY");
      this.getPlan();
    },
    filterYear2() {
      this.body.year = moment(this.body.year).format("YYYY");
      this.getActual();
    },

    downloadActual() {
      let bod = {
        year: this.body.year,
        oh_document_id: this.body.oh_document_id,
        company_id: this.body.company_id
      };
      let params = queryString.stringify(bod);
      axiosIns
        .get(
          `${axiosIns.defaults.baseURL}/oh/management/doc/export?${params}`,
          {
            responseType: "blob"
          }
        )
        .then((res) => {
          const href = URL.createObjectURL(res.data);
          // create "a" HTML element with href to file & click
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", `Actual Report.xlsx`); //or any other extension
          document.body.appendChild(link);
          link.click();
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
    },

    //action
    updatePlan(props) {
      this.$bvModal.show("modal-new-plan");
      this.isAddDoc = false;

      this.planUpdate.id = props.id;
      this.planUpdate.year = `${props.year}-08-01`;
      this.planUpdate.oh_document_id = props.oh_document_id;
      this.planUpdate.activity_name = props.activity_name;
      this.planUpdate.target = props.target;
      this.planUpdate.yearly_target = props.yearly_target;
      this.planUpdate.remark = props.remark;
    },

    dateInputActual(e) {
      if (e == "add") {
        let year = "";
        year = this.payload.date_input;
        this.payload.year = moment(year).format("YYYY");
      } else {
        let year = "";
        year = this.payloads.date_input;
        this.payloads.year = moment(year).format("YYYY");
      }
    },

    async updateData(props) {
      this.payloads.document_type = props.oh_id;
      this.payloads.company_id = props.company_id.toString();
      this.syncPlan = true;
      this.$store
        .dispatch("documentation/getPlanList", {
          page: 1,
          perPage: 100,
          oh_document_id: props.oh_id
        })
        .then(() => {
          this.syncPlan = false;
          this.payloads.plan_id = props.plan_id;
        });

      this.$bvModal.show("modal-1");
      this.isAddDoc = false;
      this.payloads.id = props.id;
      this.payloads.year = `${props.year}-08-01`;
      this.payloads.pic = props.pic;

      this.payloads.document_name = props.value;
      this.payloads.date_input = props.date_input;
      const files = [];
      let i = 0;
      let container = new DataTransfer();
      while (i < props.image_path.length) {
        const image = props.image_path[i];
        const image_url = `https://api.ugems.id/hms/api/${image}`;
        const response = await fetch(image_url);
        // here image is url/location of image
        const blob = await response.blob();
        const file = new File([blob], image, {
          type: blob.type
        });

        files.push(file);
        container.items.add(file);
        i++;

        this.payloads.files = container.files;
      }

      this.$refs.fileUpload.files = container.files;
    },
    changeCompany() {
      const company = this.body.company_id;
      this.getActual();
      this.$store.commit("detailManagement/setCompanySelect", company);
    },
    changeCompany2() {
      const company = this.bodyPlan.company_id;
      this.getPlan();
      this.$store.commit("detailManagement/setCompanySelect", company);
    },

    fotoPreview(props) {
      this.$bvModal.show("modal-2");
      this.isAddDoc = false;
      this.payloads.id = props.id;
      this.payloads.files = props.image_path;
      this.$bvModal.hide("modal-1");
    },

    saveAdd() {
      this.payload.year = moment(this.payload.year).format("YYYY");
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        try {
          this.isLoading = true;
          const formData = new FormData();
          formData.append("document_name", this.payload.document_name);
          formData.append("document_type", this.payload.document_type);
          formData.append("pic", this.payload.pic);
          formData.append("company_id", this.payload.company_id);
          formData.append("year", this.payload.year);
          formData.append("plan_id", this.payload.plan_id);
          formData.append("date_input", this.payload.date_input);
          for (let file of this.payload.files) {
            formData.append("files", file); // note, no square-brackets
          }
          if (!res) return false;
          try {
            this.$store
              .dispatch("documentation/addOhDoc", formData)
              .then((resp) => {
                this.$swal(
                  "Saved",
                  "Your input has been saved",
                  "success"
                ).then((resp) => {
                  // this.hazard_name = '';
                  this.getActual();
                  this.isLoading = false;
                  (this.payload = {
                    document_name: "",
                    document_type: "",
                    date_input: "",
                    files: []
                  }),
                    this.$bvModal.hide("modal-1");
                });
              })
              .catch((resp) => {
                this.$swal(
                  "Error",
                  "Something Went Wrong, Please Try Again",
                  "error"
                );
                this.isLoading = false;
              });
          } catch (resp) {
            this.$swal("Error", this.$t("Error while add data"), "error");
          }
        } catch (resp) {
          this.$swal("Error", this.$t("Error while add data"), "error");
        }
      });
    },

    savePlan() {
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        try {
          this.planPayload.year = moment(this.planPayload.year).format("YYYY");
          this.isLoading = true;
          this.$store
            .dispatch("documentation/addPlan", this.planPayload)
            .then((resp) => {
              this.$swal("Saved", "Your input has been saved", "success").then(
                (resp) => {
                  // this.hazard_name = '';
                  this.getPlan();
                  this.isLoading = false;
                  (this.planPayload = {
                    year: moment().format("YYYY"),
                    oh_document_id: "",
                    activity_name: "",
                    target: "",
                    yearly_target: "",
                    remark: ""
                  }),
                    this.$bvModal.hide("modal-new-plan");
                }
              );
            })
            .catch((resp) => {
              this.$swal(
                "Error",
                "Something Went Wrong, Please Try Again",
                "error"
              );
              this.isLoading = false;
            });
        } catch (resp) {
          this.$swal("Error", this.$t("Error while add data"), "error");
        }
      });
    },

    saveUpdatePlan() {
      this.planUpdate.year = moment(this.planUpdate.year).format("YYYY");
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        try {
          this.isLoading = true;
          this.$store
            .dispatch("documentation/updatePlan", this.planUpdate)
            .then((resp) => {
              this.$swal("Saved", "Your input has been saved", "success").then(
                (resp) => {
                  this.getPlan();
                  this.isLoading = false;
                  this.$bvModal.hide("modal-new-plan");
                }
              );
            })
            .catch((resp) => {
              this.$swal(
                "Error",
                "Something Went Wrong, Please Try Again",
                "error"
              );
              this.isLoading = false;
            });
        } catch (resp) {
          this.$swal("Error", this.$t("Error while save data"), "error");
        }
      });
    },

    saveUpdate() {
      this.payloads.year = moment(this.payloads.year).format("YYYY");
      this.isLoading = true;
      const formData = new FormData();
      formData.append("id", this.payloads.id);
      formData.append("document_name", this.payloads.document_name);
      formData.append("document_type", this.payloads.document_type);
      formData.append("company_id", this.payload.company_id);
      formData.append("date_input", this.payloads.date_input);
      formData.append("pic", this.payloads.pic);
      formData.append("year", this.payloads.year);
      formData.append("plan_id", this.payloads.plan_id);
      for (let file of this.payloads.files) {
        formData.append("files", file); // note, no square-brackets
      }

      this.$store
        .dispatch("documentation/updateDoc", formData)
        .then((resp) => {
          this.$swal({
            title: this.$t("Saved"),
            text: this.$t("Your input has been saved"),
            icon: "success",
            confirmButtonText: this.$t("OK")
          }).then((resp) => {
            this.getActual();
            this.isLoading = false;
            this.$bvModal.hide("modal-1");
          });
        })
        .catch((resp) => {
          this.isLoading = false
          this.$swal("Error", this.$t("Error while edit data"), "error");
        });
    },

    deleteData(id) {
      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00D97E",
        cancelButtonColor: "#E63757",
        confirmButtonText: this.$t("Yes, delete it!"),
        cancelButtonText: this.$t("Cancel")
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("documentation/delDoc", id)
            .then((resp) => {
              // this.getChemical();
              this.$swal(
                "Deleted!",
                this.$t("Your file has been deleted"),
                "success"
              );
              this.getActual();
            })
            .catch((resp) => {
              this.$swal("Error", this.$t("Error while delete data"), "error");
            });
        }
      });
    },

    deletePlan(id) {
      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00D97E",
        cancelButtonColor: "#E63757",
        confirmButtonText: this.$t("Yes, delete it!"),
        cancelButtonText: this.$t("Cancel")
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("documentation/deletePlan", id)
            .then((resp) => {
              // this.getChemical();
              this.$swal(
                "Deleted!",
                this.$t("Your record has been deleted"),
                "success"
              );
              this.getPlan();
            })
            .catch((resp) => {
              this.$swal("Error", this.$t("Error while delete data"), "error");
            });
        }
      });
    },

    // api hit
    getActual() {
      this.isSync = true;
      this.$store.dispatch("documentation/getActual", this.body).then(() => {
        this.isSync = false;
      });
    },

    getPlan() {
      this.isSync = true;
      this.$store.dispatch("documentation/getPlans", this.bodyPlan).then(() => {
        this.isSync = false;
      });
    },

    getPlanList(e) {
      if (e == "add") {
        this.syncPlan = true;
        this.payload.plan_id = "";
        this.$store
          .dispatch("documentation/getPlanList", {
            page: 1,
            perPage: 100,
            oh_document_id: this.payload.document_type,
            company_id: this.payload.company_id
          })
          .then(() => {
            this.syncPlan = false;
          });
      } else {
        this.payloads.plan_id = "";
        this.syncPlan = true;
        this.$store
          .dispatch("documentation/getPlanList", {
            page: 1,
            perPage: 100,
            oh_document_id: this.payloads.document_type,
            company_id: this.payloads.company_id
          })
          .then(() => {
            this.syncPlan = false;
          });
      }
    },

    async getDocument() {
      this.load = true;
      await this.$store
        .dispatch("document/getDocument", this.bodys)
        .then(() => {
          this.load = false;
        });
    },

    // filter
    filterPage() {
      this.getActual();
      this.getActivity();
    },

    sorting() {
      this.getActual();
      this.getActivity();
    },

    // Pagination
    onPageChange(payload) {
      this.body.perPage = payload.currentPerPage;
      this.body.page = payload.currentPage;
      this.getActual();
    },

    onPageChanges(payload) {
      this.bodyPlan.perPage = payload.currentPerPage;
      this.bodyPlan.page = payload.currentPage;
      this.getPlan();
    },

    onSortChanges(params) {
      let sort1 = params[0].field;
      let sort2 = params[0].type;
      this.body.sort = `${sort1},${sort2}`;
      this.getActual();
    },

    onSortChange(params) {
      let sort1 = params[0].field;
      let sort2 = params[0].type;
      this.bodyPlan.sort = `${sort1},${sort2}`;
      this.getPlan();
    }
  }
};
</script>
