import axiosIns from '@/api/axios'

export const GET_COMPANY_DATA = (args) => {
    return axiosIns.get(`/company/mapping?${args}`)
}

export const ADD_COMPANY = (args) => {
    return axiosIns.post(`/company/mapping`, args)
}

export const DEL_COMPANY = (id) => {
    return axiosIns.delete(`/company/mapping?company_id=${id}`)
}

export const UPDATE_COMPANY  = (args) => {
    return axiosIns.post(`/company/mapping`, args)
}

export const ADD_COMPANY_PERIOD = (args) => {
    // console.log("from args", args);
    return axiosIns.post(`/company-active/create`, args)
}

export const UPDATE_COMPANY_PERIOD = (args) => {
    return axiosIns.put(`/company-active/update?id=${args.id}`, args.body)
}

export const DEL_COMPANY_ACTIVE = (id) => {
    return axiosIns.put(`/company-active/delete?id=${id}`)
}

export const GET_COMPANY_ACTIVE = (args) => {
    return axiosIns.get(`/company-active?${args}`)
}