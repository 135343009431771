<template>
  <div class="row align-items-center justify-content-between">
    <div :class="`cart ${load === true ? '' : 'd-none'}`">
      <div class="card-body text-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <div :class="`card p-0 ${load === true ? 'd-none' : ''}`">
      <div class="card-header">
        <!-- Title -->
        <h4 class="card-header-title">
          {{ title() }} {{ $t("Registration") }}
        </h4>
        <!-- Button -->
        <div style="margin-left: 15px">
          <b-button v-b-modal.scanner :disabled="validationFeature(clinic_features)" @click="onFoc" variant="primary"
            class="ml-2">
            <span class="fe fe-file-plus"></span>
            Scan ID Card
          </b-button>
          <b-button v-b-modal.importFile @click="getLab()" :disabled="validationFeature(clinic_features)"
            variant="primary" class="ml-2" v-if="title() === 'MCU' || company_id == 1">
            <span class="fe fe-file-plus"></span>
            Import MCU
          </b-button>
          <!-- <b-button v-b-modal.modal-3 variant="primary" @click="qrModal()" class="ml-2"
            :disabled="validationFeature(clinic_features)">
            <span class="fe fe-file-plus"></span>
            Scan Clinic QR Code
          </b-button> -->
          <b-button v-b-modal.modal-search @click="manualInputs()" variant="primary"
            :disabled="validationFeature(clinic_features)" class="ml-2">
            <span class="fe fe-file-plus"></span>
            {{ $t("Manual Input") }}
          </b-button>
          <!-- @click="exporting" -->
          <b-button @click="exporting()" variant="primary" class="ml-2">
            <span class="fe fe-download"></span>
            {{ $t("Export") }}
          </b-button>
          <!-- <b-button v-b-modal.modal-export variant="primary" class="ml-2">
            <span class="fe fe-download"></span>
            {{ $t("Export") }}
          </b-button> -->
        </div>
        <div style="margin-right: 15px"></div>
      </div>
      <!-- <b-collapse id="collapse-1"> -->
      <form @submit.prevent="filterPage()">
        <div class="row mt-4" style="margin-left: 15px; margin-right: 15px">
          <!-- <div class="col" v-if="filterCompany == 1">
            <div class="form-group">
              <label for="exampleInputEmail1" class="form-label">Company</label>
              <input type="text" class="form-control" v-model="body.company" />
            </div>
          </div> -->

          <!-- Select -->
          <div class="col-4" v-if="filterCompany == 1">
            <div class="form-group">
              <label>{{ $t("Company") }}</label>
              <v-select :options="selectCompanyOption()" v-model="body.company_id" label="company_name"
                :reduce="(e) => e.id" />
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <label for="exampleInputEmail1" class="form-label">{{
      $t("Employee Name")
    }}</label>
              <input type="text" class="form-control" v-model="body.employee_name" />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>Date from</label>
              <input type="date" class="form-control" placeholder="Pilih Tanggal" v-model="body.start_date"
                name="Pilih Tanggal" />
              <!-- <v-select v-model="body.order" :options="['ASC', 'DESC']" @input="sorting" /> -->
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>Date to</label>
              <input type="date" class="form-control" placeholder="Pilih Tanggal" v-model="body.end_date"
                name="Pilih Tanggal" />
              <!-- <v-select v-model="body.order" :options="['ASC', 'DESC']" @input="sorting" /> -->
            </div>
          </div>
        </div>

        <div class="row mb-3" style="margin-right: 15px">
          <div class="col"></div>
          <div class="col-auto">
            <!-- Button -->
            <button type="submit" class="btn btn-danger lift">
              {{ $t("Filter") }}
            </button>
          </div>
        </div>
        <!-- / .row -->
      </form>
      <!-- </b-collapse> -->
      <vue-good-table :line-numbers="true" :columns="columns" :rows="dataGet.data" :total-rows="dataGet.totalItems" :sort-options="{
      enabled: true
    }" :pagination-options="{
      enabled: true,
      mode: 'pages',
      position: 'bottom',
      perPage: body.limit,
      perPageDropdown: [10, 50, 100],
      dropdownAllowAll: false,
      setCurrentPage: body.page,
      nextLabel: 'next',
      prevLabel: 'prev',
      rowsPerPageLabel: 'Rows per page',
      ofLabel: 'of',
      pageLabel: 'page',
      allLabel: 'All'
    }" style-class="vgt-table border-0 table" @on-page-change="onPageChange" @on-per-page-change="onPageChange">
        <!-- Loading -->
        <div slot="emptystate" class="text-center font-size-14">
          No data available
        </div>
        <!-- Custom Rows -->
        <template slot="table-column" slot-scope="props">
          <!-- Table Action -->
          <span v-if="props.column.label == 'NAMA KARYAWAN'">
            {{ $t("Employee Name") }}
          </span>
          <span v-if="props.column.label == 'PERUSAHAAN'">
            {{ $t("Company") }}
          </span>
          <span v-if="props.column.label == 'DEPARTEMEN'">
            {{ $t("Department") }}
          </span>
          <span v-if="props.column.label == 'STATUS'"> Status </span>
          <span v-if="props.column.label == 'JENIS KUNJUNGAN'">
            {{ $t("TYPE VISIT") }}
          </span>
          <span v-if="props.column.label == 'WAKTU REGIS'">
            {{ $t("Registration Time") }}
          </span>
          <span v-if="props.column.label == 'DIBUAT OLEH'">
            {{ $t("CREATED BY") }}
          </span>
          <span v-if="props.column.label == 'ACTION'">
            {{ $t("Action") }}
          </span>
        </template>
        <template slot="table-row" slot-scope="props">
          <!-- Table Action -->
          <span v-if="props.column.field == 'action'">
            <div v-if="validationFeatureTable(clinic_features)">
              <b-button class="no-wrap btn-pill" variant="success" size="sm" @click.prevent="detailPatient(props.row)"
                v-if="props.row.status == 'DONE' && title() === 'Clinic'">
                <span class="fe fe-activity"></span>
              </b-button>
              <b-button class="no-wrap btn-pill" variant="success" size="sm"
                @click.prevent="detailPatientMCU(props.row)" v-if="props.row.status == 'DONE' && title() === 'MCU'">
                <span class="fe fe-activity"></span>
              </b-button>
              <b-button class="no-wrap btn-pill" variant="info" size="sm" @click.prevent="diagnoseData(props.row)"
                v-if="props.row.status == 'REGISTERED'">
                <span class="fe fe-activity"></span>
              </b-button>
              <b-button class="no-wrap btn-pill ml-2" variant="secondary" size="sm"
                @click.prevent="seeHistory(props.row)" v-if="props.row.status == 'REGISTERED' && title() === 'Clinic'">
                <span class="fe fe-trending-up"></span>
              </b-button>
              <b-button class="no-wrap ml-2" variant="danger" style="margin-left: 5px" size="sm"
                @click="deleteData(props.row.register_id)" v-if="props.row.status == 'REGISTERED'">
                <span class="fe fe-trash"></span>
              </b-button>
            </div>
            <!-- <div v-if="clinic_features[0].clinic_feature_id === '2'">

            </div> -->
          </span>

          <span v-else-if="props.column.field == 'register_date'">
            {{ fixTime(props.row.register_date) }}
          </span>
        </template>
      </vue-good-table>
    </div>

    <!-- Modal -->
    <b-modal id="modal-export" title="Export Data" hide-footer>
      <div class="form-group">
        <label for="exampleInputEmail1" class="form-label">Type</label>
        <v-select :options="['Filtered', 'All List']" v-model="bodyExport.type" />
      </div>
      <!-- <div
        class="form-group"
        v-if="bodyExport.type.length > 0 && bodyExport.type === 'Monthly'"
      > -->
      <!-- <label for="exampleInputEmail1" class="form-label">{{
          $t("Month")
        }}</label>
        <input
          type="month"
          class="form-control"
          v-model="bodyExport.date"
          :max="now"
        /> -->
      <!-- </div> -->
      <!-- <div
        class="form-group"
        v-if="bodyExport.type.length > 0 && bodyExport.type === 'Yearly'"
      >
        <label for="exampleInputEmail1" class="form-label">{{
          $t("Year")
        }}</label>
        <datepicker
          input-class="form-control"
          format="yyyy"
          minimum-view="year"
          v-model="bodyExport.date"
        >
        </datepicker>
      </div> -->
      <b-button class="float-end" variant="primary" @click="exporting()"><span class="fe fe-download"></span> Download
      </b-button>
      <!-- <b-button class="float-end mr-2" variant="primary" @click="resetForm"
        ><span class="fe fe-refresh-ccw"></span> Reset Form</b-button
      > -->
    </b-modal>

    <b-modal id="modal-1" size="xl" hide-footer title="Diagnosis Doctor Form" @hide="onHide">
      <b-tabs content-class="">
        <b-tab title="Manual Input" active v-if="type_visit === 'MCU'">
          <form>
            <button class="mt-2 btn btn-primary" @click.prevent="newRecord">
              New Record
            </button>
            <div class="card mt-3" v-for="(item, index) in mcuData.mcu_record" :key="index">
              <div class="card-header">
                <!-- Title -->
                <h4 class="card-header-title">Record #{{ index + 1 }}</h4>

                <!-- Button -->
                <button class="btn btn-primary" @click.prevent="removeRecord(index)">
                  <span class="fe fe-x"></span>
                </button>
              </div>
              <div class="card-body">
                <div class="row g-3">
                  <div class="form-group col">
                    <label>Category</label>
                    <span style="color: red">*</span>
                    <v-select v-model="item.category_name" :reduce="(option) => option.category_name"
                      :options="categoryTest.data" label="category_name" v-validate="'required'" name="Category"
                      @input="categoryChange(item.category_name, index)" />
                    <span class="text-sm text-danger" v-show="errors.has('Category')">{{ errors.first("Category")
                      }}</span>
                  </div>
                  <div class="form-group col">
                    <label>Group</label>
                    <span style="color: red">*</span>
                    <v-select v-model="item.group_name" :reduce="(option) => option.group_name"
                      :options="item.optionGroup" label="group_name" v-validate="'required'" name="Group"
                      @input="groupChange(item.group_name, index)" />
                    <span class="text-sm text-danger" v-show="errors.has('Group')">{{ errors.first("Group") }}</span>
                  </div>
                </div>
                <div class="row g-3">
                  <div class="form-group col">
                    <label>Title</label>
                    <span style="color: red">*</span>
                    <v-select v-model="item.check_title" :reduce="(option) => option.item_name"
                      :options="item.optionTitle" label="item_name" v-validate="'required'" name="Title" />
                    <span class="text-sm text-danger" v-show="errors.has('Title')">{{ errors.first("Title") }}</span>
                  </div>
                  <div class="form-group col">
                    <label>Value</label>
                    <span style="color: red">*</span>
                    <input type="text" class="form-control" placeholder="Value" v-model="item.check_value" name="Value"
                      v-validate="'required'" />
                    <span class="text-sm text-danger" v-show="errors.has('Value')">{{ errors.first("Value") }}</span>
                  </div>
                </div>
              </div>
            </div>
            <button style="margin-left: auto; float: right" class="btn btn-primary mt-4" type="submit"
              @click.prevent="saveRecord()">
              Save
            </button>
          </form>
        </b-tab>
        <div v-if="type_visit === 'Rawat Jalan'">
          <form>
            <b-tabs v-model="currentstep">
              <b-tab title="Initial Check" v-if="purpose_visit === 1">
                <div v-if="!isLoading" class="row g-3 mt-2">
                  <div class="form-group col">
                    <label>Weight</label>
                    <span style="color: red">*</span>
                    <div class="input-group mb-3">
                      <input type="text" class="form-control" placeholder="Weight" v-model="diagnose.weight"
                        :disabled="statuss == 'DONE' ? true : false" name="Weight" v-validate="'required|numeric'" />
                      <span class="input-group-text">CM</span>
                    </div>
                    <span class="text-sm text-danger" v-show="errors.has('Weight')">{{ errors.first("Weight") }}</span>
                  </div>
                  <div class="form-group col">
                    <label>Height</label>
                    <span style="color: red">*</span>

                    <div class="input-group mb-3">
                      <input type="text" class="form-control" placeholder="Height" v-model="diagnose.height"
                        :disabled="statuss == 'DONE' ? true : false" name="Height" v-validate="'required|numeric'" />
                      <span class="input-group-text">CM</span>
                    </div>
                    <span class="text-sm text-danger" v-show="errors.has('Height')">{{ errors.first("Height") }}</span>
                  </div>
                  <div class="form-group col">
                    <label>Body Mass Index (BMI)</label>
                    <span style="color: red">*</span>
                    <input type="text" class="form-control" placeholder="BMI" v-model="diagnose.bmi" disabled />
                  </div>
                </div>

                <div class="row g-3" v-if="!isLoading">
                  <div class="form-group col">
                    <label>Blood Pressure</label>
                    <span style="color: red">*</span>
                    <input type="text" class="form-control" placeholder="Blood Pressure"
                      v-model="diagnose.blood_pressure" name="Blood Pressure" v-validate="'required|max:24'"
                      :disabled="statuss == 'DONE' ? true : false" />
                    <span class="text-sm text-danger"
                      v-show="errors.has('Blood Pressure')">{{ errors.first("Blood Pressure") }}</span>
                  </div>
                  <div class="form-group col">
                    <label>Allergy</label>
                    <input type="text" class="form-control" placeholder="Allergy" v-model="diagnose.allergy"
                      v-validate="'max:104'" name="Allergy" :disabled="statuss == 'DONE' ? true : false" />
                    <span class="text-sm text-danger"
                      v-show="errors.has('Allergy')">{{ errors.first("Allergy") }}</span>
                  </div>
                </div>
                <div class="row g-3" v-if="!isLoading">
                  <div class="form-group col">
                    <label>Complaint</label>
                    <span style="color: red">*</span>
                    <textarea type="text" class="form-control" placeholder="Complaint" v-model="diagnose.init_complaint"
                      name="Complaint" v-validate="'required'" :disabled="statuss == 'DONE' ? true : false" />
                    <span class="text-sm text-danger" v-show="errors.has('Complaint')">{{ errors.first("Complaint")
                      }}</span>
                  </div>
                </div>
                <div v-else class="text-center mt-6">
                  <div class="spinner-border text-primary" role="status">
                    <!-- <span class="sr-only">Loading...</span> -->
                  </div>
                  <!-- <p>Loading...</p> -->
                </div>
              </b-tab>
              <b-tab title="Doctor's Diagnosis">
                <label for=""></label>
                <div class="row g-3"></div>
                <div class="row g-3 mt-2">
                  <div class="form-group col">
                    <label>{{ $t("Doctor") }}</label>
                    <span style="color: red">*</span>
                    <v-select v-if="purpose_visit === 1" v-model="diagnose.doctor" v-validate="'required'" name="Doctor"
                      :reduce="(option) => option.fullname" :options="doctorList" label="fullname" />
                    <input v-if="purpose_visit === 2" type="text" v-validate="'required'" class="form-control"
                      placeholder="Doctor" v-model="diagnose.doctor" />
                    <span class="text-sm text-danger" v-show="errors.has('Doctor')">{{ errors.first("Doctor") }}</span>
                  </div>
                </div>
                <div class="row g-3 mt-2">
                  <div class="form-group col">
                    <label>PE & Vital Sign & Lab </label>
                    <span style="color: red">*</span>
                    <b-form-textarea id="textarea-large" size="lg" placeholder="Masukkan PE & Vital Sign & LAB"
                      v-validate="'required'" name="PE & Vital Sign & LAB" v-model="diagnose.vital_sign" :disabled="statuss == 'DONE' ? true : false
      "></b-form-textarea>
                    <span class="text-sm text-danger" v-show="errors.has('PE & Vital Sign & LAB')">{{ errors.first("PE & Vital Sign & LAB") }}</span>
                  </div>
                </div>
                <div class="row g-3 mt-2" v-if="purpose_visit === 1">
                  <div class="form-group col">
                    <label>{{ $t("Doctor Action") }}</label>
                    <v-select v-model="diagnose.doctor_action" :reduce="(option) => option.treatment_name"
                      :options="treatment.data" label="treatment_name" />
                  </div>
                </div>
                <button v-b-modal.addDisease @click="resetAddDisease()" type="button"
                  :disabled="statuss == 'DONE' ? true : false" class="btn btn-primary mb-3">
                  <span class="fe fe-plus"></span> Add Disease
                </button>
                <div class="row g-3 mt-2">
                  <div class="form-group col">
                    <label>Disease</label>
                    <b-list-group v-if="diagnose.disease.length">
                      <b-list-group-item class="d-flex justify-content-between align-items-center"
                        v-for="(item, index) in diagnose.disease" :key="`d${index}`">
                        {{ item.chapter }} / {{ item.disease_name }}
                        <button class="btn btn-white btn-sm" @click.prevent="removeDisease(index)"
                          :disabled="statuss == 'DONE' ? true : false" v-if="purpose_visit === 1">
                          <span class="fe fe-x"></span>
                        </button>
                      </b-list-group-item>
                    </b-list-group>
                    <b-list-group v-else>
                      <b-list-group-item class="d-flex justify-content-between align-items-center">
                        No Disease Select
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                </div>
                <!-- <div
                  class="row g-3"
                  v-for="(item, index) in diagnose.disease"
                  :key="`d${index}`"
                >
                  <div class="form-group col-8">
                    <div v-if="item.disease_id > 0">
                      {{ item.chapter }} - {{ item.block }} -
                      {{ item.category }} - {{ item.disease_name }}
                    </div>
                  </div>
                  <div class="form-group col-4" v-if="purpose_visit === 1">
                    <button
                      type="button"
                      style="margin-left: auto"
                      :class="`btn btn-primary ${index < 1 ? 'mt-4' : ''}`"
                      @click="removeDisease(index)"
                      :disabled="statuss == 'DONE' ? true : false"
                    >
                      <span>X</span>
                    </button>
                  </div>
                  <div
                    class="form-group col-4"
                    v-if="purpose_visit === 2"
                  >
                    <button
                      type="button"
                      :class="`btn btn-primary ${index < 0 ? 'mt-4' : ''}`"
                      @click="removeDisease(index)"
                      :disabled="statuss == 'DONE' ? true : false"
                    >
                      <span>X</span>
                    </button>
                  </div>
                </div> -->
                <div class="row g-3 mt-2">
                  <div class="form-group col">
                    <label>Sick Leave</label>
                    <v-select v-model="diagnose.sick_leave" :reduce="(option) => option.id"
                      :disabled="statuss == 'DONE' ? true : false" :clearable="false" :options="[
      { label: 'NO', id: 0 },
      { label: 'YES', id: 1 }
    ]" />
                  </div>
                  <div class="form-group col" v-if="diagnose.sick_leave === 1">
                    <label>Leave Sick Day</label>
                    <b-input-group append="Day" style="padding: 0 !important">
                      <b-form-input type="number" min="1" v-model="diagnose.sick_leave_day"></b-form-input>
                    </b-input-group>
                  </div>
                  <div class="form-group col" v-if="diagnose.sick_leave === 1">
                    <label>{{ $t("Start Date") }}</label>
                    <input type="date" class="form-control" placeholder="Pilih Tanggal" v-model="diagnose.star_date" />
                  </div>
                </div>
                <div class="row g-3 mt-3">
                  <div class="form-group col">
                    <label>SPELL</label>
                    <span style="color: red">*</span>
                    <v-select v-model="diagnose.spell" :clearable="false" :disabled="spellCondition(statuss, diagnose.sick_leave)"
                      :reduce="(option) => option.id" :options="[
      { label: 'YES', id: 1 },
      { label: 'NO', id: 2 }
    ]" v-validate="'required'" name="SPELL" />
                    <span class="text-sm text-danger" v-show="errors.has('SPELL')">{{ errors.first("SPELL") }}</span>
                  </div>
                  <div class="form-group col">
                    <label>PAK</label>
                    <span style="color: red">*</span>
                    <v-select v-model="diagnose.pak" :clearable="false" :disabled="statuss == 'DONE' ? true : false"
                      :reduce="(option) => option.id" :options="[
      { label: 'YES', id: 1 },
      { label: 'NO', id: 2 }
    ]" v-validate="'required'" name="PAK" />
                    <span class="text-sm text-danger" v-show="errors.has('PAK')">{{ errors.first("PAK") }}</span>
                  </div>
                </div>
                <div class="row g-3">
                  <div class="form-group col">
                    <label>Doctor Note</label>
                    <textarea type="text" class="form-control" placeholder="Doctor Note"
                      v-model="diagnose.doctor_note" />
                  </div>
                  <div class="form-group col" v-if="purpose_visit === 2">
                    <label>{{ $t("Upload Doctor Letter")
                      }}<span style="color: red">*</span></label>
                    <input type="file" class="form-control" v-validate="'required'" ref="fileUpload"
                      name="Doctor Letter" @change="fileUploaderDoctor" />
                    <span class="text-sm text-danger" v-show="errors.has('Doctor Letter')">{{ errors.first("Doctor Letter")
    }}</span>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Disposable Item" v-if="purpose_visit === 1">
                <div class="row g-3 mt-2">
                  <div class="col">
                    <button type="button" :disabled="statuss == 'DONE' ? true : false" class="btn btn-primary mb-3"
                      @click="addDrugsDisposable">
                      <span class="fe fe-plus"></span> {{ $t("Add Item") }}
                    </button>
                  </div>
                </div>
                <!-- {{ diagnose }} -->
                <div class="row g-3" v-for="(item, index) in diagnose.disposable" :key="index">
                  <div class="form-group col-3">
                    <label v-if="index < 1">{{ $t("Item Name") }}</label>
                    <span v-if="index < 1" style="color: red">*</span>
                    <v-select v-model="item.item_id" :reduce="(e) => e.id" :options="disposableItemOption"
                      :loading="loadExp" label="item_generic_name" :disabled="statuss == 'DONE' ? true : false"
                      @input="productUnit(item.item_id, index)" :name="`Item Name -${index}`" v-validate="'required'" />
                    <span class="text-sm text-danger" v-show="errors.has(`Item Name -${index}`)">{{ "Item Name is Required" }}</span>
                  </div>
                  <div class="form-group col-3">
                    <label v-if="index < 1">{{ $t("Quantity") }}</label>
                    <span v-if="index < 1" style="color: red">*</span>
                    <b-input-group>
                      <b-form-input type="number" class="form-control" :disabled="statuss == 'DONE' ? true : false"
                        placeholder="Masukkan Quantity" v-model="item.qty" min="0" @input="
      checkQtyDisposable(item.item_id, item.qty, index)
      " :name="`Quantity -${index}`" v-validate="'required'" />
                    </b-input-group>
                    <span class="text-sm text-danger" v-show="errors.has(`Quantity -${index}`)">{{ "Quantity is Required" }}</span>
                  </div>
                  <!-- <div class="form-group col-2">
                    <label v-if="index < 1">{{ $t("Expired Date") }}</label>
                    <span v-if="index < 1" style="color: red">*</span>
                    <div v-if="item.disposableExpireDate.length">
                      <v-select
                        v-model="item.expired_date"
                        :options="item.disposableExpireDate"
                        :clearable="false"
                        :name="`Expired Date -${index}`"
                        v-validate="'required'" />
                      <span
                        class="text-sm text-danger"
                        v-show="errors.has(`Expired Date -${index}`)"
                        >{{ "Expired Date is Required" }}</span
                      >
                    </div>
                  </div> -->
                  <div class="form-group col-3">
                    <label v-if="index < 1">{{ $t("Unit") }}</label>
                    <span v-if="index < 1" style="color: red">*</span>
                    <v-select v-model="item.item_unit_id" :reduce="(e) => e.item_uom_id" :options="item.uom"
                      :disabled="checkVal(item.item_id)" label="item_uom_name" :name="`Unit -${index}`"
                      v-validate="'required'" />
                    <span class="text-sm text-danger" v-show="errors.has(`Unit -${index}`)">{{ "Unit is Required"
                      }}</span>
                  </div>
                  <div class="form-group col-1" v-if="index >= 0">
                    <button type="button" style="margin-left: auto"
                      :class="`btn btn-primary ${index < 1 ? 'mt-4' : ''}`" @click="removeDrugsDisposable(index)"
                      :disabled="statuss == 'DONE' ? true : false">
                      <span>X</span>
                    </button>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Prescription">
                <!-- {{ diagnose.drugs }} -->
                <div class="row g-3 mt-2" v-if="purpose_visit === 2">
                  <div class="form-group col">
                    <label>{{ $t("Upload Receipt Letter") }}</label>
                    <input type="file" class="form-control" ref="fileUpload" @change="fileUploaderReceipt" />
                    <!-- <span
                      class="text-sm text-danger"
                      v-show="errors.has('file')"
                      >{{ errors.first("file") }}</span
                    > -->
                  </div>
                </div>
                <div v-if="purpose_visit === 1">
                  <div class="row g-3 mt-2">
                    <div class="form-group">
                      <b-form-checkbox v-model="diagnose.non_medisin" class="mb-3" value="0">Non
                        Medicine <span class="fe fe-info" v-b-popover.hover.top="'Check if dont use any medicine'"></span></b-form-checkbox>
                    </div>
                  </div>
                  <div v-if="diagnose.non_medisin == ''">
                    <div class="row g-3">
                      <div class="col">
                        <button type="button" :disabled="statuss == 'DONE' ? true : false" class="btn btn-primary mb-3"
                          @click="addDrugs">
                          <span class="fe fe-plus"></span> {{ $t("Add Drugs") }}
                        </button>
                      </div>
                    </div>
                    <div class="card" v-for="(item, index) in diagnose.drugs" :key="index + 'A'">
                      <div class="card-header">
                        <!-- Title -->
                        <h4 class="card-header-title">
                          Drugs #{{ index + 1 }}
                        </h4>

                        <!-- Link -->
                        <a class="small" href="#!" @click="removeDrugs(index)"
                          :disabled="statuss == 'DONE' ? true : false">
                          Remove
                        </a>
                      </div>
                      <div class="card-body">
                        <!-- List group -->
                        <div class="list-group list-group-flush my-n3">
                          <div class="row">
                            <div class="col-lg-3">
                              <label>{{ $t("Drug Name") }}</label>
                              <span style="color: red">*</span>
                              <v-select v-model="item.drugs_id" :reduce="(e) => e.id" :options="drugOption"
                                label="label" :disabled="statuss == 'DONE' ? true : false"
                                @input="receiptUnit(item.drugs_id, index)" :name="`Drug Name -${index}`"
                                v-validate="'required'" />
                              <span class="text-sm text-danger" v-show="errors.has(`Drug Name -${index}`)">{{ "Drug Name is Required" }}</span>
                            </div>
                            <div class="col-lg-3">
                              <label>{{ $t("Quantity") }}</label>
                              <span style="color: red">*</span>
                              <b-input-group>
                                <b-form-input type="number" class="form-control"
                                  :disabled="statuss == 'DONE' ? true : false" placeholder="" v-model="item.qty" @input="
                                    receiptQTY(
                                      item.qty,
                                      item.drugs_id,
                                      item.drugs_unit_id
                                    )
                                    " min="0" :name="`Quantity -${index}`" v-validate="'required'" />
                              </b-input-group>
                              <span class="text-sm text-danger" v-show="errors.has(`Quantity -${index}`)">{{ "Quantity is Required" }}</span>
                            </div>
                            <div class="col-lg-3">
                              <label>{{ $t("Unit") }}</label>
                              <span style="color: red">*</span>
                              <v-select v-model="item.drugs_unit_id" :reduce="(e) => e.item_uom_id"
                                :options="item.uom" :disabled="checkVal(item.drugs_id)" label="item_uom_name"
                                :name="`Unit -${index}`" v-validate="'required'" />
                              <span class="text-sm text-danger" v-show="errors.has(`Unit -${index}`)">{{ "Unit is Required"
                                }}</span>
                            </div>
                            <div class="col-lg-3">
                              <label>{{ $t("Expired Date") }}</label>
                              <span style="color: red">*</span>
                              <v-select v-model="item.expired_date" :options="item.drugExpireDate" :clearable="false"
                                :name="`Expired Date -${index}`" v-validate="'required'" />
                              <span class="text-sm text-danger" v-show="errors.has(`Expired Date -${index}`)">{{"Expired Date is Required" }}</span>
                            </div>
                            <div class="col-lg-4 mt-2">
                              <label>{{ $t("Dose") }}</label>
                              <span style="color: red">*</span>
                              <v-select :options="dose.data" label="dose" v-model="item.changeDose"
                                @input="changesDoses($event, index)" :name="`Dose -${index}`" v-validate="'required'" />
                              <b-form-input v-if="otherDose == true" type="text" class="form-control"
                                v-model="item.dose" :name="`Dose -${index}`" v-validate="'required'"></b-form-input>
                              <span class="text-sm text-danger" v-show="errors.has(`Dose -${index}`)">{{ "Dose is Required" }}</span>
                            </div>
                            <div class="col-lg-4 mt-2">
                              <label>{{ $t("How to use") }}</label>
                              <span style="color: red">*</span>
                              <v-select :options="howtouse.data" label="how_to_use" v-model="item.changeRule"
                                @input="changesRules($event, index)" :name="`How to Use -${index}`"
                                v-validate="'required'" />
                              <b-form-input v-if="otherRule == true" type="text" class="form-control"
                                v-model="item.how_use" :name="`How to Use -${index}`"
                                v-validate="'required'"></b-form-input>
                              <span class="text-sm text-danger" v-show="errors.has(`How to Use -${index}`)">{{ "How to Use is Required" }}</span>
                            </div>
                            <div class="col-lg-4">
                              <div class="form-check mt-5">
                                <input class="form-check-input" v-model="item.is_external" type="checkbox"
                                  id="inlineCheckbox1" true-value="true" false-value="false" />
                                <label class="form-check-label">{{
      $t("External Clinic")
    }}</label>
                              </div>
                            </div>
                          </div>
                          <vue-good-table v-if="item.drugs_id != 0" class="mt-3" mode="remote" :line-numbers="true"
                            :columns="columnsDrugs" :rows="item.dataAddDrugs" :sort-options="{
      enabled: false
    }">
                            <div slot="emptystate" class="text-center font-size-14">
                              No data available
                            </div>
                          </vue-good-table>
                        </div>
                      </div>
                    </div>
                    <!-- <div
                      class="row g-3"
                      v-for="(item, index) in diagnose.drugs"
                      :key="index + 'A'"
                    >
                      <div class="form-group col-3">
                        <label>{{ $t("Drug Name") }}</label>
                        <span style="color: red">*</span>
                        <v-select
                          v-model="item.drugs_id"
                          :reduce="(e) => e.id"
                          :options="drugOption"
                          label="label"
                          :disabled="statuss == 'DONE' ? true : false"
                          @input="receiptUnit(item.drugs_id, index)"
                        />
                      </div>
                      <div class="form-group col-1">
                        <label>{{ $t("Quantity") }}</label>
                        <span style="color: red">*</span>
                        <b-input-group>
                          <b-form-input
                            type="number"
                            class="form-control"
                            :disabled="statuss == 'DONE' ? true : false"
                            placeholder="Masukkan Quantity"
                            v-model="item.qty"
                            @input="receiptQTY(item.qty, item.drugs_id, item.drugs_unit_id)"
                            min="0"
                          ></b-form-input>
                        </b-input-group>
                      </div>
                      <div class="form-group col-1">
                        <label>{{ $t("Unit") }}</label>
                        <span style="color: red">*</span>
                        <v-select
                          v-model="item.drugs_unit_id"
                          :reduce="(e) => e.item_uom_id"
                          :options="unitReceipt.uom"
                          :disabled="checkVal(item.drugs_id)"
                          label="item_uom_name"
                        />
                      </div>
                      <div class="form-group col-2">
                        <label>{{ $t("Expired Date") }}</label>
                        <span style="color: red">*</span>
                        <v-select
                          v-model="item.expired_date"
                          :options="item.drugExpireDate"
                          :clearable="false"
                        ></v-select>
                      </div>
                      <div class="form-group col-3">
                        <label>{{ $t("How to use") }}</label>
                        <span style="color: red">*</span>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="How To Use"
                          v-model="item.how_use"
                        />
                      </div>
                      <div class="form-group col-1">
                        <label></label>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            v-model="item.is_external"
                            type="checkbox"
                            id="inlineCheckbox1"
                            true-value="true"
                            false-value="false"
                          />
                          <label
                            class="form-check-label"
                            for="inlineCheckbox1"
                            >{{ $t("External Clinic") }}</label
                          >
                        </div>
                      </div>
                      <div class="form-group col-1" v-if="index >= 0">
                        <button
                          type="button"
                          style="margin-left: auto"
                          :class="`btn btn-primary ${index < 1 ? 'mt-4' : ''}`"
                          @click="removeDrugs(index)"
                          :disabled="statuss == 'DONE' ? true : false"
                        >
                          <span>X</span>
                        </button>
                      </div>

                      <vue-good-table
                        v-if="item.drugs_id != 0"
                        class="mb-6"
                        mode="remote"
                        :line-numbers="true"
                        :columns="columnsDrugs"
                        :rows="item.dataAddDrugs"
                        :sort-options="{
                          enabled: true,
                        }"
                      >
                        <div slot="emptystate" class="text-center font-size-14">
                          No data available
                        </div>
                      </vue-good-table>
                    </div> -->
                  </div>
                </div>
              </b-tab>
              <b-tab title="Follow Up">
                <div class="row g-3 mt-2" v-if="purpose_visit === 1">
                  <div class="form-group col">
                    <label>{{ $t("Follow Up Treatment") }}</label>
                    <!-- <span style="color: red">*</span> -->
                    <v-select v-model="diagnose.follow_up" :reduce="(option) => option.id"
                      :disabled="statuss == 'DONE' ? true : false" :options="[
      { label: 'NO', id: 1 },
      { label: 'Control', id: 2 },
      { label: 'Treat Referrals', id: 3 }
    ]" />
                  </div>
                  <div class="form-group col" v-if="diagnose.follow_up === 2">
                    <label>{{ $t("Control Visit Date") }}</label>
                    <input type="date" class="form-control" :disabled="statuss == 'DONE' ? true : false"
                      placeholder="Pilih Tanggal" v-model="diagnose.next_visit" />
                  </div>
                </div>
                <div class="row g-3 mt-2" v-if="purpose_visit === 2">
                  <div class="form-group col">
                    <label>{{ $t("Upload Sick Letter") }}</label>
                    <input type="file" class="form-control" ref="fileUpload" @change="fileUploaderLicense" />
                    <!-- <span
                      class="text-sm text-danger"
                      v-show="errors.has('file')"
                      >{{ errors.first("file") }}</span
                    > -->
                  </div>
                </div>
              </b-tab>
            </b-tabs>
            <div v-if="purpose_visit === 1">
              <button style="margin-left: auto; float: right" class="btn btn-primary mt-4 ml-2" type="button"
                id="puposevisit1Next" :disabled="statuss == 'DONE' ? true : false" v-if="currentstep != 4"
                @click="currentstep++">
                Next
              </button>
              <div v-if="currentstep == 4 && isLoading == false">
                <button style="margin-left: auto; float: right" class="btn btn-primary mt-4 ml-2" type="submit"
                  :disabled="statuss == 'DONE' ? true : false" @click.prevent="diagnosePatient()">
                  Save
                </button>
              </div>
              <button style="margin-left: auto; float: right" class="btn btn-outline-primary mt-4" type="button"
                :disabled="statuss == 'DONE' ? true : false" v-if="currentstep != 0 && isLoading == false"
                @click="currentstep--">
                Back
              </button>
              <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="button" disabled
                v-if="currentstep == 4 && isLoading == true">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </button>
            </div>

            <div v-if="purpose_visit === 2">
              <button style="margin-left: auto; float: right" class="btn btn-primary mt-4 ml-2" type="button"
                id="puposevisit2Next" :disabled="statuss == 'DONE' ? true : false" v-if="currentstep != 2"
                @click="currentstep++">
                Next
              </button>
              <div v-if="currentstep == 2 && isLoading == false">
                <button style="margin-left: auto; float: right" class="btn btn-primary mt-4 ml-2" type="submit"
                  :disabled="statuss == 'DONE' ? true : false" @click.prevent="diagnosePatientVisit2()">
                  Save
                </button>
              </div>
              <button style="margin-left: auto; float: right" class="btn btn-outline-primary mt-4" type="button"
                :disabled="statuss == 'DONE' ? true : false" v-if="currentstep != 0 && isLoading == false"
                @click="currentstep--">
                Back
              </button>
              <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="button" disabled
                v-if="currentstep != 0 && isLoading == true">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </button>
            </div>
          </form>
        </div>
      </b-tabs>
    </b-modal>

    <b-modal id="addDisease" hide-footer title="Add Disease" scrollable centered @hide="onHide">
      <div class="form-group">
        <label>Search</label>
        <b-input-group>
          <b-form-input type="text" class="form-control" v-model="bodyDiagnoseDisiase.disease_name"
            @keydown="searchDiagnose"></b-form-input>
        </b-input-group>
      </div>
      <div class="d-flex">
        <button class="btn btn-white btn-sm" v-if="selected.position > 0 && isSearch == false" @click="backspace()">
          <span class="fe fe-arrow-left"></span>
        </button>
        <!-- <h2 class="mb-2 mt-2 ml-3">{{ selectedPos() }}</h2> -->
      </div>
      <b-overlay :show="loadingDiagnose" rounded="sm">
        <b-list-group class="mt-3" v-if="isSearch == true">
          <b-list-group-item button v-for="(chapter, index) in diagonseDisease" :key="index" @click="wokwok(chapter)">
            <template v-if="chapter.chapter_name">
              <small class="font-weight-bold">Chapter</small>
              <p class="mb-0">{{ chapter.chapter_name }}</p>
            </template>

            <!-- <template v-if="chapter.block_name">
              <small class="font-weight-bold">Block</small>
              <p class="mb-0">{{ chapter.block_name }}</p>
            </template>

            <template v-if="chapter.category_name">
              <small class="font-weight-bold">Category</small>
              <p class="mb-0">{{ chapter.category_name }}</p>
            </template> -->

            <template v-if="chapter.disease_name">
              <small class="font-weight-bold">Disease</small>
              <p class="mb-0">{{ chapter.disease_name }}</p>
            </template>
          </b-list-group-item>
        </b-list-group>
      </b-overlay>
      <!-- <div class="d-flex justify-content-center mt-3">
        <button
          v-if="infoDiagnose.currentPage < infoDiagnose.totalPages"
          class="btn btn-primary"
          type="submit"
          @click="showMore()">
          Show More
        </button>
      </div> -->
    </b-modal>

    <!-- Modal manual input -->
    <b-modal id="modal-2" size="lg" hide-footer title="Patient Registration Form" @hide="onHide"
      v-if="isManualInput === true">
      <form v-if="recs === 'Clinic'">
        <label for="">
          <h3>{{ $t("Patient Info") }}</h3>
        </label>
        <div class="row g-3">
          <div class="form-group col">
            <label>Name</label>
            <input type="text" class="form-control" placeholder="Name" v-model="dataPatient.fullname" disabled />
          </div>
          <div class="form-group col">
            <label>{{ $t("Company") }}</label>
            <input type="text" class="form-control" placeholder="Company" v-model="dataPatient.company" disabled />
          </div>
        </div>
        <div class="row g-3">
          <div class="form-group col">
            <label>Gender</label>
            <input type="text" class="form-control" placeholder="Gender" v-model="dataPatient.gender" disabled />
          </div>
          <div class="form-group col">
            <label>Date of Birth</label>
            <input type="text" class="form-control" placeholder="Date of Birth" v-model="dataPatient.date_of_birth"
              disabled />
          </div>
        </div>
        <div class="row g-3">
          <div class="form-group col">
            <label>NIK</label>
            <input type="text" class="form-control" placeholder="NIK" v-model="dataPatient.nik" disabled />
          </div>
          <div class="form-group col">
            <label>Blood Type</label>
            <v-select :options="['A', 'AB', 'B', 'O']" v-model="dataPatient.blood_type" :clearable='false'/>
          </div>
        </div>
        <div class="row g-3">
          <div class="form-group col">
            <label>Position</label>
            <input type="text" class="form-control" placeholder="Position" v-model="dataPatient.position" disabled />
          </div>
          <div class="form-group col">
            <label>Department</label>
            <input type="text" class="form-control" placeholder="Department" v-model="dataPatient.department"
              disabled />
          </div>
        </div>
        <div class="form-group">
          <label>Purpose Visit</label>
          <b-form-radio-group v-model="dataPatient.purpose_visit" :options="options" class="mb-3" value-field="value"
            text-field="name">
          </b-form-radio-group>
        </div>
        <div v-if="dataPatient.purpose_visit == 1">
          <label for="">
            <h3>{{ $t("Initial Check") }}</h3>
            <!-- {{ dataPatient }} -->
          </label>
          <div class="row g-3">
            <div class="form-group col">
              <label>Register Date</label>
              <span style="color: red">*</span>
              <input type="date" class="form-control" v-validate="'required'" name="Register Date" v-model="dataPatient.register_date">
              <span class="text-sm text-danger" v-show="errors.has('Register Date')">{{ errors.first("Register Date")
                }}</span>
            </div>
          </div>
          <div class="row g-3">
            <div class="form-group col">
              <label>Weight</label>
              <span style="color: red">*</span>
              <div class="input-group mb-3">
                <input type="text" class="form-control" min="0" v-validate="'required|numeric'" placeholder="Weight" name="Weight"
                v-model="dataPatient.weight" />
                <span class="input-group-text">KG</span>
              </div>
              <span class="text-sm text-danger" v-show="errors.has('Weight')">{{errors.first("Weight")}}</span>
            </div>
            <div class="form-group col">
              <label>Height</label>
              <span style="color: red">*</span>
              <div class="input-group mb-3">
                <input type="text" class="form-control" v-validate="'required|numeric'" placeholder="Height" name="Height"
                v-model="dataPatient.height" />
                <span class="input-group-text">CM</span>
              </div>
              <span class="text-sm text-danger" v-show="errors.has('Height')">{{
      errors.first("Height")
    }}</span>
            </div>
          </div>
          <div class="row g-3">
            <div class="form-group col">
              <label>Blood Pressure</label>
              <span style="color: red">*</span>
              <input type="text" class="form-control" v-validate="'required|max:24'" placeholder="Blood Pressure" name="Blood Pressure"
                v-model="dataPatient.blood_pressure" />
              <span class="text-sm text-danger" v-show="errors.has('Blood Pressure')">{{ errors.first("Blood Pressure")
                }}</span>
            </div>
            <div class="form-group col">
              <label>Allergy</label>
              <input type="text" class="form-control" v-validate="'max:104'" name="Allergy" v-model="dataPatient.allergy" />
              <span class="text-sm text-danger" v-show="errors.has('Allergy')">{{ errors.first("Allergy")
                }}</span>
            </div>
          </div>
          <div class="row g-3">
            <div class="form-group col">
              <label>Complaint</label>
              <!-- <span style="color: red">*</span> -->
              <textarea type="text" class="form-control" placeholder="Complaint" v-model="dataPatient.init_complaint" />
            </div>
          </div>
        </div>
        <div v-if="dataPatient.purpose_visit == 2">
          <label for="">
            <h3>{{ $t("Non Partnership Info") }}</h3>
          </label>
          <div class="row g-3">
            <div class="form-group col">
              <label>Register Date</label>
              <span style="color: red">*</span>
              <input type="date" class="form-control" v-validate="'required'" name="Register Date" v-model="dataPatient.register_date">
              <span class="text-sm text-danger" v-show="errors.has('Register Date')">{{ errors.first("Register Date")
                }}</span>
            </div>
          </div>
          <div class="row g-3">
            <div class="form-group col">
              <label>Clinic Name</label>
              <span style="color: red">*</span>
              <input type="text" class="form-control" placeholder="Clinic Name" v-validate="'required|max:128'" name="Clinic Name"
                v-model="dataPatient.clinic_name" />
                <span class="text-sm text-danger" v-show="errors.has('Clinic Name')">{{ errors.first("Clinic Name")
                }}</span>
            </div>
            <div class="form-group col">
              <label>Doctor Name</label>
              <span style="color: red">*</span>
              <input type="text" class="form-control" placeholder="Doctor Name" v-validate="'required|max:128'" name="Doctor Name" 
                v-model="dataPatient.doctor_name" />
                <span class="text-sm text-danger" v-show="errors.has('Doctor Name')">{{ errors.first("Doctor Name")
                }}</span>
            </div>
          </div>
        </div>
        <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" @click.prevent="registerPatient()"
          v-if="qrButton == true && isLoading == false">
          Register
        </button>
        <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" @click.prevent="registerPatient2()"
          v-if="manualButton == true && isLoading == false">
          Register
        </button>
        <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="button" disabled
          v-if="qrButton == true && isLoading == true">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Loading...
        </button>
        <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="button" disabled
          v-if="manualButton == true && isLoading == true">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Loading...
        </button>
      </form>
      <form v-if="recs === 'MCU'">
        <!-- <div class="row g-3">
        </div> -->
        <div class="row g-3">
          <div class="form-group col">
            <label>Name</label>
            <input type="text" class="form-control" placeholder="Name" v-model="dataPatient2.fullname" disabled />
          </div>
          <div class="form-group col">
            <label>{{ $t("Company") }}</label>
            <input type="text" class="form-control" placeholder="Company" v-model="dataPatient2.company" disabled />
          </div>
        </div>
        <div class="row g-3">
          <div class="form-group col">
            <label>Department</label>
            <input type="text" class="form-control" placeholder="Department" v-model="dataPatient2.department"
              disabled />
          </div>
          <div class="form-group col">
            <label>{{ $t("Position") }}</label>
            <input type="text" class="form-control" placeholder="Position" v-model="dataPatient2.position" disabled />
          </div>
        </div>
        <div class="row g-3">
          <div class="form-group col">
            <label>Age</label>
            <input type="text" class="form-control" v-model="dataPatient2.employee_age" />
          </div>
          <div class="form-group col">
            <label>Work Periode</label>
            <b-input-group append="Year" style="padding: 0 !important">
              <b-form-input type="text" v-model="dataPatient2.length_of_work"></b-form-input>
            </b-input-group>
          </div>
        </div>
        <div class="row g-3">
          <div class="form-group col">
            <label>MCU Year</label>
            <input type="text" class="form-control" v-model="dataPatient2.mcu_year" />
          </div>
          <div class="form-group col">
            <label>Married Status</label>
            <v-select v-model="dataPatient2.married_status" :reduce="(option) => option.id" :options="[
      { label: 'BELUM KAWIN', id: 0 },
      { label: 'KAWIN', id: 1 }
    ]" />
          </div>
        </div>
        <div class="row g-3">
          <div class="form-group col">
            <label>No Lab</label>
            <input type="text" class="form-control" v-model="dataPatient2.no_lab" />
          </div>
          <div class="form-group col">
            <label>MCU Category</label>
            <v-select v-model="dataPatient2.mcu_category_id" :reduce="(option) => option.id" :options="categoryMCU.data"
              label="category_name" v-validate="'required'" name="Dokter" />
          </div>
        </div>
        <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" @click.prevent="registerPatient()"
          v-if="qrButton == true && isLoading == false">
          Register
        </button>
        <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="button" disabled
          v-if="qrButton == true && isLoading == true">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Loading...
        </button>
        <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" @click.prevent="registerPatient2()"
          v-if="manualButton == true && isLoading == false">
          Register
        </button>
        <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="button" disabled
          v-if="manualButton == true && isLoading == true">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Loading...
        </button>
      </form>
    </b-modal>

    <b-modal id="modal-search" size="xl" hide-footer title="Patient Registration Form" @hide="onHide">
      <!-- <form> -->
      <div class="overlay-layer bg-opacity-5 text-center" v-if="load == true">
        <div class="spinner-border text-primary" style="width: 6rem; height: 6rem" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-else>
        <div class="row g-3">
          <div class="form-group col">
            <label>Name</label>
            <input type="text" class="form-control" placeholder="Name" v-model="search.fullname" v-validate="'max:50'" name="Name" />
              <span class="text-sm text-danger" v-show="errors.has('Name')">{{ errors.first("Name")
                }}</span>
          </div>
          <div class="form-group col">
            <label>NIK</label>
            <input type="text" class="form-control" placeholder="NIK" v-model="search.nik" v-validate="'max:16'" name="NIK" />
              <span class="text-sm text-danger" v-show="errors.has('NIK')">{{ errors.first("NIK")
                }}</span>
          </div>
        </div>
        <div class="row g-3">
          <div class="form-group col">
            <label>No. KTP</label>
            <input type="text" class="form-control" placeholder="KTP" v-model="search.no_ktp" v-validate="'max:16'" name="No. KTP" @keydown="isNumber" />
              <span class="text-sm text-danger" v-show="errors.has('No. KTP')">{{ errors.first("No. KTP")
                }}</span>
          </div>
          <div class="form-group col">
            <label>Date of Birth</label>
            <input type="date" class="form-control" placeholder="Date of Birth" v-model="search.date_of_birth" />
          </div>
          <button style="margin-left: auto; float: right" class="mt-2 mb-5 btn btn-primary"
            @click.prevent="getEmployee()">
            Search
          </button>
        </div>
        <!-- <b-tabs justified  v-if="
            (search.fullname.length > 0 ||
              search.nik.length > 0 ||
              search.no_ktp.length > 0 ||
              search.date_of_birth.length > 0) &&
            employeesList.length > 0
          " >
  <b-tab title="iCore" @click="icore()"  v-if="
            (search.fullname.length > 0 ||
              search.nik.length > 0 ||
              search.no_ktp.length > 0 ||
              search.date_of_birth.length > 0) &&
            employeesList.length > 0
          ">
     
  </b-tab>
  <b-tab title="Keycloak" @click="keycloak()"  v-if="
            (search.fullname.length > 0 ||
              search.nik.length > 0 ||
              search.no_ktp.length > 0 ||
              search.date_of_birth.length > 0) &&
            employeesList.length > 0
          ">
     <vue-good-table
          v-if="
            (search.fullname.length > 0 ||
              search.nik.length > 0 ||
              search.no_ktp.length > 0 ||
              search.date_of_birth.length > 0) &&
            employeesList.length > 0
          "
          :line-numbers="true"
          :columns="columnsSearch"
          :rows="employeesList"
          :sort-options="{
            enabled: true
          }"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            position: 'bottom',
            perPage: 10,
            perPageDropdown: [10, 50, 100],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All'
          }"
          style-class="vgt-table mt-4 border-0 table">
          <div slot="emptystate" class="text-center font-size-14">
            No data available
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'action'">
              <b-button
                class="no-wrap btn-pill"
                variant="primary"
                size="sm"
                @click="dataSelected(props.row)">
                Select
              </b-button>
            </span>

            <span v-else-if="props.column.field == 'created_at'">
              {{ fixTime(props.row.created_at) }}
            </span>
          </template>
        </vue-good-table>
  </b-tab>
  <b-tab title="Uworkforce" @click="uworkforce()"  v-if="
            (search.fullname.length > 0 ||
              search.nik.length > 0 ||
              search.no_ktp.length > 0 ||
              search.date_of_birth.length > 0) &&
            employeesList.length > 0
          ">
     <vue-good-table
          v-if="
            (search.fullname.length > 0 ||
              search.nik.length > 0 ||
              search.no_ktp.length > 0 ||
              search.date_of_birth.length > 0) &&
            employeesList.length > 0
          "
          :line-numbers="true"
          :columns="columnsSearch"
          :rows="employeesList"
          :sort-options="{
            enabled: true
          }"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            position: 'bottom',
            perPage: 10,
            perPageDropdown: [10, 50, 100],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All'
          }"
          style-class="vgt-table mt-4 border-0 table">
          <div slot="emptystate" class="text-center font-size-14">
            No data available
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'action'">
              <b-button
                class="no-wrap btn-pill"
                variant="primary"
                size="sm"
                @click="dataSelected(props.row)">
                Select
              </b-button>
            </span>

            <span v-else-if="props.column.field == 'created_at'">
              {{ fixTime(props.row.created_at) }}
            </span>
          </template>
        </vue-good-table>
  </b-tab>
</b-tabs> -->
        <vue-good-table v-if="searchEmployee == true" :line-numbers="true" :columns="columnsSearch"
          :rows="employeesList" :sort-options="{
      enabled: true
    }" :pagination-options="{
      enabled: true,
      mode: 'pages',
      position: 'bottom',
      perPage: 10,
      perPageDropdown: [10, 50, 100],
      dropdownAllowAll: false,
      setCurrentPage: 1,
      nextLabel: 'next',
      prevLabel: 'prev',
      rowsPerPageLabel: 'Rows per page',
      ofLabel: 'of',
      pageLabel: 'page',
      allLabel: 'All'
    }" style-class="vgt-table mt-4 border-0 table">
          <!-- Loading -->
          <div slot="emptystate" class="text-center font-size-14">
            No data available
          </div>
          <template slot="table-row" slot-scope="props">
            <!-- Table Action -->
            <span v-if="props.column.field == 'action'">
              <b-button class="no-wrap btn-pill" variant="primary" size="sm" @click="dataSelected(props.row)">
                <!-- <span class="fe fe-edit"></span> -->Select
              </b-button>
            </span>

            <span v-else-if="props.column.field == 'created_at'">
              {{ fixTime(props.row.created_at) }}
            </span>
          </template>
        </vue-good-table>
      </div>
      <!-- </form> -->
    </b-modal>

    <b-modal id="modal-3" @hide="onHide" hide-footer title="QRCode" v-if="isQRCode === true">
      <div v-if="isQRCode === true">
        <div class="d-block text-center">
          <h3>{{ $t("Please Scan Barcode or Input Registration Code") }}</h3>
        </div>
        <div class="d-block text-center">
          <h1>
            <b style="letter-spacing: 0.25rem; font-size: 32px">{{
      qrData.code
    }}</b>
          </h1>
          <div>
            <qr-code :text="`${qrData.code}-${body.clinic_id}`" :size="500"
              class="d-flex justify-content-center"></qr-code>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- Modal Import File -->
    <b-modal id="importFile" size="xl" hide-footer title="Form Import MCU">
      <form @submit.prevent="importData">
        <div class="row">
          <div class="col-12 mb-3" v-for="(item, i) in importPayload" :key="i">
            <label>Upload Hasil MCU(.xlsx)
              <span style="color: red">*</span>
              <span class="mx-3"><a :href="item.url" download target="_blank">
                  <i class="fe fe-download"></i> Download Template
                </a></span>
            </label>
            <input type="file" class="form-control" accept=".xls,.xlsx" @change="(e) => setFile(e, i)" v-validate="'required'" />
            <i>*Please download the template before importing file excel</i>
            <br />

            <label class="mt-3">Upload Lampiran MCU(.zip)</label>
            <span style="color: red">*</span>
            <input type="file" class="form-control mt-2" accept=".zip" @change="(e) => setFile2(e, i)" v-validate="'required'" />
            <!-- <label class="mt-3">Upload Rontgen Thorax</label>
            <input
              type="file"
              class="form-control mt-2"
              @change="(e) => setFile2(e, i)" />

            <label class="mt-3">Upload EKG</label>
            <input
              type="file"
              class="form-control mt-2"
              @change="(e) => setFile3(e, i)" />

            <label class="mt-3">Upload Audiometri</label>
            <input
              type="file"
              class="form-control mt-2"
              @change="(e) => setFile4(e, i)" /> 
            <label class="mt-3">Upload MCU Lab</label>
            <input
              type="file"
              class="form-control mt-2"
              @change="(e) => setFile5(e, i)" />  -->
          </div>
        </div>
        <!-- <div class="col-4"> -->
        <div class="form-group">
          <!-- <v-select
              class="mb-3"
                :options="doctorList"
                v-model="importFile.doctor_name"
                label="fullname"
                :reduce="(e) => e.user_id"
                /> -->
          <label>{{ $t("Doctor Name") }}</label>
          <span style="color: red">*</span>
          <input type="text" class="form-control mb-3" v-model="importFile.doctor_name" v-validate="'required'" />

          <label>{{ $t("Company") }}</label>
          <span style="color: red">*</span>
          <v-select class="mb-3" :options="selectCompanyOptions()" v-model="importFile.company_id" label="company_name"
            :reduce="(e) => e.id" v-validate="'required'" />

          <label>{{ $t("Type MCU") }}</label>
          <span style="color: red">*</span>
          <v-select class="mb-3" :options="categoryMCU.data" v-model="importFile.mcu_category_id"
            :reduce="(option) => option.id" label="category_name" v-validate="'required'" name="Dokter" />

          <label>{{ $t("Lab") }}</label>
          <input type="text" class="form-control" v-model="importFile.label_lab" disabled />
          <!-- <v-select
              class="mb-3"
                :options="selectLabOption()"
                v-model="importFile.lab_id"
                label="healthcare_facility_name"
                :reduce="(e) => e.id"
                 /> -->
        </div>
        <!-- </div> -->
        <div class="row">
          <div class="col-12 text-right">
            <button style="margin-left: auto; float: right" class="btn btn-primary mt-4" type="submit"
              v-if="isLoading === false">
              Save
            </button>
            <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="button" disabled v-else>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </div>
      </form>
    </b-modal>

    <b-modal id="scanner" size="xl" hide-footer title="Scanner Form" @hide="onHide">
      <div class="row justify-content-center">
        <div class="col-12 col-xl-10">
          <!-- Title -->
          <h2 class="mt-5 mb-2 text-center" v-if="isLength == false">
            Please scan your ID Card
            <br />
            <img src="@/assets/img/scan.gif" style="width: 800px" />
            <!-- <div class="spinner-border mt-3" role="status">
              <span class="visually-hidden">Loading...</span>
            </div> -->
          </h2>

          <div class="table-responsive mb-0" v-else>
            <h2>We found {{ users.length }} similar data</h2>
            <table class="table table-sm table-nowrap card-table">
              <thead>
                <tr>
                  <th>Fullname</th>
                  <th>NIK</th>
                  <th>No.KTP</th>
                  <th>Company</th>
                  <th>Date of Birth</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in users" :key="i">
                  <td>
                    <span>{{ item.fullname }}</span>
                  </td>
                  <td>{{ item.nik }}</td>
                  <td>{{ item.no_ktp }}</td>
                  <td>{{ item.company }}</td>
                  <td>{{ item.date_of_birth }}</td>
                  <td>
                    <b-button class="no-wrap btn-pill" variant="primary" size="sm" @click="dataSelected(item)">Select
                      <!-- @click="dataSelected(item)" -->
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Button -->
          <!-- <textarea class="form-control" /> -->
          <textarea style="opacity: 0" @keydown="edan" id="EdanSir" class="form-control" v-model="Sf" />
          <!-- <div style="white-space: pre-wrap;">{{ Sf }}</div> -->
        </div>
      </div>
      <!-- / .row -->
    </b-modal>
  </div>
</template>
<script>
import moment from "moment";
import axiosIns from "@/api/axios";
// import position from "@/store/medical/staff/position";
// import { flat } from "vue-cli-plugin-bootstrap-vue/sassAbstractsImports";
export default {
  name: "patient",
  computed: {
    // materialtype() {
    //   return this.$store.getters["type/TypeData"]
    //     ? this.$store.getters["type/TypeData"]
    //     : [];
    // },
    healthcare() {
      return this.$store.getters["healthcare/HealthcareData"]
        ? this.$store.getters["healthcare/HealthcareData"]
        : [];
    },
    facility() {
      return this.$store.getters["facility/FacilityData"]
        ? this.$store.getters["facility/FacilityData"]
        : [];
    },
    feature() {
      return this.$store.getters["healthcare/FeatureData"]
        ? this.$store.getters["healthcare/FeatureData"]
        : [];
    },
    qrData() {
      return this.$store.getters["patient/qrData"]
        ? this.$store.getters["patient/qrData"]
        : [];
    },
    patientDataQr() {
      return this.$store.getters["patient/patientQrData"]
        ? this.$store.getters["patient/patientQrData"]
        : [];
    },
    dataGet() {
      return this.$store.getters["patient/allData"]
        ? this.$store.getters["patient/allData"]
        : [];
    },
    employees() {
      return this.$store.getters["patient/employeeData"]
        ? this.$store.getters["patient/employeeData"]
        : [];
    },
    drug() {
      return this.$store.getters["drug/DrugData"]
        ? this.$store.getters["drug/DrugData"]
        : [];
    },
    unit() {
      return this.$store.getters["po/UnitProductData"]
        ? this.$store.getters["po/UnitProductData"]
        : [];
    },
    unitReceipt() {
      return this.$store.getters["po/UnitProductData"]
        ? this.$store.getters["po/UnitProductData"]
        : [];
    },
    // unit() {
    //   return this.$store.getters["unit/UnitData"]
    //     ? this.$store.getters["unit/UnitData"]
    //     : [];
    // },

    desease() {
      return this.$store.getters["disease/deseaseData"]
        ? this.$store.getters["disease/deseaseData"]
        : [];
    },
    deseaseDiagnose() {
      return this.$store.getters["disease/deseaseDataDiagnose"]
        ? this.$store.getters["disease/deseaseDataDiagnose"]
        : [];
    },
    // doctors() {
    //   return this.$store.getters["patient/doctorData"]
    //     ? this.$store.getters["patient/doctorData"]
    //     : [];
    // },
    doctorsKataKemas() {
      return this.$store.getters["roleManagement/roleManagement"]
        ? this.$store.getters["roleManagement/roleManagement"]
        : [];
    },
    treatment() {
      return this.$store.getters["treatment/TreatmentData"]
        ? this.$store.getters["treatment/TreatmentData"]
        : [];
    },
    category() {
      return this.$store.getters["patient/categoryData"]
        ? this.$store.getters["patient/categoryData"]
        : [];
    },
    howtouse() {
      return this.$store.getters["patient/howtouseData"]
        ? this.$store.getters["patient/howtouseData"]
        : [];
    },
    dose() {
      return this.$store.getters["patient/doseData"]
        ? this.$store.getters["patient/doseData"]
        : [];
    },
    filtered() {
      const data = this.$store.getters["disease/deseaseData"] || [];
      return data.data;
    },
    paginated() {
      const data = this.$store.getters["disease/deseaseData"] || [];
      return data.data;
    },
    hasNextPage() {
      const data = this.$store.getters["disease/deseaseData"] || [];
      return true;
    },

    category() {
      return this.$store.getters["category/CategoryData"]
        ? this.$store.getters["category/CategoryData"]
        : [];
    },
    block() {
      return this.$store.getters["block/BlockData"]
        ? this.$store.getters["block/BlockData"]
        : [];
    },
    chapter() {
      return this.$store.getters["chapter/ChapterData"]
        ? this.$store.getters["chapter/ChapterData"]
        : [];
    },

    categoryTest() {
      return this.$store.getters["categoryMcuTest/CategoryData"]
        ? this.$store.getters["categoryMcuTest/CategoryData"]
        : [];
    },
    groupTest() {
      return this.$store.getters["groupMcu/GroupData"]
        ? this.$store.getters["groupMcu/GroupData"]
        : [];
    },
    itemTest() {
      return this.$store.getters["itemMcu/ItemData"]
        ? this.$store.getters["itemMcu/ItemData"]
        : [];
    },
    categoryMCU() {
      return this.$store.getters["categoryMcu/CategoryData"]
        ? this.$store.getters["categoryMcu/CategoryData"]
        : [];
    },
    users() {
      return this.$store.getters["roleManagement/dataUser"]
        ? this.$store.getters["roleManagement/dataUser"]
        : [];
    },
    companyFilter() {
      return this.$store.getters["detailManagement/dataCompany"]
        ? this.$store.getters["detailManagement/dataCompany"]
        : [];
    }
  },
  data() {
    return {
      filterCompany: "",
      company: "",
      doctorList: "",
      company_id: "",
      company_name: "",
      healthcare_facility_name: "",
      bodyExport: {
        type: "",
        company_id: "",
        start_date: "",
        end_date: "",
        item_type: "",
        date: ""
      },
      importFile: {
        doctor_name: "",
        company_id: "",
        mcu_category_id: "",
        lab_id: "",
        label_lab: ""
      },
      // sockets: {},
      // lol: {},
      currentstep: 0,
      search: {
        nik: "",
        fullname: "",
        no_ktp: "",
        date_of_birth: ""
        // flag: ""
        // company_id:
      },
      file: null,
      body: {
        limit: 10,
        page: 1,
        employee_name: "",
        company_id: "",
        company_name: "",
        type_visit: "Rawat Jalan",
        start_date: moment().format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
        clinic_id: ""
      },
      bodyDiagnoseDisiase: {
        page: 1,
        chapter_id: "",
        block_id: "",
        category_id: "",
        disease_name: ""
      },
      addDisease: {
        chapter_id: "",
        block_id: "",
        category_id: "",
        disease_id: "",
        disease_name: ""
      },
      addDiseaseValue: [
        {
          chapter: "",
          block: "",
          category: "",
          disease_id: "",
          disease_name: ""
        }
      ],
      diagnose: {
        id: "",
        bmi: "",
        init_complaint: "",
        weight: "",
        height: "",
        blood_pressure: "",
        allergy: "",
        visit_type: "",
        doctor: "",
        main_complaint: "",
        vital_sign: "",
        disease_id: "",
        spell: "",
        pak: 2,
        file_doctor_letter: [],
        file_receipt_letter: [],
        file_sick_letter: [],
        disease: [
          {
            chapter: "",
            block: "",
            category: "",
            disease_id: "",
            disease_name: ""
          }
        ],
        drugs: [
          // {
          //   drugs_id: "",
          //   qty: 1,
          //   drugs_unit_id: "",
          //   expired_date: "",
          //   how_use: "",
          //   is_external: false,
          //   drugExpireDate: [],
          //   dataAddDrugs: []
          // }
        ],
        disposable: [],
        doctor_action: "",
        action_care: "",
        follow_up: "",
        star_date: moment().format("YYYY-MM-DD"),
        next_visit: "",
        doctor_note: "",
        sick_leave: 0,
        sick_leave_day: "",
        non_medisin: ""
      },
      mcuData: {
        register_id: "",
        mcu_record: []
      },
      importPayload: [
        {
          file: null,
          sample: "/kosong.xlsx",
          url: "https://ugems.id/documents/d/guest/template-mcu-uhealth-1?download=true"
        }
      ],
      importPayload2: [{}],
      dataPatient: {
        blood_type: "",
        clinic_id: "",
        code: "",
        company: "",
        company_id: "",
        date_of_birth: "",
        department: "",
        fullname: "",
        gender: "",
        id: "",
        nik: "",
        position: "",
        status: "",
        init_complaint: "",
        weight: null,
        height: null,
        blood_pressure: "",
        allergy: "",
        clinic_feature_id: "",
        purpose_visit: "",
        clinic_name: "",
        doctor_name: "",
        flag: "",
        register_date: moment().format("YYYY-MM-DD")
      },
      dataPatient2: {
        id: "",
        clinic_id: "",
        clinic_feature_id: "",
        company: "",
        employee_age: "",
        length_of_work: "",
        mcu_year: "",
        married_status: "",
        mcu_category_id: "",
        fullname: "",
        date_of_birth: "",
        department: "",
        gender: "",
        nik: "",
        position: "",
        no_lab: "",
        mcu_location: "",
        searchEmployee: false
      },
      options: [
        {
          name: "Health Check",
          value: 1
        },
        {
          name: "Patient From Non Partnership Clinic",
          value: 2
        }
      ],
      optionsReceipt: [
        {
          item: 0,
          name: "Non Medisin"
        }
      ],
      selected: {
        chapter: {
          id: "",
          name: ""
        },
        block: {
          id: "",
          name: ""
        },
        category: {
          id: "",
          name: ""
        },
        position: 0
      },
      loadingDiagnose: false,
      isSearch: false,
      pos: ["Chapter", "Block", "Category"],
      disposableItemOption: [],
      drugOption: [],
      unitOption: [],
      diagonseDisease: [],
      infoDiagnose: {},
      type_visit: "",
      register_date: moment().format("YYYY-MM-DD"),
      register_id: 0,
      purpose_visit: 0,
      clinic_ids: "",
      clinic_features: [],
      feature_id: "",
      datasRegis: {},
      load: true,
      loads: true,
      otherDose: false,
      otherRule: false,
      kunjungan: null,
      id: "",
      statuss: "",
      isManualInput: true,
      isQRCode: true,
      isUpdate: true,
      qrButton: false,
      isLoading: false,
      manualButton: false,
      isLength: false,
      Sf: "",
      modalScanner: false,
      barcodeData: "",
      scanningStarted: false,
      startCharacter: "*",
      endCharacter: "#",
      disabledKemas: false,
      recs: "",
      loadExp: false,
      columns: [
        {
          label: "NAMA KARYAWAN",
          field: "patient_name",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "PERUSAHAAN",
          field: "company",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "DEPARTEMEN",
          field: "department",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "STATUS",
          field: "status",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "JENIS KUNJUNGAN",
          field: "type_visit",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "WAKTU REGIS",
          field: "register_date",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold ",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "DIBUAT OLEH",
          field: "paramedic_name",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "ACTION",
          field: "action",
          thClass:
            "table-header no-wrap text-muted text-center font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle",
          sortable: false
        }
      ],
      columnsDrugs: [
        {
          label: "Medicine Name",
          field: "item_label",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "UNIT",
          field: "uom_label",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Stock",
          field: "qty",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary text-center align-middle"
        },
        {
          label: "EXP DATE",
          field: "expiry_date",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary text-center align-middle"
        }
      ],
      desease_search: false,
      desease_timeout: null,
      desease_timeout_chapter: null,
      desease_paramater: {
        page: 1,
        limit: 10,
        disease_name: ""
      },
      desease_chapter_paramater: {
        page: 1,
        limit: "",
        disease_name: ""
      },
      desease_observer: null,
      columnsSearch: [
        {
          label: "EMPLOYEE NAME",
          field: "fullname",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "NIK",
          field: "nik",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "NO KTP",
          field: "no_ktp",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Company",
          field: "company",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "DATE OF BIRTH",
          field: "date_of_birth",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "FLAG",
          field: "flag",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "ACTION",
          field: "action",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        }
      ],
      dataBlock: [],
      dataCategories: [],
      dataDisease: [],
      role_user: [],
      employeesList: [],
      doctorList: [],
      set: ""
    };
  },
  mounted() {
    let user_role = localStorage.getItem("user_roles");
    this.role_user = JSON.parse(user_role);
    this.company_id = localStorage.getItem("company");
    this.company_name = localStorage.getItem("company_name");
    const roles = localStorage.getItem("roles_id");
    if(JSON.parse(user_role).findIndex((e) => e.roles_id == 1 || e.roles_id == 3) > -1) {
        this.body.company_id = "";
      } else {
        this.body.company_id = localStorage.getItem("company")
      }
    if (JSON.parse(user_role).findIndex((e) => e.roles_id == 1) > -1) {
      this.filterCompany = 1;  
    }
    
    // let clinic_id = JSON.parse(localStorage.getItem("dataMedical"))
    // this.clinic_ids = clinic_id.clinic_id;
    this.getAllData();
    this.getDrug();
    this.getDisposable();
    this.desease_observer = new IntersectionObserver(this.getDesease);
    this.body.clinic_id = this.clinic_ids;
    this.dataPatient.clinic_id = this.clinic_ids;
    this.clinic_features = JSON.parse(localStorage.getItem("clinic_feature"));
    // this.getDoctor();
    this.getTreatment();
    this.getCategory();
    this.getCompany();
    // this.getHealthcare()
    // this.getFacility()
    // this.getFacilityFeature()
    // this.getDesease();
    this.getCategoryDisease();
    this.getBlock();
    this.getChapter();
    this.getCategoryMCU();
    this.getDoctorKataKemas();
    this.getDose();
    this.getHowtouse();
    // document.addEventListener('keydown', this.handleBarcodeScan);
  },
  watch: {
    "diagnose.weight": "calculateBMI",
    "diagnose.height": "calculateBMI"
  },
  // beforeUnmount() {
  //   document.removeEventListener('keydown', this.handleBarcodeScan);
  // },

  methods: {
    calculateBMI() {
      const weight = parseFloat(this.diagnose.weight);
      const height = parseFloat(this.diagnose.height);

      if (!isNaN(weight) && !isNaN(height) && height !== 0) {
        const bmi = (weight / Math.pow(height / 100, 2)).toFixed(2);
        this.diagnose.bmi = bmi;
      } else {
        this.diagnose.bmi = 0;
      }
    },
    // icore() {
    //   this.search.flag = "iCore";
    //   this.getEmployeeTab();
    // },
    // keycloak() {
    //   this.search.flag = "Keycloak";
    //   this.getEmployeeTab();
    // },
    // uworkforce() {
    //   this.load = true;
    //   this.search.flag = "Uworkforce";
    //   this.getEmployeeTab();
    // },
    getHealthcare() {
      this.$store.dispatch("healthcare/getHealthcare", this.body);
    },
    getFacility() {
      this.$store.dispatch("facility/getFacility", this.body);
    },
    getFacilityFeature() {
      this.$store.dispatch("healthcare/getFeature", this.body);
    },
    getCompany() {
      this.$store.dispatch("detailManagement/getCompany");
    },
    selectLabOption() {
      const lab = this.healthcare;
      const term = [];
      lab.map((i) => {
        term.push({
          id: i.id,
          healthcare_facility_name: i.healthcare_facility_name
        });
      });
    },
    selectCompanyOption() {
      const company = this.companyFilter;
      const term = [];
      term.push({
        id: 0,
        company_name: "BIB + Mitra"
      });

      company.map((i) => {
        term.push({
          id: i.id,
          company_name: i.company_name
        });
      });
      return term;
    },
    selectCompanyOptions() {
      const company = this.companyFilter;
      const term = [];
      term.push({
        id: 0,
        company_name: "BIB + Mitra"
      });

      company.map((i) => {
        term.push({
          id: i.id,
          company_name: i.company_name
        });
      });
      return term;
    },

    selectDoctorOptions() {
      const doctorList = this.$store.getters["roleManagement/roleManagement"];
      const term = [];
      doctorList.map((i) => {
        term.push({
          id: i.id,
          doctor_name: i.doctor_name
        });
      });
    },
    categoryChange(name, index) {
      let arr = [];
      for (let i = 0; i < this.groupTest.data.length; i++) {
        if (this.groupTest.data[i].category_name === name) {
          arr.push(this.groupTest.data[i]);
        }
      }

      this.mcuData.mcu_record[index].optionGroup = arr;
    },
    groupChange(name, index) {
      let arr = [];
      for (let i = 0; i < this.itemTest.data.length; i++) {
        if (this.itemTest.data[i].group_name === name) {
          arr.push(this.itemTest.data[i]);
        }
      }

      this.mcuData.mcu_record[index].optionTitle = arr;
    },
    checkQtyDisposable(id, qty, index) {
      const item = this.disposableItemOption.find((el) => el.id === id);
      if (qty > item.base_qty) {
        this.$swal("Error", "Quantity more than stock", "error");
        this.diagnose.disposable[index].qty = item.base_qty;
      }
    },
    reset() {
      this.currentstep = 0;

      this.dataPatient.purpose_visit = 0;
      this.dataPatient.clinic_name = "";
      this.dataPatient.doctor_name = "";
    },
    selectedPos() {
      if (this.selected.position == 0) {
        return "Chapter";
      } else if (this.selected.position == 1) {
        return this.selected.chapter.name;
      } else if (this.selected.position == 2) {
        return this.selected.block.name;
      } else if (this.selected.position == 3) {
        return this.selected.category.name;
      }
    },
    backspace() {
      this.bodyDiagnoseDisiase.page = 1;
      if (this.selected.position == 1) {
        this.loadingDiagnose = true;
        this.selected.position = this.selected.position - 1;
        this.selected.chapter.id = "";
        this.selected.chapter.name = "";
        this.bodyDiagnoseDisiase.chapter_id = "";

        this.$store
          .dispatch("disease/getDiseaseDiagnose", this.bodyDiagnoseDisiase)
          .then(() => {
            let val = this.$store.getters["disease/deseaseDataDiagnose"];
            this.diagonseDisease = val.rows;
            this.infoDiagnose = val;
            this.loadingDiagnose = false;
          });
      } else if (this.selected.position == 2) {
        this.loadingDiagnose = true;
        this.selected.position = this.selected.position - 1;
        this.selected.block.id = "";
        this.selected.block.name = "";
        this.bodyDiagnoseDisiase.block_id = "";

        this.$store
          .dispatch("disease/getDiseaseDiagnose", this.bodyDiagnoseDisiase)
          .then(() => {
            let val = this.$store.getters["disease/deseaseDataDiagnose"];
            this.diagonseDisease = val.rows;
            this.infoDiagnose = val;
            this.loadingDiagnose = false;
          });
      } else if (this.selected.position == 3) {
        this.loadingDiagnose = true;
        this.selected.position = this.selected.position - 1;
        this.selected.category.id = "";
        this.selected.category.name = "";
        this.bodyDiagnoseDisiase.category_id = "";

        this.$store
          .dispatch("disease/getDiseaseDiagnose", this.bodyDiagnoseDisiase)
          .then(() => {
            let val = this.$store.getters["disease/deseaseDataDiagnose"];
            this.diagonseDisease = val.rows;
            this.infoDiagnose = val;
            this.loadingDiagnose = false;
          });
      } else if (this.selected.position == 4) {
      }
    },
    wokwok(e) {
      this.bodyDiagnoseDisiase.page = 1;
      if (this.selected.position == 0) {
        this.isSearch = false;
        this.loadingDiagnose = true;
        this.selected.position = this.selected.position + 1;
        this.selected.chapter.id = e.id;
        this.selected.chapter.name = e.chapter_name;
        this.bodyDiagnoseDisiase.chapter_id = this.selected.chapter.id;

        this.$store
          .dispatch("disease/getDiseaseDiagnose", this.bodyDiagnoseDisiase)
          .then(() => {
            let val = this.$store.getters["disease/deseaseDataDiagnose"];
            this.diagonseDisease = val.rows;
            this.infoDiagnose = val;
            this.loadingDiagnose = false;
          });
      } else if (this.selected.position == 1) {
        this.isSearch = false;
        this.loadingDiagnose = true;
        this.selected.position = this.selected.position + 1;
        this.selected.block.id = e.id;
        this.selected.block.name = e.block_name;
        this.bodyDiagnoseDisiase.block_id = this.selected.block.id;

        this.$store
          .dispatch("disease/getDiseaseDiagnose", this.bodyDiagnoseDisiase)
          .then(() => {
            let val = this.$store.getters["disease/deseaseDataDiagnose"];
            this.diagonseDisease = val.rows;
            this.infoDiagnose = val;
            this.loadingDiagnose = false;
          });
      } else if (this.selected.position == 2) {
        this.isSearch = false;
        this.loadingDiagnose = true;
        this.selected.position = this.selected.position + 1;
        this.selected.category.id = e.id;
        this.selected.category.name = e.category_name;
        this.bodyDiagnoseDisiase.category_id = this.selected.category.id;

        this.$store
          .dispatch("disease/getDiseaseDiagnose", this.bodyDiagnoseDisiase)
          .then(() => {
            let val = this.$store.getters["disease/deseaseDataDiagnose"];
            this.diagonseDisease = val.rows;
            this.infoDiagnose = val;
            this.loadingDiagnose = false;
          });
      } else if (this.selected.position == 3) {
        const data = {
          disease_id: e.id,
          chapter: e.chapter_name,
          block: e.block_name,
          category: e.category_name,
          disease_name: e.disease_name
        };
        this.diagnose.disease.push(data);
        this.$bvModal.hide("addDisease");

        this.bodyDiagnoseDisiase = {
          page: 1,
          chapter_id: "",
          block_id: "",
          category_id: "",
          disease_name: ""
        };
      }
    },
    getData() {
      axios
        .get("/test/server", {
          maxContentLength: 100000
        })
        .then(function (response) { })
        .catch(function (err) {
          console.error(err);
          this.$swal.fire("Error!", `${err.response.data.error}`, "error");
        });
    },
    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
      }
      this.isLength = false;
      this.Sf = "";
      this.searchEmployee = false;
      clearInterval(this.set);
      this.modalScanner = false;
      this.search = {
        no_ktp: "",
        fullname: "",
        nik: "",
        date_of_birth: ""
      };
      this.selected = {
        chapter: {
          id: "",
          name: ""
        },
        block: {
          id: "",
          name: ""
        },
        category: {
          id: "",
          name: ""
        },
        position: 0
      };
    },
    nextStep() {
      this.currentstep = this.currentstep + 1;
    },

    lastStep() {
      this.currentstep = this.currentstep - 1;
    },
    setFile(e, i) {
      // const files = e.target.files;
      // if (files) {
      const file = e.target.files[0];
      this.importPayload[i]["file"] = file;
    },
    setFile2(e, i) {
      const file = e.target.files[0];
      this.importPayload2[i]["file"] = file;
    },

    async importData() {
      try {
        this.isLoading = true;
        const importPayload = this.importPayload;
        const importPayload2 = this.importPayload2;

        const invalidFile = importPayload.findIndex((e) => !e.file);
        if (invalidFile > -1) {
          this.isLoading = false;
          this.$swal({
            icon: "warning",
            title: "Oops!",
            text: "Please fill in all fields first"
          });
          return;
        }

        // let i = 0;
        // let validator = ''
        // while (i < importPayload.length) {
        const file = importPayload[0]["file"];
        const file2 = importPayload2[0]["file"];

        const formData = new FormData();
        //     importPayload.forEach((item, index) => {
        //   const file = item.file;
        //   formData.append(`files${index + 1}`, file);
        // });
        formData.append("doctor_name", this.importFile.doctor_name);
        formData.append("company_id", this.importFile.company_id);
        formData.append("type_mcu_id", this.importFile.mcu_category_id);
        formData.append("lab_id", this.importFile.lab_id);
        formData.append("files", file);
        formData.append("files", file2);
        // formData.append("fileZip",file2)
        // formData.append("fileRontgen", file2);
        // formData.append("fileEkg", file3);
        // formData.append("fileAudiometri", file4);
        // formData.append("fileMcuLab", file5);

        this.$store
          .dispatch("patient/importRegisterPatient", formData)
          .then((resp) => {
            if (resp === true) {
              this.$swal({
                icon: "success",
                title: "Succes",
                text: "Success import file"
              });
              this.isLoading = false;
              this.$bvModal.hide("importFile");
              this.getAllData();
            } else {
              this.$swal({
                icon: "error",
                title: "Error",
                text: "Something Wrong with file, Try Again"
              });
              this.isLoading = false;
              this.importFile.doctor_name = "";
              this.importFile.company_id = "";
            }
          });

        return;
      } catch (err) {
        console.log("Error while importing mcu record", err);
      }
    },
    addDrugs() {
      const blank_drugs = {
        drugs_id: "",
        qty: "",
        drugs_unit_id: "",
        expired_date: "",
        how_use: "",
        dose: "",
        is_external: false,
        changeDose: "",
        changeRule: "",
        dataAddDrugs: [],
        drugExpireDate: []
      };
      this.diagnose.drugs.push(blank_drugs);
    },
    addDrugsDisposable() {
      const blank_drugs = {
        // drugs_id: "",
        qty: "",
        // drugs_unit_id: "",
        disposableExpireDate: [],
        uom: []
      };
      this.diagnose.disposable.push(blank_drugs);
    },
    // addDisease() {
    //   const blank_disease = {
    //     disease_id: "",
    //   };
    //   this.diagnose.disease.push(blank_disease);
    // },
    chapterSelectToBlock() {
      this.load = false;
      let blocks = this.block.data;
      let chapter_id = this.addDisease.chapter_id;
      var fill = blocks.filter((item) => {
        return item.chapter_id == chapter_id;
      });
      this.dataBlock = fill;
    },
    blockSelectToCatgory() {
      this.load = false;
      let catgories = this.category.data;
      let block_id = this.addDisease.block_id;
      let chapter_id = this.addDisease.chapter_id;
      var fill = catgories.filter((item) => {
        return item.block_id == block_id && item.chapter_id == chapter_id;
      });
      this.dataCategories = fill;
    },
    categorySelectToDisease() {
      this.load = false;
      let diseasies = this.desease.data;
      let block_id = this.addDisease.block_id;
      let chapter_id = this.addDisease.chapter_id;
      let category_id = this.addDisease.category_id;
      var fill = diseasies.filter((item) => {
        return (
          item.block_id == block_id &&
          item.chapter_id == chapter_id &&
          item.category_id == category_id
        );
      });
      this.dataDisease = fill;
    },
    async fileUploaderDoctor(event) {
      this.diagnose.file_doctor_letter = event.target.files;
    },
    async fileUploaderReceipt(event) {
      this.diagnose.file_receipt_letter = event.target.files;
    },
    async fileUploaderLicense(event) {
      this.diagnose.file_sick_letter = event.target.files;
    },
    saveAddDisease() {
      if (this.addDisease.disease_id == 0) {
        this.$validator.validateAll().then(async (res) => {
          if (!res) return false;
          this.load = false;
        });
      } else {
        this.load = false;
        let chapter = this.chapter.data;
        let blocks = this.block.data;
        let categories = this.category.data;
        let diseasies = this.desease.data;

        let block_id = this.addDisease.block_id;
        let chapter_id = this.addDisease.chapter_id;
        let category_id = this.addDisease.category_id;
        let disease_id = this.addDisease.disease_id;

        var fillChapter = chapter.filter((item) => {
          return item.id == chapter_id;
        });
        var fillBlocks = blocks.filter((item) => {
          return item.id == block_id;
        });
        var fillCategories = categories.filter((item) => {
          return item.id == category_id;
        });

        var fillDisease = diseasies.filter((item) => {
          return item.id == disease_id;
        });

        const data = {
          disease_id: this.addDisease.disease_id,
          chapter: fillChapter[0].chapter_name,
          block: fillBlocks[0].block_name,
          category: fillCategories[0].category_name,
          disease_name: fillDisease[0].disease_name
        };
        this.diagnose.disease.push(data);
        this.$bvModal.hide("addDisease");
        this.load = false;
      }
    },
    // reset() {
    //   this.currentstep = 0;
    // },
    searchDiagnose(e) {
      if (parseInt(e.keyCode) == 13) {
        this.loadingDiagnose = true;
        this.bodyDiagnoseDisiase.block_id = "";
        this.bodyDiagnoseDisiase.chapter_id = "";
        this.bodyDiagnoseDisiase.category_id = "";
        if (this.bodyDiagnoseDisiase.disease_name) {
          this.selected.position = 3;
          this.isSearch = true;
        } else {
          this.selected.position = 0;
          this.isSearch = false;
        }
        this.$store
          .dispatch("disease/getDiseaseDiagnose", this.bodyDiagnoseDisiase)
          .then(() => {
            let val = this.$store.getters["disease/deseaseDataDiagnose"];
            this.diagonseDisease = val.rows;
            this.infoDiagnose = val;
            this.loadingDiagnose = false;
          });
      }
    },
    showMore() {
      this.loadingDiagnose = true;
      this.bodyDiagnoseDisiase.page = this.bodyDiagnoseDisiase.page + 1;
      this.$store
        .dispatch("disease/getDiseaseDiagnose", this.bodyDiagnoseDisiase)
        .then(() => {
          let val = this.$store.getters["disease/deseaseDataDiagnose"];
          let rows = this.diagonseDisease.concat(val.rows);
          this.diagonseDisease = rows;
          this.infoDiagnose = val;
          this.loadingDiagnose = false;
        });
    },
    resetAddDisease() {
      this.loadingDiagnose = true;
      this.selected = {
        chapter: {
          id: "",
          name: ""
        },
        block: {
          id: "",
          name: ""
        },
        category: {
          id: "",
          name: ""
        },
        position: 0
      };
      this.$store
        .dispatch("disease/getDiseaseDiagnose", {
          page: 1,
          chapter_id: "",
          block_id: "",
          category_id: "",
          disease_name: ""
        })
        .then(() => {
          this.loadingDiagnose = false;
          let val = this.$store.getters["disease/deseaseDataDiagnose"];
          this.diagonseDisease = val.rows;
          this.infoDiagnose = val;
        });
    },
    removeDrugs(index) {
      this.diagnose.drugs.splice(index, 1);
    },
    removeDisease(index) {
      // let vars = JSON.stringify(this.diagnose.disease)
      // let vars2 = JSON.parse(vars)
      // let after = vars2.splice(index, 1);
      // this.diagnose.disease = after
      this.diagnose.disease.splice(index, 1);
      // this.addDiseaseValue.splice(index, 1);
    },
    removeDrugsDisposable(index) {
      this.diagnose.disposable.splice(index, 1);
    },

    newRecord() {
      const blank_record = {
        category_name: "",
        group_name: "",
        check_title: "",
        check_value: "",
        optionGroup: [],
        optionTitle: []
      };
      this.mcuData.mcu_record.push(blank_record);
    },
    removeRecord(i) {
      this.mcuData.mcu_record.splice(i, 1);
    },

    fixTime(data) {
      return moment(data).format("DD MMMM YYYY");
    },

    selectDropdown(unit) { },

    title() {
      const clinicFeature =
        this.role_user.length > 0
          ? this.role_user[0].healthcare_facility_feature
          : [];
      const invent = clinicFeature.findIndex(
        (el) => el.healthcare_facility_feature_id === "1"
      );
      // const indexx = this.clinic_features.findIndex(
      //   (e) => e.clinic_feature_id === "1"
      // );
      if (invent > -1) {
        return "Clinic";
      } else {
        return "MCU";
      }
    },

    manualInputs() {
      const clinicFeature =
        this.role_user.length > 0
          ? this.role_user[0].healthcare_facility_feature
          : [];
      const invent = clinicFeature.findIndex(
        (el) => el.healthcare_facility_feature_id === "1"
      );
      // const indexx = this.clinic_features.findIndex(
      //   (e) => e.clinic_feature_id === "1"
      // );
      if (invent > -1) {
        this.recs = "Clinic";
        this.dataPatient2.clinic_feature_id = 1;
        this.dataPatient.clinic_feature_id = 1;
      } else {
        this.recs = "MCU";
        this.dataPatient2.clinic_feature_id = 3;
        this.dataPatient.clinic_feature_id = 3;
      }
    },

    getDataEmploye(e) {
      let id = this.dataPatient.fullname;
      let datas = e.filter((x) => x.id === id);
      this.dataPatient.company = datas[0].company;
      if (datas[0].gender === "M") {
        this.dataPatient.gender = "Male";
      } else if (datas[0].gender === "F") {
        this.dataPatient.gender = "Female";
      } else {
        this.dataPatient.gender = "Unknown";
      }
      this.dataPatient.date_of_birth = datas[0].date_of_birth;
      this.dataPatient.nik = datas[0].nik;
      this.dataPatient.blood_type = datas[0].blood_type;
      this.dataPatient.position = datas[0].position;
      this.dataPatient.department = datas[0].department;
      this.datasRegis = datas[0];
    },

    qrModal() {
      this.qrButton = true;
      this.manualButton = false;
      this.isQRCode = true;
      let dataClinic = JSON.parse(localStorage.getItem("user_roles"))
      this.body.clinic_id = dataClinic[0].healthcare_facility_id

      const clinicFeature = this.role_user.length > 0 ? this.role_user[0].healthcare_facility_feature : [];
      const invent = clinicFeature.findIndex((el) => el.healthcare_facility_feature_id === "1");

      // const finds = this.clinic_features.findIndex(
      //   (e) => e.clinic_feature_id === "1"
      // );

      if (invent > -1) {
        this.feature_id = 1;
      } else {
        this.feature_id = 3;
      }
      let bodys = {
        clinic_feature_id: this.feature_id,
        clinic_id: this.body.clinic_id
      };
      // const indexx = this.clinic_features.findIndex(
      //   (e) => e.clinic_feature_id === "1"
      // );
      if (invent > -1) {
        this.recs = "Clinic";
        this.dataPatient2.clinic_feature_id = 1;
        this.dataPatient.clinic_feature_id = 1;
      } else {
        this.recs = "MCU";
        this.dataPatient2.clinic_feature_id = 3;
        this.dataPatient.clinic_feature_id = 3;
      }
      this.$store.dispatch("patient/getQrCode", bodys).then(() => {
        let codeInterval = this.$store.getters["patient/qrData"];
        let bodys = {
          code: codeInterval.code,
          clinic_id: this.body.clinic_id
        };
        this.set = setInterval(() => {
          this.$store.dispatch("patient/getPatientQr", bodys).then(() => {
            let dataInterval = this.$store.getters["patient/patientQrData"];
            if (dataInterval.status > 0) {
              clearInterval(this.set);
              this.$bvModal.hide("modal-3");
              this.$bvModal.show("modal-2");
              this.$store.dispatch("patient/getPatientQr", bodys).then(() => {
                let dataPatient = this.$store.getters["patient/patientQrData"];
                this.dataPatient.blood_type = dataPatient.blood_type;
                this.dataPatient.clinic_id = dataPatient.clinic_id;
                this.dataPatient.code = dataPatient.code;
                this.dataPatient.company = dataPatient.company;
                this.dataPatient.company_id = dataPatient.company_id;
                this.dataPatient.date_of_birth = dataPatient.date_of_birth;
                this.dataPatient.department = dataPatient.department;
                this.dataPatient.fullname = dataPatient.fullname;
                this.dataPatient.gender = dataPatient.gender;
                (this.dataPatient.id = dataPatient.id),
                  (this.dataPatient.nik = dataPatient.nik),
                  (this.dataPatient.position = dataPatient.position),
                  (this.dataPatient.status = dataPatient.status);

                this.dataPatient2.company = dataPatient.company;
                this.dataPatient2.fullname = dataPatient.fullname;
                this.dataPatient2.id = dataPatient.id;
                this.dataPatient2.clinic_id = dataPatient.clinic_id;
                this.dataPatient2.department = dataPatient.department;
                this.dataPatient2.position = dataPatient.position;
              });
            }
          });
        }, 2000);
      });
    },

    dataSelected(props) {
      this.manualButton = true;
      this.qrButton = false;
      this.$bvModal.hide("modal-search");
      this.$bvModal.hide("scanner");
      this.$bvModal.show("modal-2");
      this.dataPatient.fullname = props.fullname;
      this.dataPatient.company = props.company;
      this.dataPatient.department = props.department;
      this.dataPatient.date_of_birth = props.date_of_birth;
      this.dataPatient.nik = props.nik;
      this.dataPatient.position = props.position;
      this.dataPatient.gender = props.gender;
      this.dataPatient.blood_type = props.blood_type;
      this.dataPatient.flag = props.flag;

      this.dataPatient2.company = props.company;
      this.dataPatient2.fullname = props.fullname;
      this.dataPatient2.id = props.id;
      this.dataPatient2.clinic_id = this.body.clinic_id;
      this.dataPatient2.date_of_birth = props.date_of_birth;
      this.dataPatient2.nik = props.nik;
      this.dataPatient2.position = props.position;
      this.dataPatient2.gender = props.gender;
      this.dataPatient2.department = props.department;
      this.dataPatient2.mcu_year = moment().format("YYYY");

      this.$store
        .dispatch("patient/getCheckMcuPatient", this.dataPatient.nik)
        .then(() => {
          let patientMcuCheck =
            this.$store.getters["patient/checkMcuPatient"].data;
          this.dataPatient.purpose_visit = "";
          // this.dataPatient.weight = patientMcuCheck.patient_weight
          // this.dataPatient.height = patientMcuCheck.patient_height
          // this.disabledKemas = false

          if (
            patientMcuCheck.patient_weight != null &&
            patientMcuCheck.patient_height != null
          ) {
            this.dataPatient.weight = patientMcuCheck.patient_weight;
            this.dataPatient.allergy = patientMcuCheck.patient_allergy;
            this.dataPatient.height = patientMcuCheck.patient_height;
            this.disabledKemas = true;
          } else {
            this.dataPatient.height = patientMcuCheck.patient_height;
            this.dataPatient.allergy = patientMcuCheck.patient_allergy;
            this.dataPatient.height = patientMcuCheck.patient_height;
            this.disabledKemas = false;
          }
        });
    },

    registerPatient() {
      if (this.recs === "Clinic") {
        this.isLoading = true;

        this.$store
          .dispatch("patient/registerPatient", this.dataPatient)
          .then(() => {
            this.$swal({
              icon: "success",
              title: "Success",
              text: "Patient has been registered"
            });
            this.isLoading = false;
            this.qrButton = false;
            this.$bvModal.hide("modal-2");
            this.getAllData();
            this.dataPatient.weight = null;
            this.dataPatient.height = null;
            this.dataPatient.allergy = "";
            this.dataPatient.blood_pressure = "";
            this.dataPatient.init_complaint = "";
            this.dataPatient.purpose_visit = "";
            this.dataPatient.clinic_name = "";
            this.dataPatient.doctor_name = "";
          }).catch((err) => {
            console.log("error", err);
            this.$swal.fire("Error!", `${err.response.data.error}`, "error");
            this.isLoading = false
          });
      } else {
        this.isLoading = true;
        this.$store
          .dispatch("patient/registerPatient", this.dataPatient2)
          .then(() => {
            this.$swal({
              icon: "success",
              title: "Success",
              text: "Patient has been registered"
            });
            this.isLoading = false;
            this.qrButton = false;
            this.$bvModal.hide("modal-2");
            this.getAllData();
            (this.dataPatient2.employee_age = ""),
              (this.dataPatient2.length_of_work = ""),
              (this.dataPatient2.married_status = ""),
              (this.dataPatient2.mcu_year = ""),
              (this.dataPatient2.no_lab = ""),
              (this.dataPatient2.mcu_location = "");
            // this.dataPatient.weight = "";
            // this.dataPatient.height = "";
            // this.dataPatient.allergy = "";
            // this.dataPatient.blood_pressure = "";
            // this.dataPatient.init_complaint = "";
          }).catch((err) => {
            console.log("error", err);
            this.$swal.fire("Error!", `${err.response.data.error}`, "error");
            this.isLoading = false
          });;
      }
    },
    registerPatient2() {
      this.$validator.validateAll().then(async (res) => {
        if (!res) {
          this.$swal
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              }
            })
            .fire({
              icon: "error",
              title: "Please fill out the mandatory fields"
            });
        } else {
          if (this.recs === "Clinic") {
            delete this.dataPatient.code;
            delete this.dataPatient.status;
            delete this.dataPatient.company_id;
            delete this.dataPatient.id;
            let body = {
              blood_type: this.dataPatient.blood_type,
              clinic_id: this.dataPatient.clinic_id,
              code: this.dataPatient.code,
              company: this.dataPatient.company,
              company_id: this.dataPatient.company_id,
              date_of_birth: this.dataPatient.date_of_birth,
              department: this.dataPatient.department,
              fullname: this.dataPatient.fullname,
              gender: this.dataPatient.gender,
              id: this.dataPatient.id,
              nik: this.dataPatient.nik,
              position: this.dataPatient.position,
              status: this.dataPatient.status,
              init_complaint: this.dataPatient.init_complaint,
              weight: this.dataPatient.weight,
              height: this.dataPatient.height,
              blood_pressure: this.dataPatient.blood_pressure,
              allergy: this.dataPatient.allergy,
              clinic_feature_id: this.dataPatient.clinic_feature_id,
              purpose_visit: this.dataPatient.purpose_visit,
              clinic_name: this.dataPatient.clinic_name,
              doctor_name: this.dataPatient.doctor_name,
              is_guest: this.dataPatient.flag != "iSafe Guest" ? false : true,
              register_date: this.dataPatient.register_date
            };

            this.isLoading = true;
            this.$store
              .dispatch("patient/registerPatient2", body)
              .then(() => {
                this.$swal({
                  icon: "success",
                  title: "Success",
                  text: "Patient has been registered"
                });
                this.isLoading = false;
                this.manualButton = false;
                this.$bvModal.hide("modal-2");
                this.getAllData();
                this.dataPatient.weight = null;
                this.dataPatient.height = null;
                this.dataPatient.allergy = "";
                this.dataPatient.blood_pressure = "";
                this.dataPatient.init_complaint = "";
                this.dataPatient.purpose_visit = "";
                this.dataPatient.clinic_name = "";
                this.dataPatient.doctor_name = "";
                this.dataPatient.is_guest = ""
              }).catch((err) => {
            console.log("error", err);
            this.$swal.fire("Error!", `${err.response.data.error}`, "error");
            this.isLoading = false
          });
          } else {
            this.isLoading = true;
            this.$store
              .dispatch("patient/registerPatient2", this.dataPatient2)
              .then(() => {
                this.$swal({
                  icon: "success",
                  title: "Success",
                  text: "Patient has been registered"
                });
                this.isLoading = false;
                this.qrButton = false;
                this.$bvModal.hide("modal-2");
                this.getAllData();
                (this.dataPatient2.employee_age = ""),
                  (this.dataPatient2.length_of_work = ""),
                  (this.dataPatient2.married_status = ""),
                  (this.dataPatient2.mcu_year = ""),
                  (this.dataPatient2.no_lab = ""),
                  (this.dataPatient2.mcu_location = "");
                this.dataPatient2.mcu_category_id = "";
              }).catch((err) => {
            console.log("error", err);
            this.$swal.fire("Error!", `${err.response.data.error}`, "error");
            this.isLoading = false
          });
          }
        }
      });
    },

    // registerPatient2() {
    //   if (
    //     this.dataPatient.weight &&
    //     this.dataPatient.height &&
    //     this.dataPatient.blood_pressure
    //   ) {
    //     if (this.recs === "Clinic") {
    //       delete this.dataPatient.code;
    //       delete this.dataPatient.status;
    //       delete this.dataPatient.company_id;
    //       delete this.dataPatient.id;
    //       this.isLoading = true;
    //       this.$store
    //         .dispatch("patient/registerPatient2", this.dataPatient)
    //         .then(() => {
    //           this.$swal({
    //             icon: "success",
    //             title: "Success",
    //             text: "Patient has been registered"
    //           });
    //           this.isLoading = false;
    //           this.manualButton = false;
    //           this.$bvModal.hide("modal-2");
    //           this.getAllData();
    //           this.resetFormFields();
    //         });
    //     } else {
    //       this.isLoading = true;
    //       this.$store
    //         .dispatch("patient/registerPatient2", this.dataPatient2)
    //         .then(() => {
    //           this.$swal({
    //             icon: "success",
    //             title: "Success",
    //             text: "Patient has been registered"
    //           });
    //           this.isLoading = false;
    //           this.qrButton = false;
    //           this.$bvModal.hide("modal-2");
    //           this.getAllData();
    //           this.resetFormFields2();
    //         });
    //     }
    //   } else {
    //     this.$swal({
    //       icon: "error",
    //       title: "Error",
    //       text: "Please fill in all required fields (Weight, Height, Blood Pressure)"
    //     });
    //   }
    // },

    diagnoseData(props) {
      this.isLoading = true;
      this.reset();
      this.register_id = props.register_id;
      this.type_visit = props.type_visit;
      this.purpose_visit = props.purpose_visit;
      this.$bvModal.show("modal-1");
      if (this.type_visit === "MCU") {
        this.mcuData.register_id = props.register_id;
        this.getGroup();
        this.getCategorys();
        this.getItem();
        this.isLoading = false;
      } else {
        this.$store
          .dispatch("patient/getDiagnose", props.register_id)
          .then(async () => {
            await this.getDesease();
            let dataDiag = this.$store.getters["patient/diagnoseData"];
            this.diagnose.doctor = dataDiag.doctor;
            if (dataDiag.disease) {
              for (let i = 0; i < dataDiag.disease.length; i++) {
                await this.getDetailDisease(dataDiag.disease[i].disease_id);
              }
            }

            this.statuss = props.status;
            this.diagnose.id = props.register_id;
            this.diagnose.init_complaint = dataDiag.init_complaint;
            this.diagnose.weight = dataDiag.weight;
            this.diagnose.bmi = dataDiag.bmi;
            this.diagnose.height = dataDiag.height;
            this.diagnose.blood_pressure = dataDiag.blood_pressure;
            this.diagnose.allergy = dataDiag.allergy;
            this.diagnose.visit_type = dataDiag.visit_type;
            this.diagnose.main_complaint = dataDiag.main_complaint;
            this.diagnose.vital_sign = dataDiag.vital_sign;
            this.diagnose.spell = dataDiag.spell;
            this.diagnose.disease = dataDiag.disease || [];
            // this.diagnose.disease_id = dataDiag.disease_id;
            this.diagnose.pak = dataDiag.pak ? dataDiag.pak : 2;
            this.diagnose.doctor_action = dataDiag.doctor_action;
            this.diagnose.action_care = dataDiag.action_care;
            this.diagnose.follow_up = 1;
            this.diagnose.next_visit = dataDiag.next_visit;
            this.diagnose.drugs = dataDiag.drugs;
            this.reset();
            this.isLoading = false;
          })
          .catch((err) => {
            console.log("error", err);
            this.$swal.fire("Error!", `${err.response.data.error}`, "error");
            this.isLoading = false
          });
      }
    },

    diagnosePatient() {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "Submitted Diagnose cannot be changed later!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes"
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$validator.validateAll().then(async (res) => {
              if (!res) {
                this.$swal
                  .mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener(
                        "mouseenter",
                        this.$swal.stopTimer
                      );
                      toast.addEventListener(
                        "mouseleave",
                        this.$swal.resumeTimer
                      );
                    }
                  })
                  .fire({
                    icon: "error",
                    title: "Please fill out the mandatory fields"
                  });
              } else {
                let drug = this.diagnose;
                let drugsMAP = [];
                if (drug.non_medisin == '') {
                  drugsMAP = drug.drugs.map((e) => {
                  return {
                    drugs_id: e.drugs_id,
                    drugs_unit_id: e.drugs_unit_id,
                    expired_date: e.expired_date,
                    how_use: `${e.dose} ${e.how_use}`,
                    qty: e.qty
                  };
                });
                }
                
                if(drug.non_medisin == true) {
                  drugsMAP = [] 
                }
                this.isLoading = true;
                let sick_leave_days = Number(drug.sick_leave_day);
                // for (let i = 0; i < obat.length; i++) {
                //   obat[i].how_use = `${obat[i].dose} ${obat[i].how_use}`;
                //   delete obat[i].dose;
                // }
                let mur = drug.disposable;
                for (let i = 0; i < mur.length; i++) {
                  delete mur[i].uom;
                  delete mur[i].disposableExpireDate;
                }
                // drug.sick_leave_day = sick_leave_days;
                // drug.drugs = JSON.stringify(obat);
                // drug.disease = JSON.stringify(drug.disease);
                // console.log("drug buatan", drug);
                // drug.disposable = JSON.stringify(drug.disposable);
                // console.log('drug ori', this.diagnose)
                let body = {
                  id: this.diagnose.id,
                  bmi: this.diagnose.bmi,
                  init_complaint: this.diagnose.init_complaint,
                  weight: this.diagnose.weight,
                  height: this.diagnose.height,
                  blood_pressure: this.diagnose.blood_pressure,
                  allergy: this.diagnose.allergy,
                  visit_type: this.diagnose.visit_type,
                  doctor: this.diagnose.doctor,
                  main_complaint: this.diagnose.main_complaint,
                  vital_sign: this.diagnose.vital_sign,
                  disease_id: this.diagnose.disease_id,
                  spell: this.diagnose.spell,
                  pak: this.diagnose.pak,
                  file_doctor_letter: this.diagnose.file_doctor_letter,
                  file_receipt_letter: this.diagnose.file_receipt_letter,
                  file_sick_letter: this.diagnose.file_sick_letter,
                  disease: JSON.stringify(this.diagnose.disease),
                  drugs: JSON.stringify(drugsMAP),
                  disposable: JSON.stringify(mur),
                  doctor_action: this.diagnose.doctor_action,
                  action_care: this.diagnose.action_care,
                  follow_up: this.diagnose.follow_up,
                  star_date: this.diagnose.star_date,
                  next_visit: this.diagnose.next_visit,
                  doctor_note: this.diagnose.doctor_note,
                  sick_leave: this.diagnose.sick_leave,
                  sick_leave_day: sick_leave_days,
                  non_medisin: this.diagnose.non_medisin
                };
                //           console.log("bodih", body);
                // console.log('diaknos', this.diagnose);
                this.$store
                  .dispatch("patient/diagnosePatient", body)
                  .then(() => {
                    this.getAllData();
                    this.$bvModal.hide("modal-1");
                    this.$swal({
                      icon: "success",
                      title: "Success",
                      text: "Medical data has been updated"
                    });
                    this.isLoading = false;
                    this.diagnose = {
                      id: "",
                      init_complaint: "",
                      weight: null,
                      height: null,
                      blood_pressure: "",
                      allergy: "",
                      visit_type: "",
                      doctor: "",
                      main_complaint: "",
                      vital_sign: "",
                      disease_id: "",
                      disease: "",
                      spell: "",
                      pak: "",
                      drugs: [],
                      disposable: [],
                      doctor_action: "",
                      action_care: "",
                      follow_up: "",
                      next_visit: "",
                      doctor_note: "",
                      non_medisin: "",
                      sick_leave_day: ""
                    };
                    this.reset();
                  })
                  .catch((resp) => {
                    this.$swal(
                      `Error`,
                      resp.response.data.error,
                      "error"
                    );
                    this.isLoading = false;
                  });

                // catch (resp) {
                //   this.$swal("Error", this.$t("Error while add data"), "error");
                // }
              }
            });
          }
        });
    },

    diagnosePatientVisit2() {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "Submitted Diagnose cannot be changed later!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes"
        })
        .then((result) => {
          if (result.isConfirmed) { 
            let sick_leave_days = Number(this.diagnose.sick_leave_day);
            this.$validator.validateAll().then(async (res) => {
              if (!res) {
                this.$swal
                  .mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener("mouseenter", this.$swal.stopTimer);
                      toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                    }
                  })
                  .fire({
                    icon: "error",
                    title: "Please fill out the mandatory fields"
                  });
              } else {
                try {
                  this.isLoading = true;
                  const formData = new FormData();
                  formData.append("id", this.diagnose.id);
                  formData.append("doctor", this.diagnose.doctor);
                  formData.append("doctor_note", this.diagnose.doctor_note);
                  formData.append("disease", JSON.stringify(this.diagnose.disease));
                  // formData.append("drugs", JSON.stringify(this.diagnose.drugs));
                  // formData.append("disposable", JSON.stringify(this.diagnose.disposable));
                  formData.append("vital_sign", this.diagnose.vital_sign);
                  formData.append("pak", this.diagnose.pak);
                  formData.append("spell", this.diagnose.spell);
                  formData.append("sick_leave", this.diagnose.sick_leave);
                  formData.append("star_date", this.diagnose.star_date);
                  formData.append("sick_leave_day", sick_leave_days);
                  for (let file_doctor_letter of this.diagnose.file_doctor_letter) {
                    formData.append("file_doctor_letter", file_doctor_letter); // note, no square-brackets
                  }
                  for (let file_receipt_letter of this.diagnose.file_receipt_letter) {
                    formData.append("file_receipt_letter", file_receipt_letter); // note, no square-brackets
                  }
                  for (let file_sick_letter of this.diagnose.file_sick_letter) {
                    formData.append("file_sick_letter", file_sick_letter); // note, no square-brackets
                  }
                  this.$store
                    .dispatch("patient/addNoPartnership", formData)
                    .then(() => {
                      this.getAllData();
                      this.$bvModal.hide("modal-1");
                      this.$swal({
                        icon: "success",
                        title: "Success",
                        text: "Medical data has been updated"
                      });
                      this.isLoading = false;
                      this.reset();
                    }).catch((err) => {
              this.$swal.fire("Error!", `${err.response.data.error}`, "error");
                  this.isLoading = false
                });;
                } catch (resp) {
                  this.$swal("Error", this.$t("Error while add data"), "error");
                }
              }
            });
          }
        })
      
    },

    deleteData(id) {
      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00D97E",
        cancelButtonColor: "#E63757",
        confirmButtonText: this.$t("Yes, delete it!"),
        cancelButtonText: this.$t("Cancel")
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("patient/deletePatient", id)
            .then((resp) => {
              this.getAllData();
              this.$swal(
                "Deleted!",
                "This Record has been deleted!",
                "success"
              );
            })
            .catch((resp) => {
              this.$swal("Error", this.$t("Error while delete data"), "error");
            });
        }
      });
    },

    saveRecord() {
      this.$validator.validateAll().then(async (res) => {
        if (!res) {
          this.$swal
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              }
            })
            .fire({
              icon: "error",
              title: "Please fill out the mandatory fields"
            });
        } else {
          try {
            this.$store
              .dispatch("patient/diagnosePatientMcu", this.mcuData)
              .then(() => {
                this.getAllData();
                this.$bvModal.hide("modal-1");
                this.mcuData = {
                  register_id: "",
                  mcu_record: []
                };
                this.$swal({
                  icon: "success",
                  title: "Success",
                  text: "Patient has been Register"
                });
              });
          } catch (resp) {
            this.$swal("Error", this.$t("Error while add data"), "error");
          }
        }
      });
    },

    validationFeature(value) {
      const clinicFeature =
        this.role_user.length > 0
          ? this.role_user[0].healthcare_facility_feature
          : [];
      const invent = clinicFeature.findIndex(
        (el) =>
          el.healthcare_facility_feature_id === "1" ||
          el.healthcare_facility_feature_id === "3"
      );
      // const index = value.findIndex(
      //   (e) => e.clinic_feature_id === "1" || e.clinic_feature_id === "3"
      // );
      if (invent > -1) {
        return false;
      } else {
        return true;
      }
    },
    validationFeatureTable(value) {
      const clinicFeature =
        this.role_user.length > 0
          ? this.role_user[0].healthcare_facility_feature
          : [];
      const invent = clinicFeature.findIndex(
        (el) =>
          el.healthcare_facility_feature_id === "1" ||
          el.healthcare_facility_feature_id === "3"
      );
      // const index = value.findIndex(
      //   (e) => e.clinic_feature_id === "1" || e.clinic_feature_id === "3"
      // );
      if (invent > -1) {
        return true;
      } else {
        return false;
      }
    },

    changesDoses(event, indx) {
      if (event.id === 0) {
        this.otherDose = true;
      } else {
        this.otherDose = false;
        this.diagnose.drugs[indx].dose = event.dose;
      }
    },
    changesRules(event, indx) {
      if (event.id === 0) {
        this.otherRule = true;
      } else {
        this.otherRule = false;
        this.diagnose.drugs[indx].how_use = event.how_to_use;
      }
    },

    seeHistory(dat) {
      localStorage.setItem("dataMedical", JSON.stringify(dat));
      localStorage.setItem("seehistory", JSON.stringify(dat));
      localStorage.setItem("breadcrumb", "Registration");
      this.$router.push("/clinic-mcu/history");
    },

    detailPatient(dat) {
      console.log("dat", dat);
      localStorage.setItem("registerID", dat.register_id);
      localStorage.setItem("dataMedical", JSON.stringify(dat));
      localStorage.setItem("breadcrumb", "Registration");
      this.$router.push("/clinic-mcu/history/record-detail");
    },
    detailPatientMCU(dat) {
      console.log("dat", dat);
      let newData = {
        nik: dat.nik,
        patient_name: dat.patient_name,
        company: dat.company,
        department: dat.department,
        position: dat.position,
        register_id: dat.register_id
      };
      localStorage.setItem("registerID", dat.register_id);
      localStorage.setItem("MCUID", dat.register_id);
      localStorage.setItem("seehistory", JSON.stringify(newData));
      localStorage.setItem("breadcrumb", "Registration");
      this.$router.push("/clinic-mcu/history/record-mcu");
    },

    onFoc() {
      this.modalScanner = true;
      console.log(
        "membuka form scan =>",
        moment().format("YYYY-MM-DD HH:mm:ss")
      );
      setTimeout(() => {
        document.getElementById("EdanSir").focus();
      }, 250);
      // this.$nextTick(() => {
      //   // this.$refs.EdanSir.focus();
      // });
    },

    // handleBarcodeScan(event) {
    //   // Check if the modal is open and the key pressed is not a special key (e.g., Control, Alt, Shift)
    //   if (this.modalScanner && !event.ctrlKey && !event.altKey && !event.shiftKey) {
    //     // Check for the Enter key press
    //     if (event.key === 'Enter') {
    //       // Process the complete barcode data
    //       this.processBarcodeData(this.barcodeData);

    //       // Clear the barcodeData string
    //       this.barcodeData = '';
    //     } else if (event.key !== 'ArrowDown') {
    //       // Append the scanned character to the barcodeData string
    //       this.barcodeData += event.key;
    //     }
    //   }
    // },

    // processBarcodeData(barcode) {
    //   // Here, you can perform any necessary processing with the full barcode data
    //   this.Sf = barcode
    //   const regex = /(\d+[a-zA-Z-]+)-/;
    //   const match = barcode.match(regex);

    //   if (match) {
    //     const extractedValue = match[1];
    //   } else {
    //     console.log("Value not found");
    //   }
    // },

    edan(e) {
      if (e.keyCode == 9) {
        e.preventDefault();
      }
      if (parseInt(e.keyCode) == 13) {
        const text = this.Sf;
        const regex = /NIK\s*:\s*(\S+)/;
        let id1 = text.indexOf("NIK");
        if (id1 < 0) {
          this.Sf = "";
          this.$swal("Error", "Invalid QR Code", "error");
        }
        let st1 = text.substring(id1);
        let id2 = st1.indexOf("Posisi");
        let st2 = st1.substring(0, id2);
        const match = st2.match(regex);

        if (match && match[1]) {
          this.search.nik = match[1];
          this.$store
            .dispatch("roleManagement/getUser", this.search)
            .then(() => {
              let roleLogin = localStorage.getItem("roles_id");
              let resFilter = [];
              const companyUser = localStorage.getItem("company");

              if (roleLogin === "1" || roleLogin === "3") {
                resFilter = this.users;
              } else {
                resFilter = this.users.filter(
                  (el) => el.company_id === companyUser
                );
              }

              // this.employees = resFilter;
              if (resFilter.length) {
                if (resFilter.length > 1) {
                  this.Sf = "";
                  this.isLength = true;
                  const clinicFeature =
                    this.role_user.length > 0
                      ? this.role_user[0].healthcare_facility_feature
                      : [];
                  const invent = clinicFeature.findIndex(
                    (el) => el.healthcare_facility_feature_id === "1"
                  );
                  if (invent > -1) {
                    this.recs = "Clinic";
                    this.dataPatient2.clinic_feature_id = 1;
                    this.dataPatient.clinic_feature_id = 1;
                  } else {
                    this.recs = "MCU";
                    this.dataPatient2.clinic_feature_id = 3;
                    this.dataPatient.clinic_feature_id = 3;
                  }
                } else {
                  this.Sf = "";
                  const clinicFeature =
                    this.role_user.length > 0
                      ? this.role_user[0].healthcare_facility_feature
                      : [];
                  const invent = clinicFeature.findIndex(
                    (el) => el.healthcare_facility_feature_id === "1"
                  );
                  // const indexx = this.clinic_features.findIndex(
                  //   (e) => e.clinic_feature_id === "1"
                  // );
                  if (invent > -1) {
                    this.recs = "Clinic";
                    this.dataPatient2.clinic_feature_id = 1;
                    this.dataPatient.clinic_feature_id = 1;
                  } else {
                    this.recs = "MCU";
                    this.dataPatient2.clinic_feature_id = 3;
                    this.dataPatient.clinic_feature_id = 3;
                  }
                  console.log(
                    "selesai scan =>",
                    moment().format("YYYY-MM-DD HH:mm:ss")
                  );
                  this.manualButton = true;
                  this.qrButton = false;
                  this.$bvModal.hide("scanner");
                  this.dataPatient.fullname = resFilter[0].fullname;
                  this.dataPatient.company = resFilter[0].company;
                  this.dataPatient.department = resFilter[0].department;
                  this.dataPatient.date_of_birth = resFilter[0].date_of_birth;
                  this.dataPatient.nik = resFilter[0].nik;
                  this.dataPatient.position = resFilter[0].position;
                  this.dataPatient.gender = resFilter[0].gender;
                  this.dataPatient.blood_type = resFilter[0].blood_type;

                  this.dataPatient2.company = resFilter[0].company;
                  this.dataPatient2.fullname = resFilter[0].fullname;
                  this.dataPatient2.id = resFilter[0].id;
                  this.dataPatient2.clinic_id = this.body.clinic_id;
                  this.dataPatient2.date_of_birth = resFilter[0].date_of_birth;
                  this.dataPatient2.nik = resFilter[0].nik;
                  this.dataPatient2.position = resFilter[0].position;
                  this.dataPatient2.gender = resFilter[0].gender;
                  this.dataPatient2.department = resFilter[0].department;
                  this.dataPatient2.mcu_year = moment().format("YYYY");
                  this.$bvModal.show("modal-2");
                }
              } else {
                this.$bvModal.hide("scanner");
                this.$swal(
                  "Error",
                  "Employee not found in this company",
                  "error"
                );
              }
            });
        } else {
        }
      }
    },

    // api hit
    getCategoryMCU() {
      this.$store.dispatch("categoryMcu/getCategory");
    },
    async getCategoryDisease() {
      let body = {
        limit: 10000,
        page: 1
      };
      this.$store.dispatch("category/getCategory", body);
    },
    async getBlock() {
      let body = {
        limit: 10000,
        page: 1
      };
      await this.$store.dispatch("block/getBlock", body);
    },
    async getChapter() {
      let body = {
        limit: 10000,
        page: 1
      };
      await this.$store.dispatch("chapter/getChapter", body);
    },
    async getDesease(e) {
      // let body = {
      //   limit: 500,
      //   page: 1,
      // };
      await this.$store.dispatch("disease/getDesease");
    },
    async getDoctor() {
      await this.$store.dispatch("patient/getDoctor");
    },

    async getDoctorKataKemas() {
      await this.$store
        .dispatch("roleManagement/getDataRoleManagement", {
          roles_id: '4, 7'
        })
        .then(() => {
          let roleLogin = localStorage.getItem("roles_id");
          let resFilter = [];
          const companyUser = localStorage.getItem("company");

          if (roleLogin === "1" || roleLogin === "3") {
            resFilter = this.doctorsKataKemas.data.rows;
          } else {
            resFilter = this.doctorsKataKemas.data.rows.filter(
              (el) => el.company_id === companyUser
            );
          }

          this.doctorList = resFilter;
        });
    },

    async getDetailDisease(e) {
      let id = e;
      await this.$store.dispatch("disease/getDetailDisease", id);
    },

    async getHowtouse() {
      await this.$store.dispatch("patient/getHowtouse");
    },

    async getDose() {
      await this.$store.dispatch("patient/getDose");
    },

    getCategorys() {
      let body = {
        limit: 10000,
        page: 1
      };
      this.$store.dispatch("categoryMcuTest/getCategory", body);
    },
    getGroup() {
      let body = {
        limit: 1000,
        page: 1
      };
      this.$store.dispatch("groupMcu/getGroup", body);
    },
    getItem() {
      let body = {
        limit: 1000,
        page: 1
      };
      this.$store.dispatch("itemMcu/getItem", body);
    },

    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.desease_observer.observe(this.$refs.load);
      }
    },
    onClose() {
      this.desease_observer.disconnect();
    },

    getAllData() {
      this.load = true;
      // const companyId = localStorage.getItem("company");
      // let roleLogin = JSON.parse(localStorage.getItem("user_roles"));
      this.$store.dispatch("patient/getAllData", this.body).then(() => {
        this.load = false;
      }).catch((err) => {
        this.$swal.fire("Error!", `${err.response.data.error}`, "error");
            this.isLoading = false
          });
    },
    getTreatment() {
      this.load = true;
      let body = {
        limit: 100,
        page: 1
      };
      this.$store.dispatch("treatment/getTreatment", body).then(() => {
        this.load = false;
      });
    },

    compareFlags(flag1, flag2) {
      const flagPriority = { Keycloak: 1, iCore: 2, Uworkforce: 3 };

      if (flagPriority[flag1] < flagPriority[flag2]) {
        return -1;
      } else if (flagPriority[flag1] > flagPriority[flag2]) {
        return 1;
      } else {
        return 0; // Flags are equal in priority
      }
    },
    getEmployees() {
      this.load = true;
      this.$store.dispatch("roleManagement/getUser", this.search).then(() => {
        let roleLogin = localStorage.getItem("roles_id");
        let resFilter = [];
        const companyUser = localStorage.getItem("company");

        if (roleLogin === "1" || roleLogin === "3") {
          resFilter = this.users;
        } else {
          resFilter = this.users.filter((el) => el.company_id === companyUser);
        }

        // Prioritize data based on "flag" when there are multiple entries with the same "nik"
        const uniqueNikMap = new Map();
        resFilter.forEach((employee) => {
          const nik = employee.nik;
          if (
            !uniqueNikMap.has(nik) ||
            this.compareFlags(employee.flag, uniqueNikMap.get(nik).flag) > 0
          ) {
            uniqueNikMap.set(nik, employee);
          }
        });

        // Convert the Map values back to an array
        this.employeesList = Array.from(uniqueNikMap.values());

        this.load = false;
      }).catch((resp) => {
        this.$swal(
          `Error`,
          resp.response.data.error,
          "error"
        );
        this.load = false;
      });;
    },

    getEmployee() {
      if (this.search.date_of_birth || this.search.fullname || this.search.nik || this.search.no_ktp) {
        this.load = true;
      this.$store.dispatch("roleManagement/getUser", this.search).then(() => {
        let roleLogin = JSON.parse(localStorage.getItem("user_roles"));
        let resFilter = [];

        const companyUser = localStorage.getItem("company");
        let index = roleLogin.findIndex((e) => e.roles_id == 1 || e.roles_id == 3)
        if (index > -1) {
          resFilter = this.users;
        } else {
          resFilter = this.users.filter((el) => el.company_id == companyUser);
        }

        this.employeesList = resFilter;
        this.load = false;
        this.searchEmployee = true;
      });
      } else {
        this.$swal
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              }
            })
            .fire({
              icon: "warning",
              title: "Fill field above atleast 1 of them"
            });
            this.employeesList = []
      }
      // this.$store.dispatch("patient/getDataEmployee", this.search).then(() => {
      //   this.load = false;
      // });
    },
    // getEmployeeTab() {
    //   this.$store.dispatch("roleManagement/getUser", this.search).then(() => {
    //     let roleLogin = localStorage.getItem("roles_id");
    //     let resFilter = [];
    //     const companyUser = localStorage.getItem("company");

    //     if (roleLogin === "1" || roleLogin === "3") {
    //       resFilter = this.users;
    //     } else {
    //       resFilter = this.users.filter((el) => el.company_id === companyUser);
    //     }

    //     this.employeesList = resFilter;
    //   });
    // },
    getDrug() {
      this.loadDate = true;
      let body = "MEDICINE";
      this.$store.dispatch("patient/getInventoryClinic", body).then(() => {
        this.loadDate = false;
        let datas = this.$store.getters["patient/dataInventoryClinic"];
        var option = [];
        datas.map((item) => {
          if (item.base_qty > 0) {
            option.push({
              id: item.id,
              label: `${item.item_generic_name} - ${item.item_product_name}`
            });
          }
        });
        this.drugOption = option;
      });
    },
    getDisposable() {
      this.loadDate = true;
      let body = "DISPOSABLE-ITEM";
      this.$store.dispatch("patient/getInventoryClinic", body).then(() => {
        this.loadDate = false;
        let datas = this.$store.getters["patient/dataInventoryClinic"];

        let arr = [];
        for (let i = 0; i < datas.length; i++) {
          if (datas[i].base_qty > 0) {
            arr.push(datas[i]);
          }
        }
        this.disposableItemOption = arr;
        // this.disposableItemOption = datas;
      });
    },
    getUnit() {
      let body = {
        page: 1,
        limit: 10,
        order: "",
        unit_name: ""
      };
      this.$store.dispatch("unit/getUnit", body);
    },
    productUnit(id, index) {
      this.loadExp = true;
      this.$store.dispatch("po/getUnitProduct", id).then(() => {
        let data = this.$store.getters["po/UnitProductData"];
        this.diagnose.disposable[index].uom = data.uom;
        // return data;
      });
      let body = "DISPOSABLE-ITEM";
      this.$store.dispatch("patient/getInventoryClinic", body).then(() => {
        this.loadDate = false;
        let datas = this.$store.getters["patient/dataInventoryClinic"];
        var rec2 = datas.find((i) => {
          return i.id == id;
        });
        if (rec2) {
          let bodyStock = {
            id: rec2.id,
            page: 1,
            perPage: 999,
            searchQuery: "",
            sort: ""
          };
          this.$store.dispatch("reporting/detailStock", bodyStock).then(() => {
            let arr = [];
            const dataStock = this.$store.getters["reporting/detailStockData"];
            for (let i = 0; i < dataStock.row.length; i++) {
              if (dataStock.row[i].expiry_date) {
                arr.push(dataStock.row[i].expiry_date);
              }
            }
            this.diagnose.disposable[index].disposableExpireDate = arr;
            this.loadExp = false;
          });
        }
        // var dat = rec2.expiry_date.map((i) => {
        //   console.log(i);
        //   if (i === null) {
        //     return "";
        //   } else {
        //     return i;
        //   }
        // });
        // this.diagnose.disposable[index].disposableExpireDate = dat;
      });
      // let body = {
      //     limit: 10000,
      //     page: 1,
      //   };
      // 	this.$store.dispatch("drug/getDrug", body).then(() => {
      // 		this.loadDate = false;
      // 		const datas = this.$store.getters["drug/DrugData"];
      // 		const list = datas.data;
      // 		var rec2 = list.filter((i) => {
      // 			return i.id == id;
      // 		});
      //     return rec2
      // 	});
    },
    receiptUnit(id, index) {
      if (!id) {
        this.diagnose.drugs[index].drugExpireDate = [];
      }
      this.$store.dispatch("po/getUnitProduct", id).then(() => {
        let data = this.$store.getters["po/UnitProductData"];
        this.diagnose.drugs[index].uom = data.uom
        // console.log('asdasdczxc', data);
        
        return data;
      });
      let body = "MEDICINE";
      this.$store.dispatch("patient/getInventoryClinic", body).then(() => {
        this.loadDate = false;
        let datas = this.$store.getters["patient/dataInventoryClinic"];
        var rec2 = datas.find((i) => {
          return i.id == id;
        });
        let bodyStock = {
          id: rec2.id,
          page: 1,
          perPage: 999,
          searchQuery: "",
          sort: ""
        };
        this.$store.dispatch("reporting/detailStock", bodyStock).then(() => {
          let arr = [];
          const dataStock = this.$store.getters["reporting/detailStockData"];
          for (let i = 0; i < dataStock.row.length; i++) {
            if (dataStock.row[i].expiry_date) {
              arr.push(dataStock.row[i].expiry_date);
            }
          }
          this.diagnose.drugs[index].drugExpireDate = arr;
        });
      });

      let bodyReport = {
        page: 1,
        perPage: 10,
        searchQuery: "",
        sort: "",
        item_id: id,
        type: "MEDICINE"
      };

      this.$store.dispatch("reporting/getListDrugs", bodyReport).then(() => {
        let datas = this.$store.getters["reporting/listDrugsData"];
        let rec = datas.rows.filter((i) => {
          return (i.item_id = id);
        });
        this.diagnose.drugs[index].dataAddDrugs = rec;
      });
    },
    checkVal(id) {
      if (id !== "") {
        return false;
      } else {
        return true;
      }
    },
    receiptQTY(qty, id, unit_id) {
      let bodyReport = {
        page: 1,
        perPage: 10,
        searchQuery: "",
        sort: "",
        item_id: id,
        type: "MEDICINE"
      };
      this.$store.dispatch("reporting/getListDrugs", bodyReport).then(() => {
        let datas = this.$store.getters["reporting/listDrugsData"];
        let dus = 0;
        let strip = 0;
        let tab = 0;
        let qtyDrug = datas.rows[0].qty;
        for (let i = 0; i < this.unit.uom.length; i++) {
          if (qtyDrug.includes(this.unit.uom[i].item_uom_name)) {
            const lel = qtyDrug.indexOf(this.unit.uom[i].item_uom_name);
            if (this.unit.uom[i].item_uom_name === "Dus") {
              dus = qtyDrug.slice(0, lel);
              qtyDrug = qtyDrug.replace(dus, "");
            } else if (this.unit.uom[i].item_uom_name === "Strip") {
              strip = qtyDrug.slice(0, lel);
              qtyDrug = qtyDrug.replace(strip, "");
            } else if (this.unit.uom[i].item_uom_name === "Tab") {
              tab = qtyDrug.slice(0, lel);
              qtyDrug = qtyDrug.replace(tab, "");
            }
            qtyDrug = qtyDrug.replace(this.unit.uom[i].item_uom_name, "");
          }
        }

        const unitFilter = this.unit.uom.find(
          (el) => el.item_uom_id === unit_id
        );
        if (unitFilter) {
          if (unitFilter.item_uom_name === "Dus") {
            if (Number(qty) > Number(dus)) {
              this.$swal("Error", this.$t("Quantity more than stock"), "error");
            }
          } else if (unitFilter.item_uom_name === "Strip") {
            if (Number(qty) > Number(strip)) {
              this.$swal("Error", this.$t("Quantity more than stock"), "error");
            }
          } else if (unitFilter.item_uom_name === "Tab") {
            if (Number(qty) > Number(tab)) {
              this.$swal("Error", this.$t("Quantity more than stock"), "error");
            }
          }
        }
      });
    },
    getCategory() {
      let body = {
        limit: 100,
        page: 1
      };
      this.$store.dispatch("categoryMcuTest/getCategory", body);
    },
    datasss(e) { },

    getCategory() {
      this.$store.dispatch("patient/getCategoryMcu");
    },

    // filter
    filterPage() {
      this.body.limit = 10,
      this.body.page = 1
      this.getAllData();
    },

    sorting() {
      this.getName();
    },

    // Pagination
    onPageChange(payload) {
      this.body.limit = payload.currentPerPage;
      this.body.page = payload.currentPage;
      this.getAllData();
    },

    // disease

    prevDisease() {
      let page = this.desease_paramater.page;
      page -= 1;
      page = page <= 1 ? 1 : page;
      this.desease_paramater.page = page;
      this.getDesease();
    },

    nextDisease() {
      const disase_data = this.paginated;
      const total_page = disase_data.totalPages;
      let page = this.desease_paramater.page;
      page += 1;
      page = page <= 1 ? 1 : page;
      if (page >= total_page) {
        page = totalPages;
      }

      this.desease_paramater.page = page;
      this.getDesease();
    },

    async searchDisease(search, loading) {
      if (search) {
        loading(true);
        clearTimeout(this.desease_timeout);
        this.desease_timeout = setTimeout(async () => {
          this.desease_paramater.disease_name = search;
          await this.getDesease();
          loading(false);
        }, 500);
      }
    },

    spellCondition(status, sick) {
      if (status != "DONE") {
        if (sick == 0) {
          this.diagnose.spell = 2
          return true
        } else {

          return false
        }
      } else {
        return true
      }
    },

    exporting() {
      let body = {
        type_visit: this.body.type_visit,
        company_id: this.body.company_id,
        employee_name: this.body.employee_name,
        start_date: this.body.start_date,
        end_date: this.body.end_date
      };
      let params = queryString.stringify(body);
      // if (this.bodyExport.type === "Filtered") {
      //   let company_id = `${company_id}`
      //   let start_date = moment(this.bodyExport.start_date).format(
      //     "YYYY-MM-DD"
      //   );
      //   let end_date = moment(this.bodyExport.end_date).format("YYYY-MM-DD");
      //   this.bodyExport.start_date = `${start_date}`;
      //   this.bodyExport.start_date = `${end_date}`;
      // this.bodyExport.date = `${start_date}-01-01`;

      // else (this.bodyExport.type === "All List") {
      // if (this.bodyExport.type === "All List") {
      // this.bodyExport.date = `${this.bodyExport.date}-01`;
      // let params = queryString.stringify(this.bodyExport);

      // Default axiosIns.get(`${axiosIns.defaults.baseURL}/medical-waste/excel`,
      axiosIns
        .get(
          `${axiosIns.defaults.baseURL}/clinic/register/export-excel?${params}`,
          {
            responseType: "blob"
          }
        )
        .then((res) => {
          const href = URL.createObjectURL(res.data);
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", `Uhealth Registration Report.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
    },
    getLab() {
      this.importFile.label_lab = this.role_user[0].healthcare_facility_name;
      this.importFile.lab_id = this.role_user[0].healthcare_facility_id;
    },

    isNumberInput(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 &&
        charCode !== 44 
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          evt.preventDefault();
      } else {
          return true;
      }
    },
    yuhu(evt) {
      console.log(evt);
    }
  }
};
</script>

<style scoped>
.pagination-select {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination-select button {
  flex-grow: 1;
}

.pagination-select button:hover {
  cursor: pointer;
}
</style>