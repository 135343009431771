import queryString from "query-string";

import {
    GET_COMPANY_DATA,
    ADD_COMPANY,
    DEL_COMPANY,
    UPDATE_COMPANY,
    ADD_COMPANY_PERIOD,
    GET_COMPANY_ACTIVE,
    UPDATE_COMPANY_PERIOD,
    DEL_COMPANY_ACTIVE
} from "@/api/compny/company";

const state = {
    dataCompany: [],
    dataCompanyActive: []
};

const mutations = {
    setCompany: (states, payload) => {
        states.dataCompany = payload;
    },
    setCompanyActive: (states, payload) => {
        states.dataCompanyActive = payload;
    },
};

const actions = {
    getCompany: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_COMPANY_DATA(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setCompany", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setCompany", {
                        setCompany: [],
                    });
                }
            });
    },

    getCompanyActive: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_COMPANY_ACTIVE(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setCompanyActive", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setCompanyActive", {
                        setCompanyActive: [],
                    });
                }
            });
    },

    addCompany: async ({ }, args) => {
        return await ADD_COMPANY(args);
    },

    add_company_period: async ({ }, args) => {
        return await ADD_COMPANY_PERIOD(args);
    },

    update_company_active: async ({ }, args) => {
        return await UPDATE_COMPANY_PERIOD(args);
    },

    delete_company_active: async ({ }, id) => {
        return await DEL_COMPANY_ACTIVE(id);
    },


    delCompany: async ({ }, id) => {
        return await DEL_COMPANY(id);
    },

    updateCompany: async ({ }, args) => {
        return await UPDATE_COMPANY(args);
    },



};

const getters = {
    CompanyData: (states) => states.dataCompany,
    dataCompanyActive: (states) => states.dataCompanyActive
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};