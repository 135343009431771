<template>
    <!-- HEADER -->
    <div class="header">
        <div class="container-fluid">
            <!-- Body -->
            <!-- / .row -->

            <!-- Filter -->
            <!-- end of filter -->

            <!-- Table -->
            <div class="card mt-5">
                <div class="card-header">
                    <!-- Title -->
                    <h4 class="card-header-title">{{ $t("Company") }}</h4>
                    <!-- Button -->
                    <b-button v-b-modal.modal-1 variant="primary" @click="isAdd = true" class="mr-3">
                        <span class="fe fe-file-plus"></span>
                        {{ $t("New Company") }}
                    </b-button>
                </div>
                <div class="cart">
                    <div class="card-body">
                        <!-- <div class="d-flex justify-content-end mb-3">
                            <div class="d-flex">
                                <label class="align-self-center mb-0 mr-3">Search</label>
                                <b-form-input class="w-auto" v-model="body.searchQuery" placeholder="Type Here..."
                                    @change="getCompany()"></b-form-input>
                            </div>
                        </div> -->
                        <vue-good-table mode="remote" :line-numbers="true" :columns="columns" :rows="companyDatas.data"
                            :total-rows="companyDatas.totalItems" :sort-options="{
                  enabled: true
                }" :pagination-options="{
                  enabled: true,
                  perPage: body.limit
                }" style-class="vgt-table table table-bordered" @on-sort-change="onSortChange"
                            :isLoading.sync="isLoading" @on-page-change="onPageChange"
                            @on-per-page-change="onPageChange">
                            <!-- Loading -->
                            <div slot="emptystate" class="text-center font-size-14">
                                No data available
                            </div>
                            <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field == 'action'">
                                    <b-button class="no-wrap btn-pill" variant="warning" size="sm"
                                        @click="updateData(props.row)">
                                        <span class="fe fe-edit"></span>
                                    </b-button>
                                    <b-button class="no-wrap ml-2" variant="danger" style="margin-left: 5px" size="sm"
                                        @click="deleteData(props.row.id)">
                                        <span class="fe fe-trash-2"></span>
                                    </b-button>
                                </span>
                                <span v-else-if="props.column.field == 'period'">
                                    {{ formatMonthy(props.row.start_date) }} - {{ formatMonthy(props.row.end_date) }}
                                </span>
                            </template>
                            <template slot="pagination-bottom" slot-scope="props">
                                <div class="d-flex justify-content-end">
                                    <div>
                                        <b-pagination :value="1" :total-rows="companyDatas.totalItems"
                                            :per-page="body.limit" first-number last-number align="right"
                                            prev-class="prev-item" next-class="next-item" class="m-3" @input="
                          (value) => props.pageChanged({ currentPage: value })
                        ">
                                        </b-pagination>
                                    </div>
                                </div>
                            </template>
                        </vue-good-table>
                    </div>
                    <!-- Link -->
                </div>
            </div>

            <!-- Modal -->
            <b-modal id="modal-1" centered hide-footer :title="$t('Company Form')">
                <b-tabs content-class="mt-3" fill v-if="isAdd == true">
                    <b-tab title="New Company">
                        <form @submit.prevent="saveAdd('new')">
                            <div class="form-group">
                                <label>{{ $t("Company Name") }} <span class="text-danger">*</span></label>
                                <input type="text" v-model="bodyAdd1.company" class="form-control" name="Company Name"
                                    v-validate="'required'">
                                <span class="text-sm text-danger" v-show="errors.has('Company Name')">{{
                                    errors.first("Company Name")
                                }}</span>
                            </div>
                            <div class="form-group">
                                <label>{{ $t("Company Tag") }} <span class="text-danger">*</span></label>
                                <input type="text" v-model="bodyAdd1.company_tag" class="form-control"
                                    name="Company Tag" v-validate="'required'">
                                <span class="text-sm text-danger" v-show="errors.has('Company Tag')">{{
                                    errors.first("Company Tag")
                                }}</span>
                            </div>
                            <div class="form-group">
                                <label>{{ $t("Company Period") }} <span class="text-danger">*</span></label>
                                <date-picker input-class="form-control" style="display: block; width: 100%;;"
                                    v-model="bodyAdd1.period" range valueType="format"></date-picker>
                                <span class="text-sm text-danger" v-show="errors.has('Company Period')">{{
                                    errors.first("Company Period")
                                }}</span>
                            </div>
                            <button style="margin-left: auto; float: right" class="btn btn-primary" type="submit"
                                v-if="isLoading == false">
                                {{ $t("Save") }}
                            </button>
                            <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="button"
                                disabled v-else>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                            </button>
                        </form>
                    </b-tab>
                    <b-tab title="Existing Company">
                        <form @submit.prevent="saveAdd('existing')">
                            <div class="form-group">
                                <label>{{ $t("Company") }} <span class="text-danger">*</span></label>
                                <v-select v-model="bodyAdd2.company_id" :options="companySelection" label="company_name"
                                    :reduce="(e) => e.id" name="Company" />
                            </div>
                            <div class="form-group">
                                <label>{{ $t("Company Tag") }} <span class="text-danger">*</span></label>
                                <input type="text" v-model="bodyAdd2.company_tag" class="form-control"
                                    name="Company Tag">
                                <span class="text-sm text-danger" v-show="errors.has('Company Tag')">{{
                                    errors.first("Company Tag")
                                }}</span>
                            </div>
                            <div class="form-group">
                                <label>{{ $t("Company Period") }} <span class="text-danger">*</span></label>
                                <date-picker input-class="form-control" style="display: block; width: 100%;;"
                                    v-model="bodyAdd2.period" range valueType="format"></date-picker>
                                <span class="text-sm text-danger" v-show="errors.has('Company Period')">{{
                                    errors.first("Company Period")
                                }}</span>
                            </div>
                            <button style="margin-left: auto; float: right" class="btn btn-primary" type="submit"
                                v-if="isLoading == false">
                                {{ $t("Save") }}
                            </button>
                            <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="button"
                                disabled v-else>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                            </button>
                        </form>
                    </b-tab>
                </b-tabs>

                <form @submit.prevent="saveUpdate()" v-else>
                    <div class="form-group">
                        <label>{{ $t("Company") }} <span class="text-danger">*</span></label>
                        <input type="text" v-model="bodyUpdate.company" class="form-control" name="Company" disabled>
                    </div>
                    <div class="form-group">
                        <label>{{ $t("Company Tag") }} <span class="text-danger">*</span></label>
                        <input type="text" v-model="bodyUpdate.company_tag" class="form-control" name="Company Tag">
                        <span class="text-sm text-danger" v-show="errors.has('Company Tag')">{{
                                    errors.first("Company Tag")
                                }}</span>
                    </div>
                    <div class="form-group">
                        <label>{{ $t("Company Period") }} <span class="text-danger">*</span></label>
                        <date-picker input-class="form-control" style="display: block; width: 100%;;"
                            v-model="bodyUpdate.period" range valueType="format"></date-picker>
                        <span class="text-sm text-danger" v-show="errors.has('Company Period')">{{
                                    errors.first("Company Period")
                                }}</span>
                    </div>
                    <button style="margin-left: auto; float: right" class="btn btn-primary" type="submit"
                        v-if="isLoading == false">
                        {{ $t("Save") }}
                    </button>
                    <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="button" disabled
                        v-else>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </button>
                </form>
            </b-modal>
        </div>
    </div>
</template>
<script>
    import moment from "moment";
    export default {
        name: "defaultCMS",
        computed: {
            companyDatas() {
                return this.$store.getters["company/dataCompanyActive"] ?
                    this.$store.getters["company/dataCompanyActive"] : [];
            },
            companySelection() {
                return this.$store.getters["detailManagement/dataCompany"] ?
                    this.$store.getters["detailManagement/dataCompany"] : [];
            }
        },
        data() {
            return {
                body: {
                    page: 1,
                    limit: 10,
                    order: "",
                    searchQuery: ""
                },
                bodyAdd1: {
                    company: "",
                    company_tag: "",
                    period: []
                },
                bodyAdd2: {
                    company_id: "",
                    company: "",
                    company_tag: "",
                    period: []
                },
                bodyUpdate: {
                    company_id: "",
                    company: "",
                    company_tag: "",
                    period: ""
                },
                id: "",
                isAdd: true,
                isLoading: false,
                columns: [{
                        label: "COMPANY",
                        field: "company",
                        thClass: "table-header text-muted font-weight-bold",
                        tdClass: "color-secondary align-middle"
                    },
                    {
                        label: "company tag",
                        field: "company_tags",
                        thClass: "table-header text-muted font-weight-bold",
                        tdClass: "color-secondary align-middle"
                    },
                    {
                        label: "Period",
                        field: "period",
                        thClass: "table-header text-muted font-weight-bold",
                        tdClass: "color-secondary align-middle"
                    },
                    {
                        label: "ACTION",
                        field: "action",
                        sortable: false,
                        thClass: "table-header no-wrap text-muted font-weight-bold",
                        tdClass: "no-wrap text-center color-secondary align-middle"
                    }
                ]
            };
        },
        mounted() {
            this.getCompany();
            this.getCompanySelect();
        },
        methods: {
            //action
            updateData(props) {
                this.$bvModal.show("modal-1");
                this.isAdd = false;
                let peris = [props.start_date, props.end_date]
                this.bodyUpdate.company_id = props.company_id;
                this.bodyUpdate.company = props.company
                this.bodyUpdate.company_tag = props.company_tags
                this.bodyUpdate.period = peris;
                this.id = props.id
            },

            saveAdd(type) {
                if (type == 'new') {
                    let body = {
                        // company_id: "",
                        company: this.bodyAdd1.company,
                        company_tags: this.bodyAdd1.company_tag,
                        start_date: this.bodyAdd1.period[0],
                        end_date: this.bodyAdd1.period[1]
                    }
                    if (this.bodyAdd1.company && this.bodyAdd1.company_tag && this.bodyAdd1.period.length > 0) {
                        this.isLoading = true;
                        this.$store
                            .dispatch("company/add_company_period", body)
                            .then((resp) => {
                                this.$swal({
                                    title: this.$t("Saved"),
                                    text: this.$t("Your input has been saved"),
                                    icon: "success",
                                    confirmButtonText: this.$t("OK")
                                }).then((resp) => {
                                    this.isLoading = false;
                                    this.bodyAdd1.company = ""
                                    this.bodyAdd1.company_tag = ""
                                    this.bodyAdd1.period = []
                                    this.getCompany();
                                    this.$bvModal.hide("modal-1");
                                });
                            })
                            .catch((resp) => {
                                this.$swal("Error", `${resp.response.data.message}`, "error");
                                this.isLoading = false;
                            });
                    } else {
                        this.$swal.mixin({
                                toast: true,
                                position: "top-end",
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                                },
                            })
                            .fire({
                                icon: "error",
                                title: "You must fill all field",
                            })
                    }
                } else {
                    // company_name
                    if (this.bodyAdd2.company_id && this.bodyAdd2.company_tag && this.bodyAdd2.period.length > 0) {
                        let selected_company = this.$store.getters["detailManagement/dataCompany"]
                        let target = selected_company.filter(company => company.id === this.bodyAdd2.company_id)
                        let body = {
                            company: target[0].company_name,
                            company_id: this.bodyAdd2.company_id,
                            company_tags: this.bodyAdd2.company_tag,
                            start_date: this.bodyAdd2.period[0],
                            end_date: this.bodyAdd2.period[1]
                        }
                        this.isLoading = true;
                        this.$store
                            .dispatch("company/add_company_period", body)
                            .then((resp) => {
                                this.$swal({
                                    title: this.$t("Saved"),
                                    text: this.$t("Your input has been saved"),
                                    icon: "success",
                                    confirmButtonText: this.$t("OK")
                                }).then((resp) => {
                                    this.isLoading = false;
                                    this.bodyAdd2.company_id = ""
                                    this.bodyAdd2.company_tag = ""
                                    this.bodyAdd2.period = []
                                    this.getCompany();
                                    this.$bvModal.hide("modal-1");
                                });
                            })
                            .catch((resp) => {
                                this.$swal("Error", `${resp.response.data.message}`, "error");
                                this.isLoading = false;
                            });
                    } else {
                        this.$swal.mixin({
                                toast: true,
                                position: "top-end",
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                                },
                            })
                            .fire({
                                icon: "error",
                                title: "You must fill all field",
                            })
                    }
                }
            },
            saveUpdate() {
                    let body = {
                        company: this.bodyUpdate.company_name,
                        company_id: this.bodyUpdate.company_id,
                        company_tags: this.bodyUpdate.company_tag,
                        start_date: this.bodyUpdate.period[0],
                        end_date: this.bodyUpdate.period[1]
                    }
                    let params = {
                        body: body,
                        id: this.id
                    }
                this.isLoading = true;
                this.$store
                    .dispatch("company/update_company_active", params)
                    .then((resp) => {
                        this.$swal({
                            title: this.$t("Saved"),
                            text: this.$t("Your input has been saved"),
                            icon: "success",
                            confirmButtonText: this.$t("OK")
                        }).then((resp) => {
                            this.isLoading = false;
                            this.getCompany();
                            this.$bvModal.hide("modal-1");
                        });
                    })
                    .catch((resp) => {
                        this.$swal("Error", `${resp.response.data.message}`, "error");
                    });
            },

            deleteData(id) {
                this.$swal({
                    title: this.$t("Are you sure?"),
                    text: this.$t("You won't be able to revert this!"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#00D97E",
                    cancelButtonColor: "#E63757",
                    confirmButtonText: this.$t("Yes, delete it!"),
                    cancelButtonText: this.$t("Cancel")
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store
                            .dispatch("company/delete_company_active", id)
                            .then((resp) => {
                                console.log(resp);
                                
                                this.getCompany();
                                this.$swal(
                                    "Deleted!",
                                    `${resp.data.message}`,
                                    "success"
                                );
                            })
                            .catch((resp) => {
                                this.$swal("Error", `${resp.response.data.message}`, "error");
                            });
                    }
                });
            },

            onSortChange(params) {
                let sort1 = params[0].field;
                let sort2 = '';
                if (params[0].type == 'none') {
                    sort2 = ''
                } else {
                    sort2 = params[0].type
                }
                // let sort2 = params[0].type;
                this.body.order = `${sort2}`;
                this.getCompany();
            },

            // api hit
            getCompany() {
                this.$store.dispatch("company/getCompanyActive", this.body);
            },
            getCompanySelect() {
                this.$store.dispatch("detailManagement/getCompany", {
                    all: true
                });
            },

            // filter
            filterPage() {
                this.getCompany();
            },

            sorting() {
                this.getCompany();
            },

            formatMonthy(dat) {
                return moment(dat).format('D MMMM YYYY')
            },

            // Pagination
            onPageChange(payload) {
                this.body.limit = payload.currentPerPage;
                this.body.page = payload.currentPage;
                this.getCompany();
                // }
            }
        }
    };
</script>